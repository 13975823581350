import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Default, Switch, Case, When } from 'react-if';
import { useNavigate } from 'react-router';
import { Tooltip } from '@mui/material';
import { ExclamationCircleIcon, StarIcon } from '@heroicons/react/20/solid';
import { DocumentCheckIcon, UsersIcon } from '@heroicons/react/24/outline';

import { useNavigationOpts } from '../../hooks/useNavigationOpts';
import { WrappedLink } from '../WrappedLink';
import { classNames }  from '../../../common/lib/classNames';
import { useHasValidSubscription } from '../../hooks/useHasValidSubscription';
import { Badge } from '../../../common/Atoms/Badge';
import { useGetSubscriptionMetadata } from '../../hooks/useGetSubscription';
import { UpgradeBadge } from '../Molecules/UpgradeBadge';
import { Divider } from '../../../common/Atoms/Divider';
import { useCreateGroupMutation } from '../../services/api/groupApi/group';

import { Logo } from './Logo';

export function DesktopSidebar({ currentOrg }) {
  const { navigation } = useNavigationOpts(!!currentOrg);
  const navigate = useNavigate();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();
  const hasValidSubscription = useHasValidSubscription();
  const [createGroup] = useCreateGroupMutation();

  const noSub = item => {
    return (!hasValidSubscription && item.requiresSubscription);
  };

  const upgrade = item => {
    if (hasValidSubscription) {
      return item.requiresPaidFeature && !subscriptionMetadata?.features?.[item.requiresPaidFeature];
    }
  };

  const notAllowed = item => {
    return noSub(item) || upgrade(item);
  };

  async function onNewGroup() {
    const res = await createGroup({ organisationId: currentOrg.id }).unwrap();

    const createdId = res?.id;

    if (createdId) {
      navigate(`${currentOrg.id}/groups/${createdId}`);
    }
  }

  return (
    <div className={ `hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col` }>
      <div className={ `flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5 pb-4` }>
        <div className={ `flex flex-shrink-0 items-center px-4` }>
          <Logo />
        </div>
        <div className={ `mt-5 flex flex-grow flex-col` }>
          <nav className={ `flex-1 space-y-8 bg-white px-2` }
            aria-label={ `Sidebar` }>
            <div className={ `space-y-1` }>
              { navigation.map((item, i) => {
                const isGroupOption = item.href.startsWith(`/groups/`);

                return (
                  <Switch key={ i }>
                    <Case condition={ item.href === `ADD_NEW_GROUP` }>
                      <a
                        key={ item.name }
                        href={ `#` }
                        data-tour={ item.demoTarget }

                        className={ classNames(
                          item.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                          `group flex items-center px-2 py-2 text-sm font-medium rounded-md`,
                          notAllowed(item) && `opacity-50 cursor-not-allowed`,
                        ) }
                        onClick={ e => {
                          e.preventDefault();
                          onNewGroup();
                        } }
                      >
                        <>
                          <item.icon
                            className={ classNames(
                              item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                              `mr-3 flex-shrink-0 h-6 w-6`,
                            ) }
                            aria-hidden={ `true` }
                          />
                          { item.name }
                        </>
                      </a>
                    </Case>
                    <Case condition={ item.href === `SPACE` }>
                      <div className={ `` }>
                        <Divider
                          weight={ `light` }
                          text={ item.name }
                          spacing={ `small` }
                        />
                      </div>
                    </Case>
                    <Case condition={ item.external }>
                      <a
                        href={ item.href }
                        target={ `_blank` }
                        rel={ `noreferrer` }
                        data-tour={ item.demoTarget }
                        className={ classNames(
                          item.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                          `group flex items-center px-2 py-2 text-sm font-medium rounded-md`,
                          notAllowed(item) && `opacity-50 cursor-not-allowed`,
                        ) }>
                        <item.icon
                          className={ classNames(
                            item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                            `mr-3 flex-shrink-0 h-6 w-6`,
                          ) }
                          aria-hidden={ `true` }
                        />
                        {
                          item.name
                        }
                      </a>
                    </Case>
                    <Case condition={ !item.children }>
                      <WrappedLink
                        key={ item.name }
                        to={ noSub(item)? `` : item.href }
                        data-tour={ item.demoTarget }

                        className={ classNames(
                          item.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                          `group flex px-2 py-2 text-sm font-medium rounded-md`,
                          notAllowed(item) && `opacity-50 cursor-not-allowed`,
                          isGroupOption ? `flex-col` : `items-center`,
                        ) }
                        disabled={ notAllowed(item) }
                      >
                        <span className={ `flex` }>
                          <item.icon
                            className={ classNames(
                              item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                              `mr-3 flex-shrink-0 h-6 w-6`,
                            ) }
                            aria-hidden={ `true` }
                          />
                          <Tooltip title={ item.name }>
                            <span className={ `flex-1 truncate` }>{ item.name }</span>
                          </Tooltip>
                          <When condition={ noSub(item) }>
                            <Badge color={ `gray` }
                              message={ `No Plan` }
                              size={ `xs` } />
                          </When>

                          <When condition={ item.type === `groupOptionDefault` }>
                            <div className={ `ml-0.5` }>
                              <Tooltip title={ `Default Group` }>
                                <StarIcon className={ `text-yellow-400 h-5 w-5` } />
                              </Tooltip>
                            </div>
                          </When>
                        </span>
                        { /* Group related stuffs */ }
                        <When condition={ isGroupOption }>
                          <div className={ `flex mt-1` }>

                            <When condition={ item.memberCount !== undefined }>
                              <Tooltip title={ `Group contains ${item.memberCount} contacts` }>
                                <span className={ `mx-1 flex` }>
                                  { item.memberCount }
                                  <UsersIcon className={ `text-gray-400 h-5 w-5` } />
                                </span>
                              </Tooltip>
                            </When>

                            <When condition={ item.validCount !== undefined }>
                              <Tooltip title={ `Group contains ${item.validCount} policies` }>
                                <span className={ `mx-1 flex` }>
                                  { item.validCount }
                                  <DocumentCheckIcon className={ `text-gray-400 h-5 w-5` } />
                                </span>
                              </Tooltip>
                            </When>

                            <When condition={ item.invalidCount }>
                              <Tooltip title={ `Group has ${item.invalidCount} incomplete policies` }>
                                <span className={ `mx-1 flex text-red-700` }>
                                  { item.invalidCount }
                                  <ExclamationCircleIcon className={ `text-red-700 h-5 w-5` } />
                                </span>
                              </Tooltip>
                            </When>

                          </div>
                        </When>
                        <When condition={ upgrade(item) }>
                          <UpgradeBadge />
                        </When>

                        <When condition={ item.requiresFeature === `ai` }>

                          <Tooltip title={ `You have early access to this feature. It may not be perfect so please let us know if you have any feedback!` }>
                            <Badge
                              className={ `mt-0 ml-1` }
                              color={ `blue` }
                            >
                              { `Early Access!` }
                            </Badge>
                          </Tooltip>
                        </When>

                      </WrappedLink>
                    </Case>
                    <Default>
                      <Disclosure as={ `div` }
                        className={ `space-y-1 w-full` }
                        key={ item.name }
                        defaultOpen={ item.alwaysOpen }>
                        { ({ open }) => (
                          <>
                            <DisclosureButton
                              as={ `a` }
                              data-tour={ item.demoTarget }
                              className={ classNames(
                                item.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                                `group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer`,
                              ) }
                            >
                              <item.icon
                                className={ classNames(
                                  item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                                  `mr-3 flex-shrink-0 h-6 w-6`,
                                ) }
                                aria-hidden={ `true` }
                              />
                              <span className={ `flex-1` }>{ item.name }</span>

                              <svg
                                className={ classNames(
                                  open ? `text-gray-400 rotate-90` : `text-gray-300`,
                                  `ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400`,
                                ) }
                                viewBox={ `0 0 20 20` }
                                aria-hidden={ `true` }
                              >
                                <path d={ `M6 6L14 10L6 14V6Z` }
                                  fill={ `currentColor` } />
                              </svg>
                            </DisclosureButton>
                            <DisclosurePanel className={ `space-y-1` }>
                              { item.children.map(subItem => (
                                <Switch key={ subItem.key || subItem.name }>
                                  <Case condition={ subItem.href === `ADD_NEW_GROUP` }>
                                    <DisclosureButton
                                      key={ subItem.name }
                                      as={ `div` }
                                      data-tour={ subItem.demoTarget }
                                    >
                                      <a
                                        href={ `#` }
                                        className={ classNames(
                                          subItem.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                                          `group flex items-center px-2 py-2 text-sm font-medium rounded-md pl-11`,
                                          notAllowed(subItem) && `opacity-50 cursor-not-allowed`,
                                        ) }
                                        onClick={ e => {
                                          e.preventDefault();
                                          onNewGroup();
                                        } }
                                      >
                                        <>
                                          <subItem.icon
                                            className={ classNames(
                                              item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                                              `mr-3 flex-shrink-0 h-6 w-6`,
                                            ) }
                                            aria-hidden={ `true` }
                                          />
                                          { subItem.name }
                                        </>
                                      </a>
                                    </DisclosureButton>
                                  </Case>
                                  <Default>
                                    <DisclosureButton
                                      key={ subItem.name }
                                      as={ `div` }
                                      data-tour={ subItem.demoTarget }
                                    >
                                      <WrappedLink
                                        to={ subItem.href }
                                        className={ classNames(
                                          subItem.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                                          `group flex items-center px-2 py-2 text-sm font-medium rounded-md pl-11`,
                                          notAllowed(subItem) && `opacity-50 cursor-not-allowed`,
                                        ) }
                                        disabled={ notAllowed(subItem) }
                                      >
                                        <>
                                          <subItem.icon
                                            className={ classNames(
                                              item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                                              `mr-3 flex-shrink-0 h-6 w-6`,
                                            ) }
                                            aria-hidden={ `true` }
                                          />
                                          { subItem.name }
                                          <When condition={ subItem.type === `groupOptionDefault` }>
                                            <div className={ `ml-0.5` }>
                                              <Badge
                                                color={ `blue` }
                                                message={ `Default` }
                                                size={ `xs` }

                                              />
                                            </div>
                                          </When>
                                          <When condition={ noSub(subItem) }>
                                            <Badge color={ `gray` }
                                              message={ `No Plan` }
                                              size={ `xs` } />
                                          </When>
                                          <When condition={ upgrade(subItem) }>
                                            <UpgradeBadge />
                                          </When>
                                        </>
                                      </WrappedLink>
                                    </DisclosureButton>
                                  </Default>
                                </Switch>
                              )) }
                            </DisclosurePanel>
                          </>
                        ) }
                      </Disclosure>
                    </Default>
                  </Switch>
                );
              }) }
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
