import { IInvalidField, IPolicy } from "shared";
import { useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import { InputTextAddon } from "../../../../../../common/Atoms/InputTextAddon";
import { fieldIsValid } from "../../../../../lib/helper";
import { Heading } from "../../../../../../common/Atoms/Typography/Heading";
import { Card } from "../../../../../../common/Atoms/Card";

interface IPolicyTitleProps {
  policy: IPolicy;
  invalids: IInvalidField[];
  handleChange: (obj: { name: string; value: string | number | boolean | Date }) => void;
}

export function PolicyTitle({
  policy,
  invalids,
  handleChange,
}: IPolicyTitleProps) {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return (
      <Card className={ `flex w-full items-end p-4 shadow-md rounded-lg` }>
        <InputTextAddon
          value={ policy.title }
          onChange={ handleChange }
          required
          label={ `Policy name` }
          subHeading={ `Customers will not be able to see this name.` }
          name={ `title` }
          className={ `w-full` }
          invalid={ fieldIsValid(`title`, invalids) }
        />

        <button
          type={ `button` }
          className={ `ml-2 h-10` }
          color={ `gray` }
          onClick={ () => setIsEditing(false) }
        >
          <CheckCircleIcon className={ `w-8 h-8 text-lateGreen-600` } />
        </button>
      </Card>
    );
  }

  return (
    <Card className={ `flex space-x-1 items-center p-4 shadow-md rounded-lg` }>
      <Heading>
        { policy.title }
      </Heading>
      <button
        type={ `button` }
        onClick={ () => setIsEditing(true) }
      >
        <PencilSquareIcon className={ `w-6 h-6 text-lateGreen-600` } />
      </button>
    </Card>
  );
}
