/* eslint-disable no-case-declarations */
import { endOfMonth, format, subMonths } from 'date-fns';
import {  DateFormats, IInvalidField, IPolicy, PolicyCalculationType, PolicyIncludeExcludeType, PolicyIntervalType, PolicyProcessType, PolicyStatementType, PolicyType, StatementRelativePeriods, getOrdinalSuffix, langauge, policyWeeklyDayIntervalMap, toDecimal } from 'shared';

export function fieldIsValid(key: string, errors: IInvalidField[]) {
  return errors.find(e => e.path === key);
}

export function capitalizeFirstLetter(str) {
  return str.replace(/^\w/, c => c.toUpperCase());
}

export function addIng(word: string) {
  if (word.endsWith(`e`)) {
    // Remove the 'e' before adding 'ing'
    return word.slice(0, -1) + `ing`;
  }
  else if (word.match(/[aeiou][^aeiou]$/i)) {
    // Double the final constonant before adding 'ing'
    return word + word.slice(-1) + `ing`;
  }
  else {
    return word + `ing`;
  }
}

export function addEd(word: string) {
  if (word.endsWith(`e`)) {
    return word + `d`;
  }
  else if (/[aeiou][b-df-hj-np-tv-z]$/i.test(word)) {
    return word + word.slice(-1) + `ed`;
  }
  else if (word.endsWith(`y`) && !/[aeiou]/i.test(word.slice(-2, -1))) {
    return word.slice(0, -1) + `ied`;
  }
  else {
    return word + `ed`;
  }
}

export function getDayIndex(policy?: IPolicy) {
  const usesDayIndex = [
    PolicyType.REMINDER,
    PolicyType.SMS,
    PolicyType.ESCALATION,
    PolicyType.AI_CALL,
    PolicyType.QUOTE_EMAIL,
    PolicyType.STATEMENT,
    PolicyType.STATEMENT_LATE_FEE,
  ];

  let dayIndex = usesDayIndex.includes(policy.policy_type)
    ? policy.day_index
    : policy.grace_period;

  if (policy.policy_type === PolicyType.DISCOUNT) {
    dayIndex = - dayIndex;
  }

  return dayIndex || 0;
}

export function renderDescription(policy: IPolicy, orgUsers: { label: string; value: string }[]) {
  const excludeThemeCount = policy.exclude_branding_themes?.split(`,`)?.length;

  const excludeThemeCountText = excludeThemeCount ? `Excludes ${excludeThemeCount} themes` : ``;
  const applyFromDueDate = policy.apply_after_due_date_string; // TODO format nicely
  const applyBeforeDueDate = policy.apply_before_due_date_string;

  const texts = [];
  const secondaryTexts = [];

  if (applyFromDueDate && applyBeforeDueDate) {
    secondaryTexts.push(
      `Applies to invoices due between ${applyFromDueDate} and ${applyBeforeDueDate}`,
    );
  }
  else if (applyFromDueDate) {
    secondaryTexts.push(
      `Applies to invoices due after ${applyFromDueDate}`,
    );
  }
  else if (applyBeforeDueDate) {
    secondaryTexts.push(
      `Applies to invoices due before ${applyBeforeDueDate}`,
    );
  }

  if (policy.xero_group_name) {
    if (policy.xero_group_name_exclude_include === PolicyIncludeExcludeType.EXCLUDE) {
      secondaryTexts.push(
        `Excludes contacts in Xero Group(s) "${policy.xero_group_name?.split(`,`).join(`, `)}"`,
      );
    }

    if (policy.xero_group_name_exclude_include === PolicyIncludeExcludeType.INCLUDE) {
      secondaryTexts.push(
        `Includes contacts in Xero Group(s) "${policy.xero_group_name?.split(`,`).join(`, `)}"`,
      );
    }
  }

  if (policy.contact_tags) {
    if (policy.contact_tags_exclude_include === PolicyIncludeExcludeType.EXCLUDE) {
      secondaryTexts.push(
        `Excludes contacts with tag(s) "${policy.contact_tags?.split(`,`).join(`, `)}"`,
      );
    }

    if (policy.contact_tags_exclude_include === PolicyIncludeExcludeType.INCLUDE) {
      secondaryTexts.push(
        `Includes contacts with tag(s) "${policy.contact_tags?.split(`,`).join(`, `)}"`,
      );
    }
  }

  let feeType = policy.calculation_type === PolicyCalculationType.FIXED ? `${toDecimal(policy.fixed_fee_amount)}` : `${policy.percent}%`;
  let postFix = ``;
  if (policy.calculation_type === PolicyCalculationType.PERCENT) {
    postFix = `Fee`;
  }

  if (policy.calculation_type === PolicyCalculationType.FIXED) {
    postFix = `Fixed Fee`;
  }

  if (policy.calculation_type === PolicyCalculationType.PRORATA) {
    postFix = `P.A (Prorated per day)`;
  }

  // If BankOfEngland rate, add this info
  if ([PolicyType.LATE_FEE, PolicyType.STATEMENT_LATE_FEE].includes(policy.policy_type) && policy.use_central_interest_rate && policy.calculation_type === PolicyCalculationType.PRORATA) {
    feeType = `${feeType} [+ BoE Base Rate]`;
  }

  const { day, verb }= renderDayIndex(policy);
  const dayIndex = getDayIndex(policy);
  const target = langauge.policy.targetStatus[policy.target_status] || `due date`;

  switch (policy.policy_type) {
  case PolicyType.LATE_FEE:
    const invoiceIssueType = policy.process_type === PolicyProcessType.NEW_INVOICE ? `Issued as a new invoice` : `Added to the original invoice`;

    texts.push(invoiceIssueType);

    texts.push(
      `${feeType} ${postFix}`,
    );

    texts.push(`${policy.grace_period} ${policy.day_period_type}${policy.grace_period > 1 ? `s` : ``} grace period`);

    if (policy.repeating && policy.repeat_frequency) {
      texts.push(
        `Repeats every ${policy.repeat_frequency} days`,
      );
    }

    break;

  case PolicyType.STATEMENT_LATE_FEE:
    if (policy.interval_type === PolicyIntervalType.MONTHLY) {
      texts.push(`Monthly on the ${policy.day_index}${getOrdinalSuffix(policy.day_index)} day of the month`);
    }

    if (policy.interval_type === PolicyIntervalType.WEEKLY) {
      texts.push(`Weekly on ${policyWeeklyDayIntervalMap[policy.day_index]}`);
    }

    if (policy.statement_type === PolicyStatementType.OUTSTANDING) {
      texts.push(
        `Applies to all invoices outstanding`,
      );
    }
    else if (policy.overdue_age_max && policy.overdue_age_min) {
      texts.push(
        `Applies to all invoices overdue between ${policy.overdue_age_min} and ${policy.overdue_age_max} days`,
      );
    }
    else if (policy.overdue_age_max) {
      texts.push(
        `Applies to all invoices overdue less than ${policy.overdue_age_max} days`,
      );
    }
    else {
      texts.push(
        `Applies to all invoices overdue more than ${policy.overdue_age_min} days`,
      );
    }

    const includeInterest = policy.include_previous_interest ? `Compounding Interest` : `Interest Not Compounded`;

    texts.push(includeInterest);

    if (policy.reference_contains) {
      secondaryTexts.push(`Only applies to invoices with "${policy.reference_contains}" in the reference`);
    }

    texts.push(
      `${feeType} ${postFix}`,
    );

    if (policy.fixed_fee_amount) {
      texts.push(`${toDecimal(policy.fixed_fee_amount).toFixed(2)} Fixed Fee`);
    }

    break;
  case PolicyType.STATEMENT:
    if (policy.interval_type === PolicyIntervalType.MONTHLY) {
      texts.push(`Monthly on the ${policy.day_index}${getOrdinalSuffix(policy.day_index)} day of the month`);
    }

    if (policy.interval_type === PolicyIntervalType.WEEKLY) {
      texts.push(`Weekly on ${policyWeeklyDayIntervalMap[policy.day_index]}`);
    }

    if (policy.statement_period === StatementRelativePeriods.UPTO_PREVIOUS_MONTH) {
      texts.push(`Include all items issued upto the end of previous month`);
    }

    if (policy.statement_period === StatementRelativePeriods.THIS_MONTH) {
      texts.push(`Only include items issued in the current month`);
    }

    if (policy.statement_period === StatementRelativePeriods.PREVIOUS_MONTH) {
      texts.push(`Only include items issued in the previous month`);
    }

    if (policy.statement_type === PolicyStatementType.OUTSTANDING) {
      texts.push(`Contacts with outstanding balance`);
    }

    if (policy.statement_type === PolicyStatementType.OVERDUE) {
      texts.push(`Contacts with overdue balance`);
    }

    if (policy.destination_email_override) {
      texts.push(`Send only to ${policy.destination_email_override}`);
    }

    if (!policy.include_statement_pdf) {
      secondaryTexts.push(`Not attaching statement PDF`);
    }
    break;
  case PolicyType.REMINDER:
  case PolicyType.SMS:
  case PolicyType.AI_CALL:
  case PolicyType.QUOTE_EMAIL:
    if (dayIndex === 0) {
      texts.push(`${verb} the ${target}.`);
    }
    else {
      texts.push(`${day} ${policy.day_period_type}${dayIndex > 1 ? `s` : ``} ${verb} the ${target}.`);
    }
    break;

  case PolicyType.DISCOUNT:
    const discountType = policy.calculation_type === PolicyCalculationType.FIXED ? `${toDecimal(policy.fixed_fee_amount)} Fixed Discount` : `${policy.percent}%`;

    texts.push(
      `${discountType}`,
    );
    break;

  case PolicyType.ESCALATION:
    const escalationDays = dayIndex === 0 ?
      `${verb} the ${target}.` :
      `${day} day${dayIndex > 1 ? `s` : ``} ${verb} the ${target}.`;

    texts.push(
      `${langauge.escalation[policy.escalation_type].label} ${escalationDays} `,
    );

    if (policy.assignee_user_id) {
      const user = orgUsers.find(u => u.value === policy.assignee_user_id);

      if (user) {
        texts.push(
          `Assign to ${user.label}`,
        );
      }
      else {
        texts.push(
          `Assign to a removed user!`,
        );
      }
    }

    break;
  }

  texts.push(excludeThemeCountText);

  if (policy.hour_of_day || policy.hour_of_day === 0) {
    const print = policy.hour_of_day < 10 ? `0${policy.hour_of_day}` : policy.hour_of_day;

    secondaryTexts.push(
      `Run at ${print}:00hrs`,
    );
  }

  if (policy.first_time_only) {
    secondaryTexts.push(
      `First time only`,
    );
  }

  return {
    description: texts.filter(t => t).join(` | `),
    secondaryDescription: secondaryTexts.filter(t => t).join(` | `),
  };
}

export function renderDayIndex(policy: IPolicy): {
  day: string
  verb: string
} {
  const dayIndex = getDayIndex(policy);

  const verb = dayIndex === 0 ? `On` : (
    dayIndex > 0 ? `after` : `before`
  );

  return {
    day: dayIndex.toString().replace(`-`, ``),
    verb,
  };
}

export function getMonthNameFromPeriod(period: StatementRelativePeriods) {
  switch (period) {
  case StatementRelativePeriods.THIS_MONTH:
    return format(new Date(), `MMMM`);
  case StatementRelativePeriods.PREVIOUS_MONTH:
    return format(new Date(new Date().setMonth(new Date().getMonth() - 1)), `MMMM`);
  case StatementRelativePeriods.UPTO_PREVIOUS_MONTH:
    return `Upto ${format(endOfMonth(subMonths(new Date(), 1)), DateFormats.CASUAL)}`;
  default:
    return `All time`;
  }
}

export function getTableHeight(rowCount?: number) {
  rowCount = rowCount || 0;
  //  52PX per row by default
  if (rowCount < 8) {
    return `h-[600px]`;
  }

  if (rowCount < 11) {
    return `h-[1000px]`;
  }

  return `h-[1200px]`;
}
