import { Fragment, useMemo, useState, useEffect } from 'react';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, ShieldCheckIcon } from '@heroicons/react/20/solid';
import { Default, Switch, Case, When } from 'react-if';
import { Tooltip } from '@mui/material';
import { IInvalidField } from 'shared';

import { classNames }  from '../lib/classNames';
import { HelpIcon } from '../../main/components/Molecules/HelpIcon';

import { SmallSpinner } from './SmallSpinner';
import { Badge } from './Badge';
import { ForwardRefFormLabel } from './FormLabel';
import { FormDescription } from './FormDescription';
import { Paragraph } from './Typography/Paragraph';

const classes = {
  invalid: `border-red-600`,
};

export interface IOption {
  label: string
  value: string | number
  disabled?: boolean
  badge?: string
  badgeColor?: string
  tooltip?: string
  description?: string
}

interface ISelectProps {
  options: IOption[]
  selected?: string | number
  onChange: ({
    value,
    name,
  }) => void
  label?: string
  invalid?: boolean | IInvalidField
  name?: string
  nullable?: boolean
  activeBadge?: boolean
  activeBadgeIsActive?: boolean;
  emptyText?: string;
  className?: string;
  description?: string;
  disabled?: boolean;
  displaySuffix?: string;
  helpIcon?: string;
  loading?: boolean;
  nestedInTextField?: boolean;
  tourTarget?: string;
  suffixElement?: React.ReactNode | null | string;
  valueClassname?: string;
  helpText?: string;
}

export function Select({
  options,
  selected,
  onChange,
  label,
  description,
  invalid,
  name,
  nullable,
  activeBadge,
  activeBadgeIsActive,
  emptyText = `Select Option`,
  className,
  disabled,
  displaySuffix,
  helpIcon,
  loading,
  nestedInTextField,
  tourTarget,
  suffixElement,
  valueClassname,
  helpText,
}: ISelectProps) {
  const initSelectData = {
    label: emptyText,
    value: null,
  };

  const initSelect = initSelectData;

  const [selectedValue, setSelectedValue] = useState(initSelect);

  useEffect(() => {
    const sv = options.find(o => o.value === selected);

    if (sv) {
      setSelectedValue(sv);
    }

    if (selected === null) {
      setSelectedValue(initSelect);
    }
  }, [ options, selected ]);

  const opts = useMemo(() => {
    const newOpts = [ ...options ];

    if (!selectedValue || nullable) {
      newOpts.unshift(initSelect);
    }

    return newOpts;
  }, [ selectedValue, options ]);

  function handleChange(value) {
    if (value.disabled) return;

    if (value.value === emptyText) {
      return onChange({
        value: null,
        name: null,
      });
    }

    return onChange({
      value: value.value,
      name,
    });
  }

  return (
    <div
      data-tour={ tourTarget }
      className={
        classNames(
          `min-w-full min-h-12`,
          className,
        )
      }>
      <Listbox value={ selectedValue }
        onChange={ handleChange }
        disabled={ disabled }
      >
        { ({ open }) => (
          <>
            <Label
              as={ ForwardRefFormLabel }
              className={ label && `mb-2 flex` }
            >
              { label }
              <When condition={ !!helpIcon }>
                <HelpIcon
                  tooltip={ helpIcon }
                  className={ `ml-1` }
                />
              </When>
            </Label>
            <When condition={ description }>
              <FormDescription>
                { description }
              </FormDescription>
              <div className={ `mb-1` } />
            </When>

            <When condition={ helpText }>
              <Paragraph variant={ `help` }>
                { helpText }
              </Paragraph>
            </When>
            <div className={ `relative` }>
              <ListboxButton className={
                classNames(
                  `relative min-w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-lateGreen-500 focus:outline-none focus:ring-1 focus:ring-lateGreen-500 sm:text-sm`,
                  invalid && classes[`invalid`],
                  disabled && `opacity-50`,
                  nestedInTextField && `rounded-none rounded-r-md border-l-none`,
                )
              }>
                <span className={ `block truncate` }>
                  <span className={ `flex justify-between` }>
                    <div className={ `flex items-center` }>
                      <Tooltip title={ selectedValue?.label }>
                        <p className={ valueClassname }>
                          { selectedValue?.label } { ` ` }
                        </p>
                      </Tooltip>
                      <When condition={ !!suffixElement }>
                        <div className={ `ml-1` }>
                          { suffixElement }
                        </div>
                      </When>
                    </div>
                    <When condition={ displaySuffix }>
                      <p className={ `ml-1` }>
                        { displaySuffix }
                      </p>
                    </When>
                    <When condition={ loading }>
                      <SmallSpinner className={ `ml-1` } />
                    </When>
                    <When condition={ activeBadge && activeBadgeIsActive !== undefined }>
                      <Switch>
                        <Case condition={ activeBadgeIsActive }>
                          <Tooltip title={ `Active (Automatic)` }>
                            <p>
                              <ShieldCheckIcon className={ `h-5 w-5 text-green-400` }
                                aria-hidden={ `true` } />
                            </p>
                          </Tooltip>
                        </Case>
                        <Default>
                          <Tooltip title={ `Safe Mode (Processing Paused)` }>
                            <p>
                              <ShieldCheckIcon className={ `h-5 w-5 text-blue-400` }
                                aria-hidden={ `true` } />
                            </p>
                          </Tooltip>
                        </Default>
                      </Switch>
                    </When>
                  </span>
                </span>
                <span className={ `pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2` }>
                  <ChevronUpDownIcon className={ `h-5 w-5 text-gray-400` }
                    aria-hidden={ `true` } />
                </span>
              </ListboxButton>

              <Transition
                show={ open }
                as={ Fragment }
                leave={ `transition ease-in duration-100` }
                leaveFrom={ `opacity-100` }
                leaveTo={ `opacity-0` }
              >
                <ListboxOptions className={
                  classNames(
                    `absolute z-10 mt-1 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`,
                  )
                }>
                  { opts.map((option, i) => (
                    <ListboxOption
                      key={ `${option.value}_${i}` }
                      className={ ({ focus }) =>
                        classNames(
                          focus ? `text-white bg-lateGreen-600` : `text-gray-900`,
                          `relative select-none py-2 pl-8 pr-4`,
                          option.disabled ? `opacity-50 cursor-not-allowed` : `cursor-pointer`,
                        )
                      }
                      value={ option }
                    >
                      { ({ selected, focus }) => (
                        <>
                          <Tooltip
                            title={ option.tooltip || option.label }
                            placement={ `left` }
                          >
                            <span className={ classNames(selected ? `font-semibold` : `font-normal`, `block `) }>
                              { option.label }
                              { ` ` }
                              <When condition={ option.badge }>
                                <Badge message={ option.badge }
                                  color={ option.badgeColor } />
                              </When>
                            </span>
                          </Tooltip>
                          <When condition={ option.description }>
                            <span className={ `text-xs text-gray-500` }>
                              { option.description }
                            </span>
                          </When>

                          <When condition={ selected }>
                            <span
                              className={ classNames(
                                focus ? `text-white` : `text-lateGreen-600`,
                                `absolute inset-y-0 left-0 flex items-center pl-1.5`,
                              ) }
                            >
                              <CheckIcon className={ `h-5 w-5` }
                                aria-hidden={ `true` } />
                            </span>
                          </When>
                        </>
                      ) }
                    </ListboxOption>
                  )) }
                </ListboxOptions>
              </Transition>
            </div>
          </>
        ) }
      </Listbox>
    </div>
  );
}
