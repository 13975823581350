import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useRef } from "react";
import { When } from "react-if";
import { Transition, TransitionChild } from "@headlessui/react";

import { Heading } from "./Typography/Heading";
import { Paragraph } from "./Typography/Paragraph";

export interface AccordianProps {
  title?: string | React.ReactNode;
  description?: string;
  children: React.ReactNode;
  open?: boolean;
  setOpen?: () => void;
}

export function Accordian({
  title,
  description,
  children,
  open,
  setOpen,
}: AccordianProps) {
  const childRef = useRef(null);

  function onButtonClick(e) {
    e.preventDefault();
    setOpen();
  }

  return (
    <div className={ `border-b border-gray-200 py-4` }>
      <button
        className={ `flex justify-between w-full` }
        onClick={ onButtonClick }
      >
        <div className={ `flex flex-col text-left` }>
          <Heading>
            { title }
          </Heading>

          <Paragraph
            variant={ `help` }
          >
            { description }
          </Paragraph>
        </div>

        <div className={ `self-center` }>
          <When condition={ !open }>
            <ChevronRightIcon className={ `h-5 w-5 flex-none text-gray-400` }
              aria-hidden={ `true` } />
          </When>

          <When condition={ open }>
            <ChevronUpIcon className={ `h-5 w-5 flex-none text-gray-400` }
              aria-hidden={ `true` } />
          </When>
        </div>
      </button>
      <Transition
        show={ open }
      >
        <TransitionChild
          ref={ childRef }
          as={ `div` }
        >
          <div className={ `pt-2` }>
            { children }
          </div>
        </TransitionChild>
      </Transition>
    </div>
  );
}
