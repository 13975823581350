import { IContact, ICreditNote, StatusEnum } from "shared";

// TEMP
export interface UILineItem {
  id?: string;
  description: string;
  quantity: number;
  amount: string;
  total: string;
  taxAmount?: string;
  accountCode?: string;
}

// TEMP UNTIL WE DO INVOICE API
export interface InvoiceDetails {
  number: string;
  reference: string;
  status: StatusEnum;
  contact: IContact;
  dueDateString: string;
  issueDateString: string;
  organisationTimezone: string;
  lineItems: UILineItem[];
  subTotal: string;
  total: string;
  tax: string;
  discounts: string;
  paidAmount: string;
  dueAmount: string;
  externalLink?: string;
  frozen: boolean;
  deleted: boolean;
  createdByUs: boolean;
  creditNotes: ICreditNote[];
  groupTitle?: string;
  sent_to_customer?: boolean;
  currency: string;
  hash: string;
  expectedPaymentDate?: string;
}

export function parseLineItems(invoice, formatter): UILineItem[] {
  if (!invoice || (!invoice.xero_line_items?.lineItems && !invoice.qbo_line_items?.lineItems)) return [];

  if (invoice.xero_line_items?.lineItems) {
    return invoice.xero_line_items.lineItems.map(item => {
      return {
        id: item.lineItemID,
        description: item.description,
        quantity: item.quantity,
        amount: formatter(item.unitAmount),
        total: formatter(item.lineAmount),
        taxAmount: item.taxAmount ? formatter(item.taxAmount) : undefined,
        accountCode: item.accountCode,
      };
    });
  }
}
