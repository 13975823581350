import { Case, Switch } from "react-if";
import { Fragment } from "react";
import { Tooltip } from "@mui/material";
import { IBadContact } from "shared";

import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useOrgMetrics } from "../../../hooks/useOrgMetrics";
import { Badge } from "../../../../common/Atoms/Badge";
import { CardContent } from "../../../../common/Atoms/CardContent";
import { WrappedLink } from "../../WrappedLink";
import { LoadingElementPlaceholder } from "../../../../common/Atoms/LoadingElementPlaceholder";

function renderBalances(contact: IBadContact) {
  const overdueBalances: string[] = [];

  // for any non-zero balances, add the overdue string to the list
  contact.balances.filter(b => b.overdue > 0).forEach(balance => {
    overdueBalances.push(balance.overdueString);
  });

  return (
    <div className={ `max-w-fit place-self-center` }>
      <Paragraph>
        <Tooltip title={ `Overdue balance` }>
          <span>
            { overdueBalances.join(`, `) }
          </span>
        </Tooltip>
      </Paragraph>
    </div>
  );
}

export function BadContacts() {
  const { data: metrics, isLoading } = useOrgMetrics();

  const badContacts = metrics?.badContacts || [];

  return (
    <Card className={ `w-full h-full` }>
      <MultiButtonCardHeader
        title={ `Slowest Payers` }
        description={ `Contacts with the oldest overdue invoices` }
      />
      <CardContent className={ `mt-4` }>
        <Switch>
          <Case condition={ isLoading }>
            <LoadingElementPlaceholder
              border={ false }
              rows={ 6 }
            />
          </Case>
          <Case condition={ !badContacts.length }>
            <div className={ `flex justify-center` }>
              <Paragraph>
                { `No slow payers!` }
              </Paragraph>
            </div>
          </Case>
          <Case condition={ badContacts.length }>
            <div className={ `grid grid-cols-3 gap-y-2` }>
              { badContacts.map(contact => {
                return (
                  <Fragment
                    key={ contact.id }
                  >
                    { /* Name link */ }
                    <WrappedLink
                      to={ `/contacts/${contact.id}` }
                    >
                      <Paragraph
                        variant={ `link` }
                        className={ `truncate` }
                      >
                        { contact.name }
                      </Paragraph>
                    </WrappedLink>

                    { /* Balances */ }
                    { renderBalances(contact) }

                    { /* Age */ }
                    <div className={ `max-w-fit place-self-end` }>
                      <Tooltip title={ `Age of oldest overdue invoice` }>
                        <Badge
                          color={ `red` }
                        >
                          { contact.daysOverdue.max }
                          { ` days overdue` }
                        </Badge>
                      </Tooltip>
                    </div>

                  </Fragment>
                );
              })
              }
            </div>
          </Case>

        </Switch>
      </CardContent>
    </Card>
  );
}
