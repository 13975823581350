import { EmailTemplateTypes } from "shared";
import { useMemo } from "react";
import { When } from "react-if";
import { useParams } from "react-router";

import { useSendInvoiceReminderMutation } from "../../services/api/invoiceApi/invoice";
import { DropdownOption, Dropdown } from "../../../common/Atoms/Dropdown";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { useEmailTemplatesByType } from "../../hooks/useEmailTemplates";
import { useIsSafeMode } from "../../hooks/useIsSafeMode";
import { Modal } from "../../../common/Atoms/Modal";

interface SendReminderEmailProps {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoice: any;
}

export function SendReminderEmailModal({ open, onClose, invoice }: SendReminderEmailProps) {
  const { organisationId, invoiceId } = useParams();
  const emailTemplates = useEmailTemplatesByType(EmailTemplateTypes.REMINDER);
  const [sendReminder, { isLoading: isSending }] = useSendInvoiceReminderMutation();
  const safeModeOn = useIsSafeMode();

  async function onSend(templateId: string) {
    await sendReminder({
      invoiceId,
      templateId,
      organisationId,
    });

    onClose();
  }
  const options: DropdownOption[] = useMemo(() => {
    return emailTemplates.map(template => ({
      label: template.name,
      onClick: () => onSend(template.id),
      loading:isSending,
    }));
  }, [emailTemplates, isSending]);

  if (!invoice) {
    return null;
  }

  return (
    <Modal
      open={ open }
      setOpen={ onClose }
      title={ `` }
    >
      <div className={ `flex justify-center flex-col items-center pb-6` }>
        <Heading>
          { `Send email reminder for ${invoice.xero_number}` }
        </Heading>

        <Paragraph
          variant={ `help` }
          className={ `text-center` }
        >
          { `Immediately send a email reminder for ${invoice.xero_number}'s contacts with the selected email template.` }
        </Paragraph>

        <When condition={ safeModeOn }>
          <Paragraph
            variant={ `warning` }
            className={ `text-center` }
          >
            { `This will send even in safe mode!` }
          </Paragraph>
        </When>
      </div>
      <div className={ `flex justify-center` }>
        <Dropdown
          disabled={ options.length === 0 }
          options={ [options] }
          label={ `Choose email template to send with` }
          size={ `md` }
        />
      </div>
    </Modal>
  );
}
