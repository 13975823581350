import { Switch, Case } from 'react-if';
import { EmailTemplateTypes, PolicyCalculationType } from 'shared';

import { RadioStack } from '../../../../../common/Atoms/RadioStack';
import { InputTextAddon } from '../../../../../common/Atoms/InputTextAddon';
import { fieldIsValid } from '../../../../lib/helper';
import { useAccountingSystemTerm } from '../../../../hooks/useAccountingSystemTerm';
import { Grid } from '../../../../../common/Atoms/Grid';
import { GridItem } from '../../../../../common/Atoms/GridItem';
import { CurrencyInput } from '../../../../../common/Atoms/CurrencyInput';
import { AccountSelector } from '../PolicyForm/AccountSelector';
import { IPolicyProps } from '../PolicyItem';
import { TaxSelector } from '../PolicyForm/TaxSelector';
import { EmailTemplateSelector } from '../PolicyForm/Fields/EmailTemplateSelector';

const calcucateOptions = [
  {
    title: `Fixed Discount`,
    description: `Applies a fixed discount in the invoice's base currency`,
    value: PolicyCalculationType.FIXED,
  },
  {
    title: `Percentage Discount`,
    description: `Applies a discount based on the percentage of the invoice total`,
    value: PolicyCalculationType.PERCENT,
  },
];

const discountAppliesOptions = [
  {
    title: `Apply to new invoices`,
    description: `Apply discounts to any new invoice`,
    value: `new`,
  },
  {
    title: `Apply only to unsent invoices`,
    description: `Apply discounts to unsent new invoices`,
    value: `unsent`,
  },
];

export function PolicyPromptPaymentDiscount({ policy, invalids, handleChange }: IPolicyProps) {
  return (
    <>
      <RadioStack
        options={ calcucateOptions }
        onChange={ handleChange }
        selected={ policy.calculation_type }
        name={ `calculation_type` }
      />

      <RadioStack
        options={ discountAppliesOptions }
        onChange={ handleChange }
        selected={ policy.discount_applies }
        name={ `discount_applies` }
      />

      <Grid
        cols={ 4 }
        gapX={ 2 }
      >
        <GridItem>
          <AccountSelector
            label={ useAccountingSystemTerm(`Discount Account`) }
            value={ policy.xero_account_code }
            valueKey={ `code` }
            onChange={ handleChange }
            name={ `xero_account_code` }
            invalid={ fieldIsValid(`xero_account_code`, invalids) }
            description={ `The Xero account to record the negative amount against` }
          />
        </GridItem>
        <GridItem
        >
          <TaxSelector
            label={ useAccountingSystemTerm(`Tax Rate`) }
            code={ policy.tax_rate }
            onChange={ handleChange }
            name={ `tax_rate` }
            invalid={ fieldIsValid(`tax_rate`, invalids) }
          />
        </GridItem>
        <GridItem>
          <Switch>
            <Case condition={ policy.calculation_type === PolicyCalculationType.FIXED }>
              <CurrencyInput
                label={ `Discount Amount` }
                invalid={ fieldIsValid(`fixed_fee_amount`, invalids) }
                value={ policy.fixed_fee_amount }
                onChange={ handleChange }
                name={ `fixed_fee_amount` }
                description={ `The amount to discount the invoice by. In the Invoice's base currency` }
              />
            </Case>
            <Case condition={ policy.calculation_type === PolicyCalculationType.PERCENT || policy.calculation_type === PolicyCalculationType.PRORATA }>
              <InputTextAddon
                invalid={ fieldIsValid(`percent`, invalids) }
                label={ `Discount %` }
                value={ policy.percent }
                onChange={ handleChange }
                addOnText={ `%` }
                name={ `percent` }
                description={ `The percentage to discount the invoice by` }
              />
            </Case>
          </Switch>
        </GridItem>
        <GridItem>
          <InputTextAddon
            label={ `Discount Period` }
            value={ policy.grace_period }
            onChange={ handleChange }
            addOnText={ `days` }
            name={ `grace_period` }
            invalid={ fieldIsValid(`grace_period`, invalids) }
            description={ `Number of days before due date that the discount is removed. Use 0 days to remove the discount on the due date.` } />
        </GridItem>
      </Grid>

      <InputTextAddon
        label={ `Description` }
        value={ policy.line_item_text }
        onChange={ handleChange }
        name={ `line_item_text` }
        invalid={ fieldIsValid(`line_item_text`, invalids) }
        description={ `Appears on the invoice. Use #DiscountDate for the date the discount will be removed` } />

      <EmailTemplateSelector
        value={ policy.template_id }
        type={ EmailTemplateTypes.DISCOUNT }
        handleChange={ handleChange }
        invalid={ fieldIsValid(`template_id`, invalids) }
        name={ `template_id` }
        label={ `Send email notification to customer when discount is applied` }
        nullable
        emptyText={ `Do not send email notification` }
        helpIcon={ `Create or edit the template under Email Templates.` }
      />
    </>
  );
}
