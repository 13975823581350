import { When } from 'react-if';
import { useNavigate } from 'react-router';

import { Toggle } from '../../../../../common/Atoms/Toggle';
import { TwoColFormRow } from '../../../Molecules/Form/TwoColFormRow';
import { useHasValidSubscription } from '../../../../hooks/useHasValidSubscription';
import { Notification } from '../../../../../common/Atoms/Notification';
import { Button } from '../../../../../common/Atoms/Button';
import { useGetSubscriptionMetadata } from '../../../../hooks/useGetSubscription';

import { OnChangeFn } from './GeneralSettings';

interface SafeModeProps {
  safeModeOn: boolean
  setSafeModeOn: OnChangeFn
}

export function SafeMode({ safeModeOn, setSafeModeOn }: SafeModeProps) {
  const hasValidSub = useHasValidSubscription();
  const { subscriptionMetadata, isBillingOwner } = useGetSubscriptionMetadata();
  const navigate = useNavigate();

  function onChange() {
    setSafeModeOn(`active`, !!safeModeOn);
  }

  return (
    <TwoColFormRow
      heading={ `Safe Mode` }
    >
      <Toggle
        label={ `Safe Mode` }
        checked={ safeModeOn }
        disabled={ !hasValidSub }
        onChange={ onChange }
        description={ `Safe mode prevents automatic processing of invoices. Turn off safe mode when you are ready to start applying policies automatically.` }
        secondaryDescription={ `Pending actions will still be created in safe mode, so you can see which actions will occur when safe mode is turned off.` }
      />
      <When condition={ !hasValidSub }>
        <Notification
          className={ `mt-4` }
          type={ `warning` }
          buttons={ (isBillingOwner && subscriptionMetadata.hasSubscription) && (
            <Button
              onClick={ () => navigate(`/billing/subscriptions/${subscriptionMetadata.id}`) }
            >
              { `Start Trial` }
            </Button>
          ) }
        >
          <span>
            { `You must subscribe to a plan or start a trial to disable safe mode!` }
          </span>
        </Notification>
      </When>
    </TwoColFormRow>
  );
}
