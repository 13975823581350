import { useMemo } from 'react';
import { EmailTemplateTypes, PolicyTargetStatus } from 'shared';
import { When } from 'react-if';

import { FormRow } from '../../../Molecules/Form/FormRow';
import { TextEditor } from '../../../Molecules/TextEditor';
import { useMergeTags } from '../../../../hooks/useMergeTagsAndLinks';
import { FormLabel } from '../../../../../common/Atoms/FormLabel';
import { FormDescription } from '../../../../../common/Atoms/FormDescription';
import { IPolicyProps } from '../PolicyItem';
import { ApplyInvoicesFrom } from '../PolicyForm/Fields/ApplyInvoicesFrom';
import { ApplyInvoicesTo } from '../PolicyForm/Fields/ApplyInvoicesTo';
import { TimeOfDay } from '../PolicyForm/Fields/TimeOfDay';
import { MinimumDue } from '../PolicyForm/Fields/MinimumDue';
import { MaximumDue } from '../PolicyForm/Fields/MaximumDue';
import { useGetSelectedOrganisation } from '../../../../hooks/useGetSelectedOrganisation';
import { ExcludeBrandingThemes } from '../PolicyForm/Fields/ExcludeBrandingThemes';
import { ContactTagFilter } from '../PolicyForm/Fields/ContactTagFilter';
import { XeroContactsGroupFilter } from '../PolicyForm/Fields/XeroContactGroupsFilter';
import { SMSDefaultCountryCode } from '../PolicyForm/Fields/SMSDefaultCountryCode';
import { SMSFallbackToPhone } from '../PolicyForm/Fields/SMSFallbackToPhone';
import { Paragraph } from '../../../../../common/Atoms/Typography/Paragraph';
import { useAllowedFeature } from '../../../../hooks/useAllowedFeature';
import { OnlyWebhooks } from '../PolicyForm/Fields/OnlyWebhooks';
import { AccordianConfig, AccordianGroup } from '../../../../../common/Atoms/AccordianGroup';

import { StandardPolicyTrigger } from './Sections/StandardPolicyTrigger';
import { PolicyFormRow } from './Sections/PolicyFormRow';

export function PolicySmsNew(props: IPolicyProps) {
  const { policy, invalids, handleChange } = props;
  const mergeTags = useMergeTags(EmailTemplateTypes.SMS); // TODO: Other types
  const currentOrg = useGetSelectedOrganisation();
  const allowedWebhooks = useAllowedFeature(`webhooks`);

  const formattedMergeTags = useMemo(() => {
    return mergeTags.asArray
      .filter(tag => tag.value !== `ForecastedPenaltyAmount` && !tag.archived)
      .map(tag => {
        return {
          text: tag.name,
          value: tag.value,
        };
      });
  }, [ mergeTags ]);

  function handleSmsContentChange({ text }) {
    handleChange({
      name: `sms_content`,
      value: text,
    });
  }

  const accordians: AccordianConfig[] = [
    {
      title: `When`,
      name: `${policy.id}-when`,
      description: `Configure when the SMS will be sent`,
      initialOpen: true,
      children: (
        <div className={ `space-y-3` }>
          <StandardPolicyTrigger
            excludedTriggerTypes={ [ PolicyTargetStatus.MARKED_PAID] }
            policy={ policy }
            handleChange={ handleChange }
            invalids={ invalids }
          />

          <PolicyFormRow columns={ 1 }>
            <TimeOfDay
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
              labelOverride={ `Time of Day` }
            />
          </PolicyFormRow>
        </div>
      ),
    },
    {
      title: `Who`,
      name: `${policy.id}-who`,
      description: `More fine grain control over which invoices & contacts within this group the SMS will be sent to`,
      children: (
        <div className={ `space-y-3` }>
          <PolicyFormRow columns={ 1 }>
            <ContactTagFilter
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
            />

            <When condition={ currentOrg?.accountingSystemType === `XERO` }>
              <XeroContactsGroupFilter
                policyFormData={ policy }
                handleChange={ handleChange }
                invalids={ invalids }
              />
            </When>
          </PolicyFormRow>

          <PolicyFormRow columns={ 4 }>
            <ApplyInvoicesFrom
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
            />

            <ApplyInvoicesTo
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
            />

            <MinimumDue
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
            />

            <MaximumDue
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
            />
          </PolicyFormRow>

          { /* TODO - make configurable again */ }
          <When condition={ currentOrg?.accountingSystemType === `XERO` }>
            <PolicyFormRow columns={ 1 }>
              <ExcludeBrandingThemes
                policyFormData={ policy }
                handleChange={ handleChange }
                invalids={ invalids }
              />
            </PolicyFormRow>
          </When>
        </div>
      ),
    },
    {
      title: `How`,
      name: `${policy.id}-how`,
      description: `Customize the content of the SMS message & how it is sent`,
      children: (
        <div className={ `space-y-3` }>
          <FormRow className={ `space-y-1` }>
            <FormLabel>{ `Text Message Content` }</FormLabel>
            <FormDescription>{ `Use merge tags to dynamic insert values. Maximum 320 characters` }</FormDescription>
            <Paragraph variant={ `help` }>
              { `Will be sent to the primary mobile phone number on file for the invoice contact if available.` }
            </Paragraph>
            <TextEditor
              value={ policy.sms_content }
              onChange={ handleSmsContentChange }
              mergeTags={ formattedMergeTags }
              mode={ `sms` }
            />
          </FormRow>

          <PolicyFormRow columns={ 2 }>
            <SMSDefaultCountryCode
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
            />

            <SMSFallbackToPhone
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
            />
          </PolicyFormRow>

          <When condition={ allowedWebhooks }>
            <OnlyWebhooks
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
            />
          </When>
        </div>
      ),
    },
  ];

  return (
    <AccordianGroup
      accordians={ accordians }
    />
  );
}
