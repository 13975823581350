import { HexColorPicker } from "react-colorful";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

interface IColorPickerProps {
  value?: string | null;
  onChange: (value: string | null) => void;
}

export function ColorPicker({ value, onChange }: IColorPickerProps) {
  return (
    <Popover className={ `relative` }>
      <PopoverButton
        className={ `rounded-full w-[24px] h-[24px] border-lateGreen-800 border` }
        style={ { backgroundColor: value } }
      >
      </PopoverButton>

      <PopoverPanel
        className={ `absolute z-10 right-0` }
      >
        <HexColorPicker
          color={ value || `` }
          onChange={ onChange }
        />
      </PopoverPanel>
    </Popover>
  );
}
