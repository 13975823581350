import { Fragment } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { When } from 'react-if';
import { Tooltip } from '@mui/material';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';

import { classNames }  from '../lib/classNames';

import { SmallSpinner } from './SmallSpinner';
import { Badge } from './Badge';
import { Button } from './Button';

const themes = {
  white: {
    items: {
      active: `bg-gray-100 text-gray-900`,
      inactive: `text-gray-700`,
    },
  },
  primary: {
    items: {
      active: `bg-gray-100 text-lateGreen-900`,
      inactive: `text-lateGreen-700`,
    },
  },
};

interface IOption {
  paywalled?: boolean;
  badge?: string;
  label: string;
  badgeColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any) => void;
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string;
}

interface IDropdownProps {
  options: Array<Array<IOption>>
  label?: string;
  className?: string;
  disabled?: boolean;
  theme?: keyof typeof themes;
  position?: `left` | `right`;
  onSelect?: (option: IOption) => void;
  size?: `sm` | `md`;
  ellipsis?: boolean;
  loading?: boolean;
}

export type DropdownOption = IOption;

export function Dropdown({
  options, // Multidimensional array of options
  label,
  className,
  disabled,
  theme = `white`,
  position = `left`,
  onSelect,
  size = `sm`,
  ellipsis,
  loading,
}: IDropdownProps) {

  function onClick(e, option, close) {
    e.preventDefault();

    if (option.paywalled || option.disabled) return;

    if (onSelect) {
      // Global onSelect handler takes priority
      onSelect(option);

      close();

      return;
    }

    option.onClick && option.onClick(option);

    close();
  }

  function renderMenuItems() {
    return options.map((optionArray, i) => {
      return (
        <div className={ `py-1` }
          key={ i }>
          {
            optionArray.map((option, j) => {
              return (
                <MenuItem key={ j }>
                  { ({ focus, close }) => (
                    <Tooltip
                      title={ option.tooltip }
                      placement={ `left` }
                    >
                      <a
                        href={ `#` }
                        onClick={ e => onClick(e, option, close) }
                        className={ classNames(
                          focus ? themes[theme].items.active : themes[theme].items.inactive,
                          `block px-4 py-2 text-sm`,
                          option.paywalled || option.disabled && `opacity-50 cursor-not-allowed`,
                        ) }
                      >
                        { option.label }
                        <When condition={ option.paywalled }>
                          { ` ` }
                          <Badge color={ `gray` }
                            message={ `Upgrade Plan` } />
                        </When>
                        <When condition={ option.badge }>
                          { ` ` }
                          <Badge color={ option.badgeColor }
                            message={ option.badge } />
                        </When>
                      </a>
                    </Tooltip>
                  ) }
                </MenuItem>
              );
            })
          }
        </div>
      );
    });
  }

  const anyLoading = options.some(optionArray => optionArray.some(option => option.loading));

  return (
    <Menu as={ `div` }
      className={
        classNames(
          `relative inline-block text-left`,
          className,
        )
      }>
      <div>
        <MenuButton
          as={ (props => <Button
            size={ size }
            { ...props }
            className={ classNames(
              props.className,
              ellipsis && `rounded-full`,
            ) }
          />) as unknown as `button` }
          disabled={ disabled || loading }
          className={ classNames(
            ellipsis && `rounded-full`,
            loading && `cursor-wait`,
          ) }
        >
          <When condition={ ellipsis }>
            <EllipsisVerticalIcon className={ `w-6 h-6 text-white` } />
          </When>

          <When condition={ !ellipsis }>
            { label }
          </When>
          <When condition={ anyLoading }>
            <SmallSpinner className={ `ml-2` } />
          </When>

          <When condition={ !ellipsis }>
            <ChevronDownIcon className={ `-mr-1 h-5 w-5 text-gray-400` }
              aria-hidden={ `true` }
            />
          </When>
        </MenuButton>
      </div>

      <Transition
        as={ Fragment }
        enter={ `transition ease-out duration-100` }
        enterFrom={ `transform opacity-0 scale-95` }
        enterTo={ `transform opacity-100 scale-100` }
        leave={ `transition ease-in duration-75` }
        leaveFrom={ `transform opacity-100 scale-100` }
        leaveTo={ `transform opacity-0 scale-95` }
      >
        <MenuItems className={
          classNames(
            `absolute`,
            position === `left` ? `right-0` : `left-0`,
            `z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md whitespace-nowrap bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`,
          )
        }>
          {
            renderMenuItems()
          }
        </MenuItems>
      </Transition>
    </Menu>
  );
}
