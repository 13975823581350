import { useMemo } from "react";
import { displayPhoneNumber } from "shared";
import { When } from "react-if";

import { Card } from "../../../../../common/Atoms/Card";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { useContact } from "../../../../hooks/useContact";
import { Notification } from "../../../../../common/Atoms/Notification";

function renderName(firstName?: string, lastName?: string) {
  if (!firstName && !lastName) {
    return `(No name found)`;
  }

  if (!firstName) {
    return lastName;
  }

  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
}

function renderIncludedElement(included: boolean) {
  if (included) {
    return (
      <div className={ `mt-1 flex items-center gap-x-1.5` }>
        <div className={ `flex-none rounded-full bg-emerald-500/20 p-1 animate-pulse` }>
          <div className={ `h-1.5 w-1.5 rounded-full bg-emerald-500` } />
        </div>
        <Paragraph
          variant={ `secondary` }
          size={ `xs` }
        >
          { `Included In Emails` }
        </Paragraph>
      </div>
    );
  }

  return (
    <div className={ `mt-1 flex items-center gap-x-1.5` }>
      <div className={ `flex-none rounded-full bg-red-500/20 p-1 animate-pulse` }>
        <div className={ `h-1.5 w-1.5 rounded-full bg-red-500` } />
      </div>
      <Paragraph
        variant={ `secondary` }
        size={ `xs` }
      >
        { `Excluded From Emails` }
      </Paragraph>
    </div>
  );
}

export function ContactPersons() {
  const { data: contact } = useContact();

  const people = useMemo(() => {
    if (!contact) {
      return [];
    }

    const persons = contact.contactPersons.map(person => {
      return {
        title: renderName(person.firstName, person.lastName),
        email: person.email,
        role: `Additional Contact`,
        includedInNotifications: person.includeInNotifications,
        mobileNumber: displayPhoneNumber(person.mobileCountryCode, person.mobileNumber),
        defaultNumber: displayPhoneNumber(person.defaultCountryCode, person.defaultNumber),
        emailError: person.emailError,
      };
    });

    persons.unshift({
      title: renderName(contact.primaryContactPerson.firstName, contact.primaryContactPerson.lastName),
      email: contact.primaryContactPerson.email,
      role: `Primary Contact`,
      includedInNotifications: contact.primaryContactPerson.includeInNotifications,
      mobileNumber: displayPhoneNumber(contact.primaryContactPerson.mobileCountryCode, contact.primaryContactPerson.mobileNumber),
      defaultNumber: displayPhoneNumber(contact.primaryContactPerson.defaultCountryCode, contact.primaryContactPerson.defaultNumber),
      emailError: contact.primaryContactPerson.emailError,
    });

    return persons;
  }, [ contact ]);

  return (
    <Card>
      <Heading>
        { `Contact People` }
      </Heading>

      <ul role={ `list` }
        className={ `divide-y divide-gray-100` }>
        { people.map(person => (
          <li key={ person.email }
            className={ `` }>
            <div className={ `flex justify-between gap-x-6 py-5` }>
              <div className={ `flex min-w-0 gap-x-4` }>
                <div className={ `min-w-0 flex-auto` }>
                  <Paragraph>
                    { person.title }
                  </Paragraph>
                  <Paragraph
                    variant={ `secondary` }
                    className={ `truncate` }
                    tooltipped
                  >
                    { person.email }
                  </Paragraph>
                  { /* When a contact has a default number but no mobile. Still show mobile to highlight it being empty */ }
                  <When condition={ person.mobileNumber || person.defaultNumber }>
                    <Paragraph
                      variant={ `secondary` }
                    >
                      <span>
                        { `Mobile: ` }
                      </span>
                      { person.mobileNumber }
                    </Paragraph>
                  </When>

                  <When condition={ person.defaultNumber }>
                    <Paragraph
                      variant={ `secondary` }
                    >
                      <span>
                        { `Phone: ` }
                      </span>
                      { person.defaultNumber }
                    </Paragraph>
                  </When>
                </div>
              </div>
              <div className={ `hidden shrink-0 sm:flex sm:flex-col sm:items-end` }>
                <Paragraph>
                  { person.role }
                </Paragraph>

                { renderIncludedElement(person.includedInNotifications) }
              </div>
            </div>

            <When condition={ person.emailError }>
              <Notification
                type={ `warning` }
              >
                { person.emailError }
              </Notification>
            </When>
          </li>
        )) }
      </ul>
    </Card>
  );
}
