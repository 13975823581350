import { Fragment } from 'react';
import { Dialog, Transition, Disclosure, TransitionChild, DialogPanel, DisclosurePanel, DisclosureButton } from '@headlessui/react';
import {
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Default, Switch, Case, When } from 'react-if';
import { useNavigate } from 'react-router';
import { Tooltip } from '@mui/material';

import { classNames }  from '../../../common/lib/classNames';
import { useNavigationOpts } from '../../hooks/useNavigationOpts';
import { WrappedLink } from '../WrappedLink';
import { useHasValidSubscription } from '../../hooks/useHasValidSubscription';
import { Badge } from '../../../common/Atoms/Badge';
import { useGetSubscriptionMetadata } from '../../hooks/useGetSubscription';
import { UpgradeBadge } from '../Molecules/UpgradeBadge';
import { Divider } from '../../../common/Atoms/Divider';
import { useCreateGroupMutation } from '../../services/api/groupApi/group';

import { Logo } from './Logo';

export function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  currentOrg,
}) {
  const { navigation } = useNavigationOpts(!!currentOrg);
  const navigate = useNavigate();
  const hasValidSubscription = useHasValidSubscription();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();
  const [createGroup] = useCreateGroupMutation();

  const noSub = item => {
    return (!hasValidSubscription && item.requiresSubscription);
  };

  const upgrade = item => {
    if (hasValidSubscription) {
      return item.requiresPaidFeature && !subscriptionMetadata?.features?.[item.requiresPaidFeature];
    }
  };

  const notAllowed = item => {
    return noSub(item) || upgrade(item);
  };

  async function onNewGroup() {
    const res = await createGroup({ organisationId: currentOrg.id }).unwrap();

    const createdId = res?.id;

    if (createdId) {
      navigate(`${currentOrg.id}/groups/${createdId}`);
    }
  }

  return (
    <Transition show={ sidebarOpen }
      as={ Fragment }>
      <Dialog as={ `div` }
        className={ `relative z-40 md:hidden` }
        onClose={ setSidebarOpen }>
        <TransitionChild
          as={ Fragment }
          enter={ `transition-opacity ease-linear duration-300` }
          enterFrom={ `opacity-0` }
          enterTo={ `opacity-100` }
          leave={ `transition-opacity ease-linear duration-300` }
          leaveFrom={ `opacity-100` }
          leaveTo={ `opacity-0` }
        >
          <div className={ `fixed inset-0 bg-gray-600 bg-opacity-75` } />
        </TransitionChild>

        <div className={ `fixed inset-0 z-40 flex` }>
          <TransitionChild
            as={ Fragment }
            enter={ `transition ease-in-out duration-300 transform` }
            enterFrom={ `-translate-x-full` }
            enterTo={ `translate-x-0` }
            leave={ `transition ease-in-out duration-300 transform` }
            leaveFrom={ `translate-x-0` }
            leaveTo={ `-translate-x-full` }
          >
            <DialogPanel className={ `relative flex w-full max-w-xs flex-1 flex-col bg-lateGreen-700 pt-5 pb-4` }>
              <TransitionChild
                as={ Fragment }
                enter={ `ease-in-out duration-300` }
                enterFrom={ `opacity-0` }
                enterTo={ `opacity-100` }
                leave={ `ease-in-out duration-300` }
                leaveFrom={ `opacity-100` }
                leaveTo={ `opacity-0` }
              >
                <div className={ `absolute top-0 right-0 -mr-12 pt-2` }>
                  <button
                    type={ `button` }
                    className={ `ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white` }
                    onClick={ () => setSidebarOpen(false) }
                  >
                    <span className={ `sr-only` }>{ `Close sidebar` }</span>
                    <XMarkIcon className={ `h-6 w-6 text-white` }
                      aria-hidden={ `true` } />
                  </button>
                </div>
              </TransitionChild>
              <div className={ `flex flex-shrink-0 items-center px-4` }>
                <Logo type={ `white` }/>
              </div>
              <div className={ `mt-5 h-0 flex-1 overflow-y-auto` }>
                <nav className={ `space-y-8 px-2` }>
                  { navigation.map((item, i) => {
                    return (
                      <Switch key={ i }>
                        <Case condition={ item.href === `ADD_NEW_GROUP` }>
                          <a
                            key={ item.name }
                            href={ `#` }
                            data-tour={ item.demoTarget }

                            className={ classNames(
                              item.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                              `group flex items-center px-2 py-2 text-sm font-medium rounded-md`,
                              notAllowed(item) && `opacity-50 cursor-not-allowed`,
                            ) }
                            onClick={ e => {
                              e.preventDefault();
                              onNewGroup();
                            } }
                          >
                            <>
                              <item.icon
                                className={ classNames(
                                  item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                                  `mr-3 flex-shrink-0 h-6 w-6`,
                                ) }
                                aria-hidden={ `true` }
                              />
                              { item.name }
                            </>
                          </a>
                        </Case>
                        <Case condition={ item.href === `SPACE` }>
                          <div className={ `` }>
                            <Divider
                              weight={ `light` }
                              text={ item.name }
                              spacing={ `small` }
                            />
                          </div>
                        </Case>
                        <Case condition={ item.external }>
                          <a
                            href={ item.href }
                            target={ `_blank` }
                            rel={ `noreferrer` }
                            className={ classNames(
                              item.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                              `group flex items-center px-2 py-2 text-sm font-medium rounded-md`,
                              notAllowed(item) && `opacity-50 cursor-not-allowed`,
                            ) }>
                            <item.icon
                              className={ classNames(
                                item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                                `mr-3 flex-shrink-0 h-6 w-6`,
                              ) }
                              aria-hidden={ `true` }
                            />
                            {
                              item.name
                            }
                          </a>
                        </Case>
                        <Case condition={ !item.children }>
                          <WrappedLink
                            key={ item.name }
                            to={ item.href }
                            className={ classNames(
                              item.current ? `bg-lateGreen-800 text-white` : `text-lateGreen-100 hover:bg-lateGreen-600`,
                              `group flex items-center px-2 py-2 text-base font-medium rounded-md`,
                              notAllowed(item) && `opacity-50 cursor-not-allowed`,
                            ) }
                            disabled={ notAllowed(item) }
                          >
                            <>
                              <item.icon className={ `mr-4 h-6 w-6 flex-shrink-0 text-lateGreen-300` }
                                aria-hidden={ `true` } />
                              <Tooltip title={ item.name }>
                                <span className={ `flex-1 truncate` }>{ item.name }</span>
                              </Tooltip>
                              <When condition={ noSub(item) }>
                                <Badge color={ `gray` }
                                  message={ `No Plan` }
                                  size={ `xs` } />
                              </When>

                              <When condition={ upgrade(item) }>
                                <UpgradeBadge />
                              </When>

                              <When condition={ item.type === `groupOptionDefault` }>
                                <div className={ `ml-0.5` }>
                                  <Badge
                                    color={ `blue` }
                                    message={ `Default` }
                                    size={ `xs` }

                                  />
                                </div>
                              </When>
                            </>
                          </WrappedLink>
                        </Case>
                        <Default>
                          <Disclosure as={ `div` }
                            className={ `space-y-1 w-full` }
                            key={ item.name }>
                            { ({ open }) => (
                              <>
                                <DisclosureButton
                                  as={ `a` }
                                  className={ classNames(
                                    item.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                                    `group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer`,
                                  ) }
                                >
                                  <item.icon
                                    className={ classNames(
                                      item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                                      `mr-3 flex-shrink-0 h-6 w-6`,
                                    ) }
                                    aria-hidden={ `true` }
                                  />
                                  <span className={ `flex-1` }>{ item.name }</span>

                                  <svg
                                    className={ classNames(
                                      open ? `text-gray-400 rotate-90` : `text-gray-300`,
                                      `ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400`,
                                    ) }
                                    viewBox={ `0 0 20 20` }
                                    aria-hidden={ `true` }
                                  >
                                    <path d={ `M6 6L14 10L6 14V6Z` }
                                      fill={ `currentColor` } />
                                  </svg>
                                </DisclosureButton>
                                <DisclosurePanel className={ `space-y-1` }>
                                  { item.children.map(subItem => (
                                    <Switch key={ subItem.key || subItem.name }>
                                      <Case condition={ subItem.href === `ADD_NEW_GROUP` }>
                                        <DisclosureButton
                                          key={ subItem.name }
                                          as={ `div` }
                                        >
                                          <a
                                            href={ `#` }
                                            className={ classNames(
                                              subItem.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                                              `group flex items-center px-2 py-2 text-sm font-medium rounded-md pl-11`,
                                              notAllowed(item) && `opacity-50 cursor-not-allowed`,
                                            ) }
                                            onClick={ e => {
                                              e.preventDefault();
                                              onNewGroup();
                                            } }
                                          >
                                            <>
                                              <subItem.icon
                                                className={ classNames(
                                                  item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                                                  `mr-3 flex-shrink-0 h-6 w-6`,
                                                ) }
                                                aria-hidden={ `true` }
                                              />
                                              { subItem.name }
                                            </>
                                          </a>
                                        </DisclosureButton>
                                      </Case>
                                      <Default>

                                        <DisclosureButton
                                          key={ subItem.name }
                                          as={ `div` }
                                        >
                                          <WrappedLink
                                            to={ subItem.href }
                                            className={ classNames(
                                              subItem.current ? `bg-gray-100 text-gray-900` : `text-gray-600 hover:bg-gray-50 hover:text-gray-900`,
                                              `group flex items-center px-2 py-2 text-sm font-medium rounded-md pl-11`,
                                              notAllowed(item) && `opacity-50 cursor-not-allowed`,
                                            ) }
                                            disabled={ notAllowed(item) }
                                          >
                                            <>
                                              <subItem.icon
                                                className={ classNames(
                                                  item.current ? `text-gray-500` : `text-gray-400 group-hover:text-gray-500`,
                                                  `mr-3 flex-shrink-0 h-6 w-6`,
                                                ) }
                                                aria-hidden={ `true` }
                                              />
                                              { subItem.name }
                                              <When condition={ noSub(subItem) }>
                                                <Badge color={ `gray` }
                                                  message={ `No Plan` }
                                                  size={ `xs` } />
                                              </When>

                                              <When condition={ upgrade(subItem) }>
                                                <UpgradeBadge />
                                              </When>
                                            </>
                                          </WrappedLink>
                                        </DisclosureButton>
                                      </Default>
                                    </Switch>
                                  )) }
                                </DisclosurePanel>
                              </>
                            ) }
                          </Disclosure>
                        </Default>
                      </Switch>
                    );
                  }) }
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div className={ `w-14 flex-shrink-0` }
            aria-hidden={ `true` }>
            { /* Dummy element to force sidebar to shrink to fit close icon */ }
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
