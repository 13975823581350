import { DataGridPro, GridColDef, GridFilterModel, GridPaginationModel, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router';

import { useContactParams, useContacts } from '../../../../hooks/useContacts';
import { Button } from '../../../../../common/Atoms/Button';
import { WrappedLink } from '../../../WrappedLink';
import { getTableHeight } from '../../../../lib/helper';
import { Card } from '../../../../../common/Atoms/Card';

export function ContactTable() {
  const { data: contacts, isLoading, isFetching } = useContacts();
  const { params, updateParams, resetParams } = useContactParams();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  // On first mount, reset the filters
  useEffect(() => {
    if (urlParams.has(`persist`)) {
      // Do nothing
    }
    else {
      console.log(`Resetting params`);
      resetParams();
    }
  }, []);

  const rows = useMemo(() => {
    if (!contacts) return [];

    return contacts.contacts.map(contact => ({
      ...contact,
      unpaidInvoiceCount: contact.unpaidInvoiceCount?.toLocaleString(),
      primaryContact:
        (contact.primaryContactPerson.firstName || ``) +
        ` ` +
        (contact.primaryContactPerson.lastName || ``),
    }));

  }, [contacts]);

  const columns: GridColDef<(typeof rows)[number]>[] = useMemo(() => {
    const r: GridColDef<(typeof rows)[number]>[] = [
      {
        field: `name`,
        headerName: `Name`,
        minWidth: 300,
        maxWidth: 400,
        flex: 0.3,
        type: `custom`,
        renderCell: ({ row }) => (
          <WrappedLink to={ `/contacts/${row.id}` }>
            <span className={ `text-blue-600 underline` }>
              { row.name }
            </span>
          </WrappedLink>
        ),
      },
      {
        field: `primaryContact`,
        headerName: `Primary Contact`,
        minWidth: 150,
        flex: 0.2,
      },
      {
        field: `unpaidInvoiceCount`,
        headerName: `No. of Unpaid Invoices`,
        flex: 0.1,
        sortable: false,
      },
      {
        field: `outstandingBalanceDisplay`,
        headerName: `Outstanding`,
        flex: 0.2,
        sortable: false,
      },
      {
        field: `overdueBalanceDisplay`,
        headerName: `Overdue`,
        flex: 0.2,
        cellClassName: `text-red-600`,
        sortable: false,
      },
    ];

    // Apply some duplicates
    return r.map(c => ({
      ...c,
      editable: false,
      headerClassName: `text-md text-gray-800 font-bold`,
    }));
  }, [rows]);

  function onFilterChange(model: GridFilterModel) {
    const str = model.quickFilterValues?.[0] || ``;

    updateParams({
      ...params,
      searchStr: str,
      page: 1,
    });
  }

  function onPaginationModelChange(model: GridPaginationModel) {
    updateParams({
      ...params,
      page: model.page + 1,
      limit: model.pageSize,
    });
  }

  const paginationModel = useMemo(() => {
    return {
      page: params.page - 1,
      pageSize: params.limit,
    };
  }, [params.page, params.limit]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(
    contacts?.totalCount || 1,
  );

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      contacts?.totalCount !== undefined
        ? contacts?.totalCount
        : prevRowCountState,
    );
  }, [contacts?.totalCount, setRowCountState]);

  const heightClass = getTableHeight(rows?.length);

  return (
    <Card>
      <div className={ heightClass } >
        <DataGridPro
          sx={ {
            border: `none`,
          } }
          rows={ rows }
          columns={ columns }
          initialState={ {
            pagination: {
              paginationModel,
            },
          } }
          pageSizeOptions={ [15, 25, 50, 100, 300] }
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          pagination
          paginationMode={ `server` }
          onPaginationModelChange={ onPaginationModelChange }
          paginationModel={ paginationModel }
          rowCount={ rowCountState }
          onRowClick={ row => console.log(`Row clicked`, row) }
          loading={ isLoading || isFetching }
          filterMode={ `server` }
          onFilterModelChange={ onFilterChange }
          slots={ {
            toolbar: CustomToolbar,
          } }
          slotProps={ {
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
          } }
          localeText={ {
            noRowsLabel: `No contacts in group. Click "Add Contacts" to move contacts to this group`,
          } }
        />
      </div>
    </Card>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <div className={ `flex justify-between w-full` }>
        <GridToolbarQuickFilter
          quickFilterParser={ (value: string) => [value] }
          debounceMs={ 350 }
        />
        <WrappedLink
          to={ `/contacts/manage` }
        >
          <Tooltip title={ `Move contacts to this group on the contact page` }>
            <Button>
              { `Add Contacts` }
            </Button>
          </Tooltip>
        </WrappedLink>
        { /* <GridToolbarExport
          slotProps={ {
            tooltip: { title: `Export data` },
            button: { variant: `outlined` },
          } }
        /> */ }
      </div>
    </GridToolbarContainer>
  );
}
