import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StatementRelativePeriods } from "shared";
import { useParams } from "react-router";

import { setCurrentCurrency, setStatementPeriod } from "../slices/contactPagePrefs";
import { RootState } from "../store";
import { useFetchContactQuery } from "../services/api/contactsApi/contact";

export function useContact() {
  const { contactId, organisationId } = useParams<{ organisationId: string, contactId?: string }>();
  const dispatch = useDispatch();
  const { currentCurrency, statementPeriod } = useSelector((s: RootState) => s.contactPagePrefs);

  const contactData = useFetchContactQuery({
    contactId: Number(contactId),
    organisationId,
    statementPeriod,
  }, {
    skip: !contactId || !organisationId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!contactData.data) return;

    if (!currentCurrency && contactData.data?.possibleCurrencies.length > 0) {
      dispatch(setCurrentCurrency(contactData.data?.possibleCurrencies[0]));
    }

    if (!contactData.data?.possibleCurrencies.includes(currentCurrency)) {
      dispatch(setCurrentCurrency(contactData.data?.possibleCurrencies[0]));
    }
  }, [currentCurrency, contactData.data?.possibleCurrencies]);

  return {
    ...contactData,
    currentCurrency,
    setCurrentCurrency: (currency: string) => dispatch(setCurrentCurrency(currency)),
    statementPeriod,
    setStatementPeriod: (period: StatementRelativePeriods) => dispatch(setStatementPeriod(period)),
  };
}
