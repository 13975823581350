import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { IInvalidField, IPolicy, PolicyState, validatePolicySync, yupErrorMessageParse } from "shared";

import { RootState } from "../../../store";
import { Drawer } from "../../../../common/Atoms/Drawer";
import { closeAndClear } from "../../../slices/policyDrawer";
import { UnsavedChangesModal } from "../../Modals/UnsavedChanges";
import { useDeletePolicyMutation, useUpdatePolicyMutation } from "../../../services/api/policyApi/policy";

import { PolicyItem } from "./PolicyItem";

export function PolicyDrawer() {
  // Hooks
  const drawerProps = useSelector((s: RootState) => s.policyDrawer);
  const dispatch = useDispatch();
  const [updatePolicy, {
    isLoading: saveLoading,
    isSuccess: saveSuccess,
    reset: resetSave,
  }] = useUpdatePolicyMutation();
  const [deletePolicy, {
    isLoading: deleteLoading,
    isSuccess: deleteSuccess,
    reset: resetDelete,
  }] = useDeletePolicyMutation();

  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  /** Close the drawer, upon successful save */
  const [shouldCloseOnSuccess, setShouldCloseOnSuccess] = useState(false);
  const [ invalids, setInvalids ] = useState<IInvalidField[]>([]);

  const { open, policyFormData, formDataDirty } = drawerProps;

  // Validate on load
  useEffect(() => {
    if (policyFormData) {
      const pol = { ...policyFormData };

      validate(pol.policy);
    }
  }, [ policyFormData ]);

  // When the update was successful, close the drawer
  useEffect(() => {
    if ((saveSuccess || deleteSuccess) && shouldCloseOnSuccess) {
      resetStates();

      dispatch(closeAndClear());
    }
  }, [ saveSuccess, deleteSuccess, shouldCloseOnSuccess ]);

  function resetStates() {
    setShowUnsavedModal(false);
    setShouldCloseOnSuccess(false);
    setInvalids([]);
    resetSave();
    resetDelete();
  }

  // Event Handlers
  function onOpenClose(show: boolean) {
    if (show) {
      console.warn(`No op`);

      return;
    }

    if (formDataDirty) {
      setShowUnsavedModal(true);

      return;
    }

    resetStates();
    dispatch(closeAndClear());
  }

  function onCancelExit() {
    resetStates();
    setShowUnsavedModal(false);
  }

  function onConfirmExit() {
    resetStates();
    dispatch(closeAndClear());
  }

  function onSaveAndClose() {
    const valid = validate({ ...policyFormData.policy });

    if (valid) {
      save();
    }

    // Not valid, go back to form
    setShowUnsavedModal(false);
  }

  function validate(pol: IPolicy) {
    try {
      const valid = validatePolicySync(pol, false);

      setInvalids([]);

      return valid;
    }
    catch (err) {
      console.error(err);

      const errorMessages = yupErrorMessageParse(err);

      if (Array.isArray(errorMessages)) {
        setInvalids(errorMessages);
      }
      else {
        setInvalids([{
          message: `An Unknown Error has occured.`,
        }]);
      }

      return false;
    }
  }

  function save() {
    const pol = { ...policyFormData };

    const valid = validate(pol.policy);

    if (valid) {
      updatePolicy({
        id: policyFormData.policy.id,
        organisationId: pol.policy.organisation_id,
        ...pol,
      });

      setShouldCloseOnSuccess(true);
    }
  }

  function setLiveAndSave() {
    const pol = { ...policyFormData };

    const valid = validate(pol.policy);

    if (valid) {
      updatePolicy({
        id: policyFormData.policy.id,
        organisationId: pol.policy.organisation_id,
        ...pol,
        policy: {
          ...pol.policy,
          state: PolicyState.ACTIVE,
        },
      });
    }

    setShouldCloseOnSuccess(true);
  }

  function onDelete() {
    deletePolicy({
      id: policyFormData.policy.id,
      organisationId: policyFormData.policy.organisation_id,
    });

    setShouldCloseOnSuccess(true);
  }

  const modalTitle = (
    <span className={ `flex` }>
      <ExclamationCircleIcon
        className={ `h-6 w-6 text-red-500 mr-1` }
      />
      { `You have unsaved changes!` }
    </span>
  );

  return (
    <Drawer
      size={ `lg` }
      open={ open }
      setOpen={ onOpenClose }
      className={ `bg-gray-50` }
      closeButton={ false }
    >
      <PolicyItem
        onSave={ save }
        onSaveSetLive={ setLiveAndSave }
        onDelete={ onDelete }
        invalids={ invalids }
        loading={ saveLoading || deleteLoading }
      />
      <UnsavedChangesModal
        show={ showUnsavedModal }
        onCancel={ onCancelExit }
        onConfirm={ onConfirmExit }
        onSaveAndExit={ onSaveAndClose }
        title={ modalTitle }
        message={ `Are you sure you want to exit and discard your changes?` }
      />
    </Drawer>
  );
}
