import { fieldIsValid } from "../../../../../lib/helper";
import { Select } from "../../../../../../common/Atoms/Select";

import { IFieldProps } from "./fieldProps";

export function PenaltyDueDate({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const options = [
    {
      label: `Penalty Issue Date`,
      value: 0,
    },
    {
      label: `1 Week after Penalty Issued`,
      value: 7,
    },
  ];

  return (
    <Select
      label={ `Penalty Invoice Due Date` }
      helpIcon={
        `The due date of the issued penalty invoice.` }
      onChange={ handleChange }
      name={ `penalty_due_date` }
      invalid={ fieldIsValid(`penalty_due_date`, invalids) }
      selected={ policyFormData.penalty_due_date }
      options={ options }
      emptyText={ `Original Invoice Due Date` }
      nullable
    />
  );
}
