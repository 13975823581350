// Shows if the action create is in progress for this org or not
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { useFetchActionCreateStatusQuery } from "../services/api/actionCreateStatusApi/actionCreateStatus";

import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";
import { useActions } from "./useActions";

export function useActionCreateStatus() {
  const currentOrg = useGetSelectedOrganisation();
  const { data: actionCreateStatus } = useFetchActionCreateStatusQuery(currentOrg?.id, {
    skip: !currentOrg,
    pollingInterval: 3000,
    skipPollingIfUnfocused: true,
  });
  const { refetch: actionsRefetch } = useActions();
  const mostRecentStatus = useRef(false);
  const timeOutRef = useRef(null);

  useEffect(() => {
    if (actionCreateStatus?.inProgress) {
      // Show a toast or something
      toast.loading(`Action Generation in Progress...`, { autoClose: false, toastId: `action_create` });
    }

    if (!actionCreateStatus?.inProgress) {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }

      if (mostRecentStatus.current) {
        toast.update(`action_create`, {
          render: `Action Generation Complete! Reloading actions...`,
        });

        // Ensure actions are refetched
        actionsRefetch();

        timeOutRef.current = setTimeout(() => {
          toast.dismiss(`action_create`);
        }, 3000);
      }
      else {
        toast.dismiss(`action_create`);
      }
    }

    mostRecentStatus.current = !!actionCreateStatus?.inProgress;

    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
  }, [actionCreateStatus?.inProgress]);

  return {
    actionCreateStatus: actionCreateStatus?.inProgress,
  };
}
