import { Switch, Case, When } from 'react-if';
import { ACCOUNTING_CONFIG, EmailTemplateTypes, InterestRateCountry, PolicyCalculationType, PolicyProcessType, allowedRegions, findByAlias } from 'shared';
import { useMemo } from 'react';

import { RadioCards } from '../../../../../common/Atoms/RadioCards';
import { RadioStack } from '../../../../../common/Atoms/RadioStack';
import { InputTextAddon } from '../../../../../common/Atoms/InputTextAddon';
import { Toggle } from '../../../../../common/Atoms/Toggle';
import { fieldIsValid } from '../../../../lib/helper';
import { useAccountingSystemTerm } from '../../../../hooks/useAccountingSystemTerm';
import { Grid } from '../../../../../common/Atoms/Grid';
import { GridItem } from '../../../../../common/Atoms/GridItem';
import { CurrencyInput } from '../../../../../common/Atoms/CurrencyInput';
import { useAccountingSystemSetting } from '../../../../hooks/useAccountingSystemSetting';
import { AccountSelector } from '../PolicyForm/AccountSelector';
import { IPolicyProps } from '../PolicyItem';
import { TaxSelector } from '../PolicyForm/TaxSelector';
import { GracePeriod } from '../PolicyForm/GracePeriod';
import { useGetSelectedOrganisation } from '../../../../hooks/useGetSelectedOrganisation';
import { Transition } from '../../../../../common/Atoms/Transition';
import { BANK_OF_ENGLAND_ARTICLE } from '../../../../constants/links';
import { PenaltyDueDate } from '../PolicyForm/Fields/PenaltyDueDate';
import { EmailTemplateSelector } from '../PolicyForm/Fields/EmailTemplateSelector';

const statusValues = [
  {
    title: `Approved`,
    value: `AUTHORISED`,
  },
  {
    title: `Draft`,
    value: `DRAFT`,
  },
];

const typeOptions = [
  {
    title: `New Invoice`,
    description: `Creates a new invoice for each late fee issued.`,
    value: PolicyProcessType.NEW_INVOICE,
  },
  {
    title: `Update Invoice`,
    description: `Add as a new line item to the existing invoice.`,
    value: PolicyProcessType.UPDATE_INVOICE,
  },
];

const calcucateOptions = [
  {
    title: `Interest Rate Per Annum`,
    description: `Applies a % of overdue, prorated per day`,
    value: PolicyCalculationType.PRORATA,
  },
  {
    title: `Percentage`,
    description: `Applies a fee equal to the percentage of the due amount owing at the time of late fee issue`,
    value: PolicyCalculationType.PERCENT,
  },
  {
    title: `Fixed`,
    description: `Applies a fixed fee in the invoice's base currency`,
    value: PolicyCalculationType.FIXED,
  },
];

function getInterestRateCopy(region: string) {
  if (findByAlias(region) === InterestRateCountry.UK) {
    return {
      label: `Apply the Bank of England base rate`,
      description: `Will apply the BoE base rate + your percent. Using the correct base rate(s) that the invoice period spans accross.`,
      helpLink: BANK_OF_ENGLAND_ARTICLE,
    };
  }

  return null;
}

export function PolicyLateFee({
  policy,
  invalids,
  handleChange,
}: IPolicyProps) {
  const currentOrg = useGetSelectedOrganisation();
  const hideInvoiceStatus = useAccountingSystemSetting(ACCOUNTING_CONFIG.HIDE_INVOICE_STATUS);

  const percentLabel = policy.calculation_type === PolicyCalculationType.PERCENT ? `Percent` : `Percent Per Annum`;

  const allowedCentralRate = useMemo(() => {
    if (currentOrg) {
      return allowedRegions.includes(currentOrg.region);
    }

    return false;
  }, [currentOrg]);

  const percentPostfix = useMemo(() => {
    if (!policy.use_central_interest_rate || !allowedCentralRate) {
      return policy.calculation_type === PolicyCalculationType.PERCENT ? `%` : `% annually`;
    }

    return policy.calculation_type === PolicyCalculationType.PERCENT ? `%` : `% + BoE rate annually`;

  }, [policy.calculation_type, policy.use_central_interest_rate, allowedCentralRate]);

  return (
    <>
      <RadioCards
        label={ `How should we issue the late fee` }
        options={ typeOptions }
        onChange={ handleChange }
        selected={ policy.process_type }
        cols={ 2 }
        name={ `process_type` }
      />

      <When condition={ policy.process_type === PolicyProcessType.UPDATE_INVOICE }>
        <Toggle
          label={ `Issue a new invoice if unable to update existing?` }
          description={ `If the invoice is locked or has any payments/credits applied, we will issue as a new invoice instead.` }
          helpIcon={ `It is not possible to update a invoice that has any payments, credit notes or is in a locked period. You can choose to send as new invoice, which will only contain this late fee.` }
          checked={ policy.on_update_fail_send_new }
          onChange={ handleChange }
          name={ `on_update_fail_send_new` }
          invalid={ fieldIsValid(`on_update_fail_send_new`, invalids) }
        />
      </When>

      <When condition={
        !hideInvoiceStatus &&
        (policy.process_type === PolicyProcessType.NEW_INVOICE ||
        policy.on_update_fail_send_new)
      }>
        <RadioCards
          label={ `Send new penalty invoices as` }
          options={ statusValues }
          onChange={ handleChange }
          selected={ policy.invoice_status }
          cols={ 2 }
          name={ `invoice_status` }
        />
      </When>

      <RadioStack
        options={ calcucateOptions }
        onChange={ handleChange }
        selected={ policy.calculation_type }
        name={ `calculation_type` }
      />

      <Transition
        show={ policy.calculation_type === PolicyCalculationType.PRORATA && allowedCentralRate }
        speed={ `slow` }
      >
        <div>
          <Grid
            cols={ 3 }
            gapX={ 4 }
          >
            <GridItem span={ 3 }>
              <Toggle
                { ...getInterestRateCopy(currentOrg?.region) }
                checked={ policy.use_central_interest_rate }
                onChange={ handleChange }
                name={ `use_central_interest_rate` }
                invalid={ fieldIsValid(`use_central_interest_rate`, invalids) }
              />
            </GridItem>
          </Grid>
        </div>

      </Transition>

      <Grid
        cols={ 3 }
        gapX={ 4 }
      >
        <GridItem
          position={ `bottom` }
        >
          <AccountSelector
            label={ useAccountingSystemTerm(`Fee Income Account`) }
            value={ policy.xero_account_code }
            valueKey={ `code` }
            onChange={ handleChange }
            name={ `xero_account_code` }
            invalid={ fieldIsValid(`xero_account_code`, invalids) }
          />
        </GridItem>
        <GridItem
          position={ `bottom` }
        >
          <TaxSelector
            label={ useAccountingSystemTerm(`Tax Rate`) }
            code={ policy.tax_rate }
            onChange={ handleChange }
            name={ `tax_rate` }
            invalid={ fieldIsValid(`tax_rate`, invalids) }
          />
        </GridItem>
        <GridItem
          position={ `bottom` }
        >
          <Switch>
            <Case condition={ policy.calculation_type === `fixed` }>
              <CurrencyInput
                helpIcon={ `This amount will be in the same currency as the invoice.` }
                label={ `Fee Amount` }
                invalid={ fieldIsValid(`fixed_fee_amount`, invalids) }
                value={ policy.fixed_fee_amount }
                onChange={ handleChange }
                name={ `fixed_fee_amount` }
              />
            </Case>
            <Case condition={ policy.calculation_type === `percent` || policy.calculation_type === `prorata` }>
              <InputTextAddon
                invalid={ fieldIsValid(`percent`, invalids) }
                label={ percentLabel }
                value={ policy.percent }
                onChange={ handleChange }
                addOnText={ percentPostfix }
                name={ `percent` }
              />
            </Case>
          </Switch>
        </GridItem>
      </Grid>

      <Grid
        cols={ 3 }
        gapX={ 4 }
      >
        <GridItem
          position={ `bottom` }
        >
          <GracePeriod
            handleChange={ handleChange }
            policyFormData={ policy }
            invalids={ invalids }
          />
        </GridItem>
        <GridItem>
          <Toggle
            label={ `Repeat Late Fee` }
            description={ `When still unpaid, repeat late fee?` }
            checked={ policy.repeating }
            onChange={ handleChange }
            name={ `repeating` }
            invalid={ fieldIsValid(`repeating`, invalids) }
          />
        </GridItem>
        <GridItem>
          <InputTextAddon
            label={ `Repeat Frequency` }
            helpIcon={ `How often the late fee is re-issued. Interest is always calculated daily when using "Per Annum"` }
            value={ policy.repeating ? policy.repeat_frequency : `Not Applicable` }
            name={ `repeat_frequency` }
            onChange={ handleChange }
            disabled={ !policy.repeating }
            addOnText={ `days` }
            invalid={ fieldIsValid(`repeat_frequency`, invalids) }
          />
        </GridItem>
      </Grid>
      <div className={ `grid grid-cols-6 gap-4` }>
        <div className={ `col-span-4` }>
          <InputTextAddon
            label={ `Description` }
            value={ policy.line_item_text }
            onChange={ handleChange }
            name={ `line_item_text` }
            invalid={ fieldIsValid(`line_item_text`, invalids) }
            description={ `Appears on the invoice line item. Use #PenaltyDate to insert the penalty issued date` }
          />
        </div>

        <When condition={ policy.process_type === PolicyProcessType.NEW_INVOICE || policy.on_update_fail_send_new }>
          <div className={ `col-span-2` }>
            <PenaltyDueDate
              policyFormData={ policy }
              handleChange={ handleChange }
              invalids={ invalids }
            />
          </div>
        </When>
      </div>

      <EmailTemplateSelector
        value={ policy.template_id }
        type={ EmailTemplateTypes.LATE_FEE }
        handleChange={ handleChange }
        invalid={ fieldIsValid(`template_id`, invalids) }
        name={ `template_id` }
        label={ `Send email notification to customer when late fee is applied` }
        nullable
        emptyText={ `Do not send email notification` }
        helpIcon={ `Create or edit the template under Email Templates.` }
      />
    </>
  );
}
