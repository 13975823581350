import { IPlanV3, StripePricesLookupKeys, SubscriptionStatus, allowedSubscriptionStatus, findPlanByLookupKey } from "shared";
import { useEffect, useMemo, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router";
import { isAfter } from "date-fns";

import { Card } from "../../../common/Atoms/Card";
import { usePlans } from "../../hooks/usePlans";
import { Select } from "../../../common/Atoms/Select";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Button } from "../../../common/Atoms/Button";
import { createCheckout } from "../../lib/api";
import { events, logAnalyticEvent } from "../../lib/analytics";
import { useUser } from "../../hooks/useUser";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { useChangePlanMutation } from "../../services/api/subscriptionApi/subscription";

import { PlanItem } from "./PlanItem";
import { PlanChangeModal } from "./PlanChangeModal";

interface INewPlanProps {
  plan: IPlanV3
}

const initPlanChangeModal = {
  currentPlan: null,
  newPlan: null,
  show: false,
};

export function NewPlan({ plan }: INewPlanProps) {
  const { data: user } = useUser();
  const id = Number(useParams().subscriptionId);
  const { data: subscriptions } = useSubscriptions();
  const { data: plans } = usePlans();
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const [loading, setLoading] = useState(false);
  const [chosenPrice, setChosenPrice] = useState<StripePricesLookupKeys>(null);
  const [ changePlan, { isLoading: changePlanLoading } ] = useChangePlanMutation();
  const [ planChangeModal, setPlanChangeModal ] = useState(initPlanChangeModal);

  const subscription = useMemo(() => {
    return subscriptions.find(s => s.id === id);
  }, [ subscriptions]);

  useEffect(() => {
    if (!chosenPrice && plans?.length && subscription) {
      if (allowedSubscriptionStatus.includes(subscription.status)) {
        const currentLookup = subscription.mainLookupKey as StripePricesLookupKeys;
        const planConfig = findPlanByLookupKey(currentLookup);

        if (plans.find(p => p.lookup_key === currentLookup) && planConfig.name === plan.name) {
          setChosenPrice(subscription.mainLookupKey as StripePricesLookupKeys);

          return;
        }
      }
      const defaultPrice = Object.keys(plan.prices).find(k => plan.prices[k].default);

      setChosenPrice(defaultPrice as StripePricesLookupKeys);
    }

  }, [chosenPrice, plans, subscription]);

  const priceOptions = useMemo(() => {
    if (!plans) return [];

    const keysInPlan = Object.keys(plan.prices) as StripePricesLookupKeys[];

    return plans
      .filter(p => keysInPlan.includes(p.lookup_key as StripePricesLookupKeys))
      .sort((a, b) => (
        plan.prices[a.lookup_key as StripePricesLookupKeys].limits.invoices - plan.prices[b.lookup_key as StripePricesLookupKeys].limits.invoices
      ))
      .map(p => {
        return {
          label: `${plan.prices[p.lookup_key as StripePricesLookupKeys].limits.invoices} invoices & ${plan.prices[p.lookup_key as StripePricesLookupKeys].limits.emails} emails / month`,
          value: p.lookup_key,
        };
      });
  }, [plan, plans]);

  const chosenPriceDetails = useMemo(() => {
    if (!chosenPrice || !plans) return null;

    const currentPrice = plan.prices[chosenPrice];
    const baseStripePrice = plans.find(p => p.lookup_key === chosenPrice);

    const basePrice = baseStripePrice?.unit_amount;

    if (!basePrice) return null;

    const basePriceFormatted = (basePrice / 100).toFixed(2);

    return {
      planDetails: currentPrice,
      planCost: plans.find(p => p.lookup_key === chosenPrice),
      basePriceFormatted,
    };
  }, [chosenPrice, plan, plans]);

  const items = useMemo(() => {
    if (!chosenPriceDetails?.planDetails) return [];
    const base = [
      {
        icon: CheckCircleIcon,
        text: `${ chosenPriceDetails.planDetails.limits.invoices } invoices per month`,
      },
      {
        icon: CheckCircleIcon,
        text: `Issue Late Fees & Interest on Invoices`,
      },
      {
        icon: CheckCircleIcon,
        text: `Auto Send Statements`,
      },
      {
        icon: CheckCircleIcon,
        text: `Prompt Payment Discounts`,
      },
      {
        icon: CheckCircleIcon,
        text: `Email Reminders`,
      },
      {
        icon: CheckCircleIcon,
        text: `SMS Reminders ($0.10 each)`,
      },
    ];

    if (plan.features.statementInterest) {
      base.push({
        icon: CheckCircleIcon,
        text: `Charge Interest on statements`,
      });
    }

    if (plan.features.customDomain) {
      base.push({
        icon: CheckCircleIcon,
        text: `BYO Email Domain`,
      });
    }

    if (plan.features.multicurrency) {
      base.push({
        icon: CheckCircleIcon,
        text: `Multicurrency`,
      });
    }

    if (plan.features.webhooks) {
      base.push({
        icon: CheckCircleIcon,
        text: `Webhooks`,
      });
    }

    if (plan.name === `Pro`) {
      base.push({
        icon: CheckCircleIcon,
        text: `Share allowance with other organisations ($10ea / Month)`,
      });
    }

    return base;
  }, [ plan, chosenPriceDetails ]);

  async function onCheckout(changePlan?: boolean) {
    setLoading(true);

    const res = await createCheckout(chosenPrice, subscriptionId, changePlan);

    setLoading(false);

    logAnalyticEvent(events.beginCheckout, {
      userId: user.id,
      stripeProduct: chosenPrice,
    });

    window.location = res.body.url;
  }

  const hasCurrentPlan = allowedSubscriptionStatus.includes(subscription?.status);
  const isCurrentPlan = subscription?.mainLookupKey === chosenPrice;

  function onChangePlan() {
    setPlanChangeModal({
      show: true,
      currentPlan: subscription?.mainLookupKey,
      newPlan: chosenPrice,
    });
  }

  async function onChangePlanConfirm() {
    await changePlan({
      lookupKey: chosenPrice,
      subscriptionId,
    });

    setPlanChangeModal(initPlanChangeModal);
  }

  function renderButton() {
    if (hasCurrentPlan && isCurrentPlan) {
      return (
        <Button
          onClick={ () => {} }
          size={ `2xl` }
          disabled
        >
          { `Current Plan` }
        </Button>
      );
    }

    if (hasCurrentPlan) {
      return (
        <Button
          onClick={ () => onChangePlan() }
          loading={ changePlanLoading }
          disabled={ changePlanLoading }
          size={ `2xl` }
        >
          { `Change Plan` }
        </Button>
      );
    }

    if (user.trialEndsOn && isAfter(new Date(user.trialEndsOn * 100), new Date())) {
      return (
        <Button
          onClick={ () => onCheckout() }
          loading={ loading }
          size={ `2xl` }
        >
          { `Choose Plan` }
        </Button>
      );
    }

    return (
      <Button
        onClick={ () => onCheckout() }
        loading={ loading }
        size={ `2xl` }
      >
        { `Start Trial` }
      </Button>
    );
  }

  return (
    <>
      <Grid2
        xs={ 2 }
        sm={ 1 }
        width={ `100%` }
      >
        <Card
          className={ `drop-shadow-md min-h-full flex flex-col justify-between border border-lateGreen-300 ` }
        >
          <div>
            <div
              id={ `plan-${plan.name}-header` }
              className={ `min-h-[6rem]` }
            >
              <h2 className={ `text-secondary text-2xl font-semibold leading-6 ` }>
                { plan.name }
              </h2>
              <Paragraph
                variant={ `secondary` }
                className={ `my-4` }
              >
                { plan.description }
              </Paragraph>
            </div>
            <div className={ `flex flex-col` }>
              <div className={ `mb-3` }>
                <p>
                  <span className={ `text-2xl font-semibold leading-6` }>
                    { `$${chosenPriceDetails?.basePriceFormatted}` }
                  </span>
                  <span className={ `` }>
                    { ` / month` }
                  </span>
                </p>

              </div>
              <Select
                options={ priceOptions }
                label={ `` }
                onChange={ e => setChosenPrice(e.value) }
                selected={ chosenPrice }
                className={ `mb-6` }
              />

              <div className={ `space-y-5 mt-6` }>
                {
                  items.map((item, i) => (
                    <PlanItem key={ i }
                      item={ item } />
                  ))
                }
              </div>
            </div>
          </div>
          <div className={ `flex justify-center pt-12` }>
            { renderButton() }
          </div>
        </Card>
      </Grid2>
      <PlanChangeModal
        onConfirm={ onChangePlanConfirm }
        onCancel={ () => setPlanChangeModal(initPlanChangeModal) }
        show={ planChangeModal.show }
        currentPlan={ planChangeModal.currentPlan }
        newPlan={ planChangeModal.newPlan }
        trial={ subscription?.status === SubscriptionStatus.TRIALING }
        loading={ changePlanLoading }
      />
    </>
  );
}
