import { Link } from 'react-router-dom';
import { Default, Switch, Case, When } from 'react-if';
import { SubscriptionVerficationStatus } from 'shared';

import { useGetSubscriptionMetadata } from '../hooks/useGetSubscription';
import { useGetSelectedOrganisation } from '../hooks/useGetSelectedOrganisation';
import { Strap } from '../../common/Atoms/Strap';
import { useOnboarding } from '../hooks/useOnboarding';

export function SubscriptionStrap() {
  const {
    subscriptionMetadata,
    isBillingOwner,
  } = useGetSubscriptionMetadata();
  const currentOrg = useGetSelectedOrganisation();
  const { isOnboarding } = useOnboarding();

  if (!subscriptionMetadata || ([`active`, `trial`, `trialing`].includes(subscriptionMetadata.status) && subscriptionMetadata.verificationStatus === SubscriptionVerficationStatus.VERIFIED)) {
    return null;
  }

  if (isOnboarding) {
    return null;
  }

  return (
    <Strap
      type={ `warning` }
    >
      <Switch>
        <Case condition={ !subscriptionMetadata.hasSubscription }>
          { `This organisation is not linked to a subscription!` }
          <Link
            to={ `/billing/subscriptions` }
            className={ `underline` }
          >
            { `Please link to an active subscription to avoid disconnection.` }
          </Link>
        </Case>

        <Case condition={ [`inactive`, `expired`, `canceled`].includes(subscriptionMetadata.status) }>
          <When condition={ isBillingOwner }>
            { `Your trial has not yet started or your plan has expired, please choose a ` }
            <Link
              to={ `/billing` + (subscriptionMetadata.id ? `/subscriptions/${subscriptionMetadata.id}` : ``) }
              className={ `underline` }
            >
              { ` plan` }
            </Link>
            { ` to start trial. Organisation is scheduled for disconnection.` }
          </When>
          <When condition={ !isBillingOwner }>
            { `Your plan or trial has expired, please contact the billing owner; ` }{ `(${currentOrg.billingManagedBy})` } { `to choose a plan. Organisation is scheduled for disconnection.` }
          </When>
        </Case>

        <Case condition={ subscriptionMetadata.status === null }>
          { `Your subscription is not active, click ` }
          <Link
            to={ `/billing` + (subscriptionMetadata.id ? `/subscriptions/${subscriptionMetadata.id}` : ``) }
            className={ `underline` }
          >
            { `here` }
          </Link>
          { ` to start a trial or choose a plan. Trial starts after choosing plan` }
        </Case>

        <Case condition={ subscriptionMetadata.verificationStatus === SubscriptionVerficationStatus.UNVERIFIED }>
          { `We are currently verifying your account. This should be complete within 1 business day. Email sending is limited.` }
        </Case>

        <Default>
          { `There is a problem with your subscription. Please contact support.` }
        </Default>
      </Switch>
    </Strap>
  );
}
