import { EmailTemplateTypes, PolicyEscalationType, PolicyTargetStatus } from 'shared';
import { useMemo, useState } from 'react';

import { fieldIsValid } from '../../../../lib/helper';
import { IPolicyProps } from '../PolicyItem';
import { InputTextAddon } from '../../../../../common/Atoms/InputTextAddon';
import { RadioStack } from '../../../../../common/Atoms/RadioStack';
import { Grid } from '../../../../../common/Atoms/Grid';
import { GridItem } from '../../../../../common/Atoms/GridItem';
import { TextEditor } from '../../../Molecules/TextEditor';
import { useMergeTags } from '../../../../hooks/useMergeTagsAndLinks';
import { Heading } from '../../../../../common/Atoms/Typography/Heading';
import { FormDescription } from '../../../../../common/Atoms/FormDescription';
import { FormLabel } from '../../../../../common/Atoms/FormLabel';
import { Select } from '../../../../../common/Atoms/Select';

const escalationOptions = [
  {
    title: `Escalate to call`,
    description: `This will place an escalation in the queue for your team to call this customer.`,
    value: PolicyEscalationType.CALL,
  },
  {
    title: `Escalate to stop credit`,
    description: `This will remind your team to cease any further credit sales to the customer.`,
    value: PolicyEscalationType.STOP_CREDIT,
  },
  {
    title: `Escalate template legal letter`,
    description: `This will place an escalation in the queue for your team to send a template legal letter.`,
    value: PolicyEscalationType.LETTER,
  },
];

function calculateDayTime(value) {
  if (value === 0) {
    return `on`;
  }

  if (value < 0) {
    return `before`;
  }

  return `after`;
}

export function PolicyEscalation({
  policy,
  invalids,
  handleChange,
}: IPolicyProps) {
  const [dayType, setDayType] = useState(calculateDayTime(policy.day_index));

  function handleStepsContentChange({ text }) {
    handleChange({
      name: `escalation_steps`,
      value: text,
    });
  }

  const mergeTags = useMergeTags(EmailTemplateTypes.ESCALATION); // TODO: fix type

  const formattedMergeTags = useMemo(() => {
    return mergeTags.asArray
      .map(tag => {
        return {
          text: tag.name,
          value: tag.value,
        };
      });
  }, [ mergeTags ]);

  const dayTypes = useMemo(() => {
    const result =[
      {
        label: `After`,
        value: `after`,
      },
      {
        label: `On The`,
        value: `on`,
      },
    ];

    if (policy.target_status === PolicyTargetStatus.DUE) {
      result.push({
        label: `Before`,
        value: `before`,
      });
    }

    return result;
  }, [ policy.target_status ]);

  const invoiceStates = [
    {
      label: `Due Date`,
      value: PolicyTargetStatus.DUE,
    },
    {
      label: `Issue Date`,
      value: PolicyTargetStatus.ISSUED,
    },
  ];

  const dayIndexValue = useMemo(() => {
    let str = policy.day_index.toString();

    if (str.startsWith(`-`)) {
      str = str.replace(`-`, ``);
    }

    return str;
  }, [ policy.day_index ]);

  function onDayIndexChange(e, day = dayType) {
    const { value } = e;

    let num = Number(value);

    if (Number.isNaN(num)) {
      handleChange({
        name: `day_index`,
        value,
      });

      return;
    }

    if (day === `before`) {
      num = num < 0 ? num : -num;
    }

    if (day === `after`) {
      num = num > 0 ? num : -num;
    }

    if (day === `on`) {
      num = 0;
    }

    handleChange({
      name: `day_index`,
      value: num,
    });
  }

  function handleDayTypeChange(e) {
    setDayType(e.value);

    onDayIndexChange({
      value: policy.day_index,
    }, e.value);
  }

  return (
    <>
      <RadioStack
        options={ escalationOptions }
        onChange={ handleChange }
        selected={ policy.escalation_type }
        name={ `escalation_type` }
      />

      <div>
        <FormLabel>{ `Raise this escalation` }</FormLabel>
        <Grid cols={ 3 }
          className={ `items-end` }>
          <GridItem>
            <InputTextAddon
              value={ dayIndexValue }
              onChange={ onDayIndexChange }
              name={ `day_index` }
              invalid={ fieldIsValid(`day_index`, invalids) }
              addOnText={ `days ` }
              disabled={ dayType === `on` }
            />
          </GridItem>
          <GridItem>
            <Select
              options={ dayTypes }
              selected={ dayType }
              onChange={ handleDayTypeChange }
            />
          </GridItem>
          <GridItem>
            <Select
              options={ invoiceStates }
              selected={ policy.target_status }
              onChange={ handleChange }
              name={ `target_status` }
              invalid={ fieldIsValid(`target_status`, invalids) }
            />
          </GridItem>
        </Grid>
      </div>

      { /* TODO: should we allow merge tags in this field? */ }
      <Heading>{ `Instructions` }</Heading>
      <FormDescription>
        { `Enter the instructions here. For example the discussion topics for a phone call, or which template legal letter to send. You can use merge tags to customize the steps for the specific invoice.` }
      </FormDescription>
      <TextEditor
        value={ policy.escalation_steps }
        onChange={ handleStepsContentChange }
        mergeTags={ formattedMergeTags }
        mode={ `sms` }
      />

      <InputTextAddon
        value={ policy.escalation_emails || `` }
        onChange={ handleChange }
        label={ `Notify email addresses` }
        subHeading={ `Enter email addresses to notify when this escalation is scheduled` }
        name={ `escalation_emails` }
        invalid={ fieldIsValid(`escalation_emails`, invalids) }
        helpIcon= { `Enter up to 5 email addresses, separated by a comma` }
      />
    </>
  );
}
