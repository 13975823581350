import { useMemo, useState } from "react";
import { PolicyTargetStatus } from "shared";

import { IPolicyProps } from "../../PolicyItem";
import { Grid } from "../../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../../common/Atoms/GridItem";
import { InputTextAddon } from "../../../../../../common/Atoms/InputTextAddon";
import { fieldIsValid } from "../../../../../lib/helper";
import { Select } from "../../../../../../common/Atoms/Select";

interface IStandardPolicyTriggerProps extends IPolicyProps {
  excludedTriggerTypes?: PolicyTargetStatus[]
  excludedTriggerRelatives?: (`on` | `before` | `after`)[]
  label?: string
}

function calculateDayTime(value) {
  if (value === 0) {
    return `on`;
  }

  if (value < 0) {
    return `before`;
  }

  return `after`;
}

export function StandardPolicyTrigger({
  policy,
  handleChange,
  invalids,
  label,
  excludedTriggerTypes = [],
  excludedTriggerRelatives = [],
}: IStandardPolicyTriggerProps) {
  const [dayType, setDayType] = useState(calculateDayTime(policy.day_index));

  const invoiceStates = [
    {
      label: `Due Date`,
      value: PolicyTargetStatus.DUE,
    },
    {
      label: `Issue Date`,
      value: PolicyTargetStatus.ISSUED,
    },
    {
      label: `Date Marked Paid`,
      value: PolicyTargetStatus.MARKED_PAID,
    },
  ].filter(({ value }) => !excludedTriggerTypes.includes(value));

  const dayTypes = useMemo(() => {
    const result: {
      label: string;
      value: `after` | `on` | `before`;
    }[] = [
      {
        label: `After`,
        value: `after` as const,
      },
      {
        label: `On The`,
        value: `on` as const,
      },
    ];

    if (policy.target_status === PolicyTargetStatus.DUE) {
      result.unshift({
        label: `Before`,
        value: `before` as const,
      });
    }

    return result.filter(({ value }) => !excludedTriggerRelatives.includes(value));
  }, [ policy.target_status ]);

  const dayIndexValue = useMemo(() => {
    let str = policy.day_index.toString();

    if (str.startsWith(`-`)) {
      str = str.replace(`-`, ``);
    }

    return str;
  }, [ policy.day_index ]);

  function onDayIndexChange(e, day = dayType) {
    const { value } = e;

    let num = Number(value);

    if (Number.isNaN(num)) {
      handleChange({
        name: `day_index`,
        value,
      });

      return;
    }

    if (day === `before`) {
      num = num < 0 ? num : -num;
    }

    if (day === `after`) {
      num = num > 0 ? num : -num;
    }

    if (day === `on`) {
      num = 0;
    }

    handleChange({
      name: `day_index`,
      value: num,
    });
  }

  function handleDayTypeChange(e) {
    setDayType(e.value);

    onDayIndexChange({
      value: policy.day_index,
    }, e.value);
  }

  return (
    <Grid cols={ 3 }
      className={ `items-end` }>
      <GridItem>
        <InputTextAddon
          value={ dayIndexValue }
          onChange={ onDayIndexChange }
          name={ `day_index` }
          invalid={ fieldIsValid(`day_index`, invalids) }
          addOnText={ `days ` }
          label={ label }
          disabled={ dayType === `on` }
        />
      </GridItem>
      <GridItem>
        <Select
          options={ dayTypes }
          selected={ dayType }
          onChange={ handleDayTypeChange }
        />
      </GridItem>
      <GridItem>
        <Select
          options={ invoiceStates }
          selected={ policy.target_status }
          onChange={ handleChange }
          name={ `target_status` }
          invalid={ fieldIsValid(`target_status`, invalids) }
        />
      </GridItem>
    </Grid>
  );
}
