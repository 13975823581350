import { EmailTemplateTypes } from "shared";
import { useMemo } from "react";
import { When } from "react-if";

import { Modal } from "../../../../../common/Atoms/Modal";
import { useEmailTemplatesByType } from "../../../../hooks/useEmailTemplates";
import { Dropdown, DropdownOption } from "../../../../../common/Atoms/Dropdown";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { useSendStatementNowMutation } from "../../../../services/api/contactsApi/contact";
import { useIsSafeMode } from "../../../../hooks/useIsSafeMode";
import { useContact } from "../../../../hooks/useContact";

interface SendStatementModalProps {
  open: boolean;
  onClose: () => void;
}

export function SendStatementModal({ open, onClose }: SendStatementModalProps) {
  const { data: contact, isFetching, isLoading, statementPeriod } = useContact();
  const emailTemplates = useEmailTemplatesByType(EmailTemplateTypes.STATEMENT_EMAIL);
  const [sendStatement, { isLoading: isSending }] = useSendStatementNowMutation();
  const safeModeOn = useIsSafeMode();

  async function onSend(templateId: string) {
    console.log(`Send statement to ${contact.name} with template ${templateId}`);
    if (!contact) {
      return;
    }

    await sendStatement({
      contactId: contact.id,
      templateId,
      statementPeriod: statementPeriod,
      organisationId: contact.organisationId,
    });

    onClose();
  }
  const options: DropdownOption[] = useMemo(() => {
    return emailTemplates.map(template => ({
      label: template.name,
      onClick: () => onSend(template.id),
      loading: isFetching || isLoading || isSending,
    }));
  }, [emailTemplates, isFetching, isLoading, contact, isSending]);

  if (!contact) {
    return null;
  }

  return (
    <Modal
      open={ open }
      setOpen={ onClose }
      title={ `` }
    >
      <div className={ `flex justify-center flex-col items-center pb-6` }>
        <Heading>
          { `Send statement to ${contact.name}` }
        </Heading>

        <Paragraph
          variant={ `help` }
          className={ `text-center` }
        >
          { `Immediately send a statement to ${contact.name}'s contacts with the selected email template. Includes outstanding invoices in the current selected period.` }
        </Paragraph>

        <When condition={ safeModeOn }>
          <Paragraph
            variant={ `warning` }
            className={ `text-center` }
          >
            { `This will send even in safe mode!` }
          </Paragraph>
        </When>
      </div>
      <div className={ `flex justify-center` }>
        <Dropdown
          disabled={ options.length === 0 }
          options={ [options] }
          label={ `Choose email template to send with` }
          size={ `md` }
        />
      </div>
    </Modal>
  );
}
