import { useParams } from 'react-router';

import { useFetchInvoiceQuery } from '../services/api/invoiceApi/invoice';

export function useInvoice() {
  const { organisationId, invoiceId } = useParams();

  const result = useFetchInvoiceQuery({
    organisationId,
    invoiceId,
  },{
    skip: !organisationId || !invoiceId,
    pollingInterval: 1000 * 10,
    skipPollingIfUnfocused: true,
  });

  return {
    ...result,
    invoice: result.data?.invoice,
    policyCheck: result.data?.policyCheck,
  };
}
