import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { When } from "react-if";

import { Card } from "../../../../../common/Atoms/Card";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { useContact } from "../../../../hooks/useContact";
import { env } from "../../../../../common/lib/env";
import { SendStatementModal } from "../Modals/SendStatementModal";
import { OrderCreditReportModal } from "../Modals/OrderCreditReportModal";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { Badge } from "../../../../../common/Atoms/Badge";
import { WrappedLink } from "../../../WrappedLink";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";

import { ContactStats } from "./ContactStats";
import { TagSelector } from "./TagSelector";

export function ContactHeading() {
  const { data: contact } = useContact();
  const currentOrg = useGetSelectedOrganisation();
  const [showReportModal, setShowReportModal] = useState(false);
  const [showSendStatementModal, setShowSendStatementModal] = useState(false);

  if (!contact) {
    return null;
  }

  // New UI goes to new group URL
  const groupLink = `/groups/${contact.extraData.group.id}`;

  return (
    <>

      <Card>
        <div className={ `flex justify-between items-start` }>
          <div className={ `flex space-x-1` }>
            <div>
              <When condition={ contact?.parentContactId }>
                <WrappedLink
                  to={ `/contacts/${contact.parentContactId}` }
                  className={ `hover:underline text-indigo-600 hover:text-indigo-800 ` }
                >
                  { `${ contact?.parentContactName } ↰` }
                </WrappedLink>
              </When>
              <Heading>
                { contact.name }
              </Heading>
              <When condition={ contact?.childContacts && contact?.childContacts.length > 0 }>
                { contact.childContacts.map(child => (
                  <p key={ child.id }>
                    <WrappedLink
                      key={ child.id }
                      to={ `/contacts/${child.id}` }
                      className={ `hover:underline text-indigo-600 hover:text-indigo-800 ` }
                    >
                      { `↳ ${ child.name }` }
                    </WrappedLink>
                  </p>
                )) }
              </When>
              <Paragraph
                variant={ `help` }>
                { contact?.externalAccountNumber }
              </Paragraph>

              { /* Tags */ }
              <div className={ `mt-2` }>
                <TagSelector />
              </div>
            </div>
            <a
              href={ `${env.portalWebUrl}/c/${contact.hash}` }
              target={ `_blank` }
              rel={ `noopener noreferrer` }
            >
              <Tooltip title={ `View customer portal` }>
                <ArrowTopRightOnSquareIcon className={ `w-5 h-5 text-blue-400` } />
              </Tooltip>
            </a>

            <WrappedLink
              to={ groupLink }
            >
              <Tooltip title={ `View group` }>
                <Badge
                >
                  { contact.extraData?.group?.title }
                </Badge>
              </Tooltip>
            </WrappedLink>
          </div>
          { /* Quick Actions */ }
          <div>
            <ul className={ `text-right` }>
              { /*  Send a statement */ }
              <li>
                <a
                  href={ `#` }
                  onClick={ () => setShowSendStatementModal(true) }
                >
                  <Paragraph
                    variant={ `link` }
                  >
                    { `Send Statement` }
                  </Paragraph>
                </a>
              </li>

              { /*  Order a credit report */ }
              <li>
                <a
                  href={ `#` }
                  onClick={ () => setShowReportModal(true) }
                >
                  <Paragraph
                    variant={ `link` }
                  >
                    { `Order Credit Report` }
                  </Paragraph>
                </a>
              </li>

              <When condition={ currentOrg?.accountingSystemType === `XERO` }>
                <li>
                  <a
                    href={ `https://go.xero.com/organisationlogin/default.aspx?shortcode=${currentOrg.shortcode}&redirecturl=/Contacts/View/${contact.externalId}` }
                    target={ `_blank` }
                    rel={ `noopener noreferrer` }
                  >
                    <Paragraph
                      variant={ `link` }
                    >
                      { `View in Xero` }
                    </Paragraph>
                  </a>
                </li>
              </When>
            </ul>
          </div>
        </div>

        <ContactStats />

      </Card>
      <OrderCreditReportModal
        onCancel={ () => setShowReportModal(false) }
        show={ showReportModal }
        contact={ contact }
      />
      <SendStatementModal
        open={ showSendStatementModal }
        onClose={ () => setShowSendStatementModal(false) }
      />
    </>
  );
}
