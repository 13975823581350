import { ISubscriptionResponse, ISubscriptionResponseItem, IUpdateSubscriptionRequest, IUpdateSubscriptionResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export interface UpdateSubscriptionPayload {
  id: number;
  update: IUpdateSubscriptionRequest;
}

export const subscriptionsApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchSubscriptions: builder.query<ISubscriptionResponseItem[], void>({
      query: () => ({
        url: `subscription`,
        method: `GET`,
      }),
      transformResponse: (result: ISubscriptionResponse) => result.subscriptions,
      providesTags: result => result ?
        [
          ...result.map(({ id }) => ({ type: CacheTags.Subscriptions, id })),
          { type: CacheTags.Subscriptions, id: `LIST` },
        ]
        : [{ type: CacheTags.Subscriptions, id: `LIST` }],
    }),
    actionSubscription: builder.mutation({
      query: ({ id, action, organisationId }) => ({
        url: `subscription/${id}/${action}`,
        method: `POST`,
        params: {
          org: organisationId,
        },
      }),
      invalidatesTags: ({ id }) => [
        { type: CacheTags.Subscriptions, id },
        { type: CacheTags.Organisations, id: `LIST` },
      ],
    }),
    createSubscription: builder.mutation({
      query: () => ({
        url: `subscription`,
        method: `POST`,
      }),
      invalidatesTags: [{ type: CacheTags.Subscriptions, id: `LIST` }],
    }),
    deleteSubscription: builder.mutation({
      query: ({ id }) => ({
        url: `subscription/${id}`,
        method: `DELETE`,
      }),
      invalidatesTags: [{ type: CacheTags.Subscriptions, id: `LIST` }],
    }),
    updateSubscription: builder.mutation<IUpdateSubscriptionResponse, UpdateSubscriptionPayload>({
      query: ({ id, ...body }) => ({
        url: `subscription/${id}`,
        method: `PATCH`,
        data: body.update,
      }),
      invalidatesTags: ({ id }) => [
        { type: CacheTags.Subscriptions, id },
        { type: CacheTags.Organisations, id: `LIST` },
      ],
    }),
    changePlan: builder.mutation({
      query: ({ subscriptionId, lookupKey }) => ({
        url: `subscription/${subscriptionId}/change-plan`,
        method: `POST`,
        data: {
          lookupKey,
        },
      }),
      invalidatesTags: ({ id }) => [
        { type: CacheTags.Subscriptions, id },
        { type: CacheTags.Organisations, id: `LIST` },
      ],
    }),
    addOnOrg: builder.mutation({
      query: ({ subscriptionId }) => ({
        url: `subscription/${subscriptionId}/add-org`,
        method: `GET`,
      }),
      invalidatesTags: ({ id }) => [
        { type: CacheTags.Subscriptions, id },
        { type: CacheTags.Organisations, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchSubscriptionsQuery,
  useActionSubscriptionMutation,
  useCreateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useChangePlanMutation,
  useAddOnOrgMutation,
} = subscriptionsApi;
