import { classNames } from "../../../../../../common/lib/classNames";

interface PolicyFormRowProps {
  columns?: number;
  children: React.ReactNode;
}

const columnMap = {
  1: `grid-cols-1`,
  2: `grid-cols-2`,
  3: `grid-cols-3`,
  4: `grid-cols-4`,
};

export function PolicyFormRow({
  columns = 1,
  children,
}: PolicyFormRowProps) {
  return (
    <div className={ classNames(
      `grid gap-4 items-end`,
      columnMap[columns],
    ) }>
      { children }
    </div>
  );
}
