import { IActionCreateStatusResponse } from "shared";

import { emptySplitApi } from "../baseApi";
import { orgHeader } from "../axiosBaseQuery";
import { CacheTags } from "../../types/cacheTags";

export const actionCreateStatusApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchActionCreateStatus: builder.query<IActionCreateStatusResponse, string>({
      query: organisationId => ({
        url: `actions/create-in-progress`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      providesTags: [{ type: CacheTags.ActionCreateStatus, id: `LIST` }],
    }),
  }),
});

export const {
  useFetchActionCreateStatusQuery,
} = actionCreateStatusApi;
