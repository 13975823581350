import { useState } from "react";

import { Accordian, AccordianProps } from "./Accordian";

export interface AccordianConfig extends Pick<AccordianProps, `children` | `description` | `title`> {
  initialOpen?: boolean;
  name: string;
}

interface AccordianGroupProps {
  accordians: AccordianConfig[];
}

export function AccordianGroup({
  accordians,
}: AccordianGroupProps) {
  const [ openName, setOpenName ] = useState<string | null>(accordians.find(accordian => accordian.initialOpen)?.name || null);

  return (
    <div>
      { accordians.map(accordian => (
        <Accordian
          key={ accordian.name }
          { ...accordian }
          open={ openName === accordian.name }
          setOpen={ () => {
            if (openName !== accordian.name) {
              setOpenName(accordian.name);
            }
            else {
              setOpenName(null);
            }
          } }
        />
      )) }
    </div>
  );
}
