
import { Modal } from "../../../common/Atoms/Modal";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Button } from "../../../common/Atoms/Button";
import { Divider } from "../../../common/Atoms/Divider";

interface ActionNowModal {
  onCancel: () => void;
  onConfirm: () => void;
  show: boolean;
  message: string;
}

export function ActionNowModal({ onCancel, onConfirm, show, message }: ActionNowModal) {
  function onClose() {
    // This is the top X, treat as cancel
    onCancel();
  }

  return (
    <Modal
      title={ `` }
      open={ show }
      setOpen={ onClose }
    >

      <div className={ `flex flex-col items-center mt-6` }>
        <Paragraph variant={ `primary` }>
          { message }
        </Paragraph>

        <Divider weight={ `light` } />

        <div>
          <Button
            color={ `gray` }
            className={ `mr-4` }
            onClick={ onCancel }
          >
            { `Cancel` }
          </Button>
          <Button
            className={ `mr-4` }
            color={ `red` }
            onClick={ onConfirm }
          >
            { `Process Action Now` }
          </Button>
        </div>
      </div>

    </Modal>
  );
}

