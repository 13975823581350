import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { ActionStatus, ActionType, DateFormats, PolicyProcessType, actionConfig, actionNotesReadable, actionStatementTypes, completeLikeActionStatuses, currencyFormatter, emailTypes, langauge, toDecimal } from "shared";
import { format } from "date-fns";
import {  When } from "react-if";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

import { Drawer } from "../../../common/Atoms/Drawer";
import { RootState } from "../../store";
import { closeAndClear } from "../../slices/actionDrawer";
import { DescriptionItem, DescriptionList } from "../../../common/Atoms/DescriptionList";
import { Card } from "../../../common/Atoms/Card";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { Divider } from "../../../common/Atoms/Divider";
import { WrappedLink } from "../WrappedLink";
import { Grid } from "../../../common/Atoms/Grid";
import { GridItem } from "../../../common/Atoms/GridItem";
import { Button } from "../../../common/Atoms/Button";
import { useActionInvoiceMutation } from "../../services/api/invoiceApi/invoice";
import { useActions } from "../../hooks/useActions";
import { useActionNowMutation, useRemoveActionMutation, useRemoveFromBatchMutation, useRevertActionMutation, useRegenerateChildActionsMutation } from "../../services/api/actionApi/action";
import { Badge } from "../../../common/Atoms/Badge";
import { useApiRequest } from "../../hooks/useApiRequest";
import { env } from "../../../common/lib/env";
import { useGroups } from "../../hooks/useGroups";
import { DatePopover } from "../../../common/Components/DatePopover";
import { Notification } from "../../../common/Atoms/Notification";
import { AICallTranscription } from "../AICalls/Transcription";

import { ActionNowModal } from "./ActionNowModal";
import { ActionStatusBadge } from "./ActionStatusBadge";
import { IgnoreLateFeeModal } from "./IgnoreLateFeeModal";

const hasOriginalInvoice = [
  ActionType.LATE_FEE,
  ActionType.DISCOUNT_APPLIED_EMAIL,
  ActionType.DISCOUNT_REMOVED_EMAIL,
  ActionType.DISCOUNT_APPLY,
  ActionType.DISCOUNT_REMOVE,
  ActionType.LATE_FEE_EMAIL,
  ActionType.REMINDER,
  ActionType.SEND_SMS,
  ActionType.ESCALATION,
];

const allowedToRetry = [
  ActionType.SEND_SMS,
];

export function ActionDrawer() {
  const drawerProps = useSelector((s: RootState) => s.actionDrawer);
  const dispatch = useDispatch();
  const [actionInvoice, { isLoading: actionInvoiceLoading }] = useActionInvoiceMutation();
  const [revertAction, { isLoading: revertActionLoading }] = useRevertActionMutation();
  const [removeAction, { isLoading: removeActionLoading }] = useRemoveActionMutation();
  const [removeFromBatch, { isLoading: removeFromBatchLoading }] = useRemoveFromBatchMutation();
  const [regenerateChildActions, { isLoading: regenerateChildActionsLoading }] = useRegenerateChildActionsMutation();
  const [actionNow, { isLoading: actionNowLoading }] = useActionNowMutation();
  const [showActionNowModal, setShowActionNowModal] = useState(false);
  const [showIgnoreFeeModal, setShowIgnoreFeeModal] = useState(false);
  const [downloadingStatement, setDownloadingStatement] = useState(false);
  const { data: actionsData } = useActions();
  const request = useApiRequest();
  const { data: groups } = useGroups();

  const action = useMemo(() => {
    return actionsData?.actions.find(a => a.id === drawerProps.actionId);
  }, [actionsData, drawerProps.actionId]);

  const contactGroup = useMemo(() => {
    if (!action) return null;

    if (!action.contact?.groupId) return null;

    return groups?.find(g => g.id === action.contact.groupId);
  }, [action, groups]);

  const formatter = currencyFormatter(action?.invoice?.xero_currency);

  // CHECK IF WE SHOULD CLOSE THE DRAWER AS THE ACTION NO LONGER EXISTS IN CACHE
  useEffect(() => {
    if (!action) {
      dispatch(closeAndClear());
    }
  }, [action]);

  function onOpenClose(show: boolean) {
    if (show) {
      console.warn(`No op`);

      return;
    }

    dispatch(closeAndClear());
  }

  function ignorePenalty() {
    setShowIgnoreFeeModal(true);
  }

  async function onConfirmIgnorePenalty() {
    if (!action) return;

    await actionInvoice({
      invoiceId: action.invoiceId,
      action: `ignorePenalty`,
      organisationId: action.organisationId,
      actionId: action.id,
    });

    setShowIgnoreFeeModal(false);
  }

  function onRevert() {
    if (!action) return;

    revertAction({
      actionId: action.id,
      organisationId: action.organisationId,
    });
  }

  async function onActionNow() {
    if (!action) return;

    setShowActionNowModal(false);

    await actionNow({
      actionId: action.id,
      organisationId: action.organisationId,
    });
  }

  function onRemoveAction() {
    if (!action) return;

    removeAction({
      actionId: action.id,
      organisationId: action.organisationId,
    });
  }

  function onRemoveFromBatch() {
    if (!action) return;

    removeFromBatch({
      actionId: action.id,
      organisationId: action.organisationId,
    });
  }

  async function onResendEmail() {
    if (!action) return;

    await regenerateChildActions({
      actionId: action.id,
      organisationId: action.organisationId,
    });

    dispatch(closeAndClear());
  }

  async function downloadStatement(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,actionId: string, statementId: number) {
    e.preventDefault();

    if (downloadingStatement) return;

    setDownloadingStatement(true);
    try {
      const result = await request({
        method: `GET`,
        responseType: `blob`,
        url: `${env.baseApiUrl}actions/${actionId}/statement/${statementId}/download`,
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement(`a`);
      link.href = url;
      link.setAttribute(`download`, `historic_statement_${actionId}_${statementId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
    catch (e) {
      const responseObj = await e.response?.data?.text();
      toast.error(`${responseObj ? `${responseObj}` : `Could not download statement.`}`);
    }

    setDownloadingStatement(false);
  }

  const stats = useMemo(() => {
    if (!action) return [];

    let statusContent = <ActionStatusBadge action={ action }
      size={ `md` } />;

    if (
      (action.status === ActionStatus.FAILED && actionConfig[action.type].canRemoveOnFail)
      // Also allow removing a interest charge that was a noop
      || (action.status === ActionStatus.COMPLETE && action.type === ActionType.ISSUE_STATEMENT_LATE_FEE && !action.statementPenalties?.length)
    ) {
      statusContent = (
        <div>
          <ActionStatusBadge
            action={ action }
            size={ `md` }
          />
          <Button
            onClick={ onRemoveAction }
            loading={ removeActionLoading }
            color={ `red` }
            size={ `sm` }
            className={ `ml-2` }
            tooltip={ `Delete action to allow it to be retried. (If policy conditions are still met)` }
          >
            { `Delete Action` }
          </Button>
        </div>
      );
    }

    if ([ActionStatus.PENDING, ActionStatus.APPROVAL_PENDING].includes(action.status) && actionConfig[action.type].canRemovePending) {
      statusContent = (
        <div>
          <ActionStatusBadge
            action={ action }
            size={ `md` }
          />
          <Button
            onClick={ onRemoveAction }
            loading={ removeActionLoading }
            color={ `gray` }
            size={ `sm` }
            className={ `ml-2` }
            tooltip={ `Cancel this action` }
          >
            { `Cancel Action` }
          </Button>
        </div>
      );
    }

    if (action.onlyWebhook) {
      statusContent = (
        <div className={ `flex` }>
          <ActionStatusBadge
            action={ action }
            size={ `md` }
          />

          <Tooltip title={ `Did not send to contact. Only triggered webhook` }>
            <Badge
              color={ `orange` }
              className={ `ml-2 w-fit` }
              size={ `sm` }
            >
              { `Webhook Only` }
            </Badge>
          </Tooltip>
        </div>
      );
    }

    const commonProps: DescriptionItem[] = [
      {
        title: `Type`,
        content: <Badge
          color={ action.type }
          size={ `md` }
          message={ langauge.action[action.type].label } />,
      },
      {
        title: `Status`,
        content: statusContent,
      },
      {
        title: `Scheduled Date`,
        content: <DatePopover
          date={ action.actionOn }
          organisationTimezone={ action.organisationTimezone }
          labelFormat={ `ccc, DDD t` }
        />,
      },
      {
        title: `Actual Date`,
        content: action.executedOn ?
          <DatePopover
            date={ action.executedOn }
            organisationTimezone={ action.organisationTimezone }
            labelFormat={ `ccc, DDD t` }
          /> : `Not yet ran`,
      },
    ];

    if (emailTypes.includes(action.type)) {
      commonProps.push({
        title: `To`,
        content: action.payload?.contactPerson?.email ||
        action.sendgridMessage?.toAddress ||
        `-`,
      });

      if (action.sendgridMessage?.bccAddress) {
        commonProps.push({
          title: `BCC Address`,
          content: action.sendgridMessage.bccAddress,
        });
      }

      if (action.sendgridMessage?.ccAddress) {
        commonProps.push({
          title: `CC Address`,
          content: action.sendgridMessage.ccAddress,
        });
      }
    }

    if (action.type === ActionType.SEND_SMS) {
      commonProps.push({
        title: `To`,
        content: action.payload?.smsPayload?.to ||  `-`,
      });
    }

    if (hasOriginalInvoice.includes(action.type)) {
      commonProps.push({
        title: `Original Invoice`,
        content: (<WrappedLink
          to={ `/invoices/${action.invoiceId}` }
          className={ `text-blue-600 underline flex items-start` }
        >
          { action.invoice?.xero_number ? action.invoice?.xero_number : `No Number` }
          <ArrowTopRightOnSquareIcon
            className={ `inline-block w-4 h-4 ml-1` }
            aria-hidden={ `true` }
          />
        </WrappedLink>),
      });
    }

    // Now some action specific props
    if (action.type === ActionType.LATE_FEE) {
      if (
        action.penalty?.process_type === PolicyProcessType.NEW_INVOICE &&
        action.penalty?.internal_invoice_id_for_penalty
      ) {
        commonProps.push({
          title: `New Penalty Invoice`,
          content: (<WrappedLink
            to={ `/invoices/${action.penalty?.internal_invoice_id_for_penalty}` }
            className={ `text-blue-600 underline flex items-start` }
          >
            { action.penalty?.xero_invoice_number }
            <ArrowTopRightOnSquareIcon
              className={ `inline-block w-4 h-4 ml-1` }
              aria-hidden={ `true` }
            />
          </WrappedLink>),
        });
      }

      if (action.penalty?.process_type) {
        // Legacy processed actions may not have this
        commonProps.push({
          title: `Issued As`,
          content: action.penalty?.process_type === PolicyProcessType.NEW_INVOICE ? `New Invoice` : `Line Item(s) on Original Invoice`,
        });
      }

      commonProps.push({
        title: `Fee Amount`,
        content: completeLikeActionStatuses.includes(action.status) ? formatter(toDecimal(action.penalty?.penalty_amount_total)) : `Calculated When Sent`,
      });

      if (action.status === ActionStatus.COMPLETE || action.status === ActionStatus.REVERTED) {
        commonProps.push({
          title: `Late Fee Ignored`,
          content: action.ignored ? `Yes` : (
            <Button
              onClick={ ignorePenalty }
              loading={ actionInvoiceLoading }
              color={ `red` }
            >
              { `Ignore Late Fee` }
            </Button>
          ),
          subText: `Ignoring a late fee will allow it to be recalculated & reapplied!`,
        });
      }
    }

    if (actionStatementTypes.includes(action.type)) {
      if (action.batchCount) {
        commonProps.push({
          title: `Number of actions in batch`,
          content: action.batchCount,
        });
      }
    }

    if (action.type === ActionType.ISSUE_STATEMENT_LATE_FEE && (action.status === ActionStatus.COMPLETE || action.status === ActionStatus.REVERTED)) {
      action.statementPenalties.forEach(p => {
        commonProps.push({
          title: `Interest Calculation Date`,
          content: <DatePopover
            date={ p.calculationDate }
            organisationTimezone={ action.organisationTimezone }
            labelFormat={ `ccc, DDD t` }
          />,
        });

        const title = action.statementPenalties.length > 1 ? `Issued Charges Invoice (${p.currency})` : `Issued Charges Invoice`;

        commonProps.push({
          title,
          content: (<WrappedLink
            to={ `/invoices/${p.invoiceId}` }
            className={ `text-blue-600 underline flex items-start` }
          >
            { p.invoiceNumber }
            <ArrowTopRightOnSquareIcon
              className={ `inline-block w-4 h-4 ml-1` }
              aria-hidden={ `true` }
            />
          </WrappedLink>),
        });
      });

      if (!action.statementPenalties.length) {
        commonProps.push({
          title: `Issued Charges Invoice(s)`,
          content: `No overdue balance found with policy conditions`,
        });
      }
    }

    if (action.type === ActionType.SEND_STATEMENT_INTEREST_EMAIL) {
      commonProps.push({
        title: `Currency`,
        content: action.payload?.currency || `-`,
      });
    }

    if (action.type === ActionType.SEND_STATEMENT && action.statements?.length) {
      const element = (
        <div className={ `flex flex-col` }>
          {
            action.statements.map(s => (
              <a
                onClick={ e => downloadStatement(e, action.id, s.id) }
                key={ s.id }
              >
                <Paragraph
                  variant={ `link` }
                >
                  { `Download ${s.currency} Statement` }
                </Paragraph>
              </a>
            ))
          }
        </div>
      );

      commonProps.push({
        title: `Generated Statements`,
        content: element,
      });
    }

    if (action.type === ActionType.AI_CALL_INVOICE) {
      commonProps.push({
        title: `Number to Call`,
        content: action.payload?.to || `-`,
      });

      if (action.status === ActionStatus.COMPLETE && action.aiCall) {
        commonProps.push(...[
          {
            title: `Invoice`,
            content: (<WrappedLink
              to={ `/invoices/${action.invoiceId}` }
              className={ `text-blue-600 underline flex items-start` }
            >
              { action.invoice?.xero_number }
              <ArrowTopRightOnSquareIcon
                className={ `inline-block w-4 h-4 ml-1` }
                aria-hidden={ `true` }
              />
            </WrappedLink>),
          },
          {
            title: `Call Status`,
            content: action.aiCall.status,
          },
          {
            title: `Call Duration`,
            content: `${action.aiCall.durationMinutes} minutes`,
          },
          {
            title: `Credit Cost`,
            content: action.aiCall.cost,
          },
        ]);
      }
    }

    // TODO, Ensure we save all triggering policies
    if (action.policies?.length) {
      commonProps.push({
        title: `Triggered By Policies`,
        content: action.policies?.map(p => {
          const policy = { ...p };

          if (policy.system) {
            return `Manual Trigger`;
          }

          if (policy.deleted) {
            policy.title = `${policy.title} (Deleted)`;
          }

          return policy.title;
        }).join(`, `),
      });
    }

    // Always put the notes at the bottom
    if (action.notes.length) {
      commonProps.push({
        title: `Notes`,
        content: action.notes.map(n => actionNotesReadable[n]).join(`\n`),
      });
    }

    return commonProps;
  }, [action, downloadingStatement]);

  const emailHistory = useMemo(() => {
    if (!action) return null;
    if (!emailTypes.includes(action.type)) return null;
    if (!action.sendgridMessage) return null;

    const events = action.sendgridMessage.events?.map(e => {
      return {
        title: format(new Date(e.timestamp), DateFormats.UI_SHORT_TIME),
        content: (
          <div>
            <p>
              { langauge.sendgrid.event[e.eventType] || e.eventType }
            </p>
            <Paragraph
              variant={ `help` }
            >
              { e.email ? e.email : `` }
            </Paragraph>
          </div>
        ),
      };
    });

    return [
      {
        title: `Sent to`,
        content: action.sendgridMessage.toAddress || action.payload?.contactPerson?.email || `Not Recorded`,
      },
      {
        title: `From`,
        content: action.sendgridMessage.fromAddress || `Not Recorded`,
      },
      ...events,
    ];

  }, [action]);

  const smsHistory: DescriptionItem[] | null = useMemo(() => {
    if (!action) return null;
    if (action.type !== ActionType.SEND_SMS) return null;

    if ([ActionStatus.PENDING, ActionStatus.APPROVAL_PENDING].includes(action.status)) {
      return [
        {
          title: `Delivery Progress Status`,
          content: `Not yet sent`,
        },
      ];
    }

    if (action.onlyWebhook) {
      return [
        {
          title: `Delivery Progress Status`,
          content: `Not Applicable - Webhook Only`,
        },
        {
          title: `Webhook Payload To`,
          content: action.payload?.smsPayload?.to || `-`,
        },
        {
          title: `Webhook Payload Body`,
          content: action.payload?.smsPayload?.body || `-`,
        },
      ];
    }

    return [
      {
        title: `Delivery Progress Status`,
        content: action.sms?.status || `-`,
      },
      {
        title: `Sent to`,
        content: action.sms?.to || `-`,
      },
      {
        title: `Sent From`,
        content: action.sms?.from || `-`,
        subText: `The number the SMS message would have received from`,
      },
      {
        title: `Message`,
        content: action.sms?.body || `-`,
      },
    ];
  }, [action]);

  const webhookHistory: DescriptionItem[] | null = useMemo(() => {
    if (!action) return null;
    if (!action.outboundWebhooks?.length) return null;

    return action.outboundWebhooks.map(w => {
      return {
        title: new Date(w.createdAt).toLocaleString(),
        content: (
          <span className={ `flex flex-col` }>
            <span>
              { `Status: ${w.responseCode}` }
            </span>
            <span>
              { `URL: ${w.destinationUrl}` }
            </span>
          </span>
        ),
      };
    });
  }, [action]);

  const adminDebug: DescriptionItem[] | null = useMemo(() => {
    if (env.isProd) return null;
    if (!action) return null;

    return [
      {
        title: `ID`,
        content: action.id,
      },
      {
        title: `Hash`,
        content: action.hash,
      },
      {
        title: `Created At`,
        content: action.createdAt as unknown as string,
      },
      {
        title: `Updated At`,
        content: action.updatedAt as unknown as string,
      },
      {
        title: `Payload`,
        content: <pre>{ JSON.stringify(action.payload, null, 2) }</pre>,
      },
    ];
  }, [action]);

  // Max size of 4
  const buttons = useMemo(() => {
    if (!action) return [];

    const buttonArray: {
      label: string;
      onClick: () => void;
      disabled?: boolean;
      loading?: boolean;
      tooltip?: string;
    }[] = [];

    if ([ActionStatus.PENDING, ActionStatus.APPROVAL_PENDING].includes(action.status) && actionConfig[action.type].canActionNow) {
      buttonArray.push({
        label: `Action Now`,
        onClick: () => setShowActionNowModal(true),
        loading: actionNowLoading,
        tooltip: `This will process the action immediately. Ignoring the scheduled date!`,
      });
    }

    if (
      action.type === ActionType.LATE_FEE &&
      action.status === ActionStatus.COMPLETE
    ) {
      const tooltipText = action.penalty?.process_type === PolicyProcessType.NEW_INVOICE ?
        `This will attempt to void the invoice` :
        `This will attempt to remove the line item(s) from the original invoice. If there are payments applied. A credit note will be applied instead`;

      buttonArray.push({
        label: `Remove Late Fee`,
        onClick: onRevert,
        loading: revertActionLoading,
        tooltip: tooltipText,
      });

      // Also add a button to resend the late fee email
      let emailTooltip = `Resend the late fee email to contacts`;
      let emailDisabled = false;

      if (action.policies?.length) {
        const hasTemplate = action.policies.some(p => p.template_id);
        if (!hasTemplate) {
          emailTooltip = `No related policies have a late fee email template set`;
          emailDisabled = true;
        }

        const onlySendToSelf = action.policies.every(p => p.do_not_send_email_to_customer);

        if (onlySendToSelf) {
          emailTooltip = `A related policy has is configured to only send emails to the reply to address`;
          emailDisabled = true;
        }

        buttonArray.push({
          label: `Resend Late Fee Email(s)`,
          disabled: emailDisabled,
          tooltip: emailTooltip,
          onClick: onResendEmail,
          loading: regenerateChildActionsLoading,
        });
      }
    }

    if (
      action.type === ActionType.ISSUE_STATEMENT_LATE_FEE &&
      action.status === ActionStatus.COMPLETE &&
      action.statementPenalties?.length
    ) {
      const tooltipText = `This will attempt to void the invoice. Excluding this from future calculations`;

      buttonArray.push({
        label: `Remove Interest Charge(s)`,
        onClick: onRevert,
        loading: revertActionLoading,
        tooltip: tooltipText,
      });
    }

    if (action.type === ActionType.DISCOUNT_APPLY) {
      if ([ActionStatus.PENDING, ActionStatus.APPROVAL_PENDING].includes(action.status)) {
        buttonArray.push({
          label: `Apply Now`,
          onClick: () => actionInvoice({
            invoiceId: action.invoiceId,
            action: `actionNow`,
            organisationId: action.organisationId,
            actionId: action.id,
          }),
        });
      }
    }

    if (action.type === ActionType.DISCOUNT_REMOVE) {
      if (action.status === ActionStatus.PENDING) {
        buttonArray.push({
          label: `Remove Now`,
          onClick: () => actionInvoice({
            invoiceId: action.invoiceId,
            action: `actionNow`,
            organisationId: action.organisationId,
            actionId: action.id,
          }),
        });
      }
    }

    if (actionStatementTypes.includes(action.type) && action.batchCount && completeLikeActionStatuses.includes(action.status)) {
      buttonArray.push({
        label: `Remove From Batch`,
        tooltip: `Removing from batch allows other pending actions to be cleared`,
        onClick: onRemoveFromBatch,
        loading: removeFromBatchLoading,
      });
    }

    return buttonArray;
  }, [action, actionInvoiceLoading, ignorePenalty, onRevert, revertActionLoading, contactGroup, regenerateChildActionsLoading]);

  return (
    <Drawer
      open={ drawerProps.open }
      setOpen={ onOpenClose }
    >
      <DescriptionList
        description={ action && langauge.action[action.type].userDescription }
        title={ `Action Details` }
        items={ stats }
      />

      <When condition={ action?.createdFromPreview }>
        <Notification
          type={ `info` }
          className={ `mt-4` }
        >
          { `Created manually from policy preview. Some checks were omitted.` }

        </Notification>
      </When>

      { /* Actionable Buttons */ }
      <When condition={ !!buttons.length }>
        <Grid
          className={ `mt-6` }
          cols={ 2 }>
          {
            buttons.map((button, i) => {

              return (
                <GridItem
                  key={ i }
                  span={ 1 }
                  position={ `bottom` }
                >
                  <Button
                    onClick={ button.onClick }
                    disabled={ button.disabled }
                    loading={ button.loading }
                    tooltip={ button.tooltip }
                  >
                    { button.label }
                  </Button>
                </GridItem>
              );
            })
          }
        </Grid>
        <When condition={ !!buttons.find(b => b.label === `Remove Late Fee`) }>
          <Paragraph
            variant={ `help` }
            className={ `mt-2` }
          >
            {
              action?.penalty?.process_type === PolicyProcessType.NEW_INVOICE ?
                `Removing this late fee will attempt to void the created invoice` :
                `Removing this late fee will attempt to remove the late line item(s) added to the original invoice. If there are any payments/credit notes applied. A credit note will be applied instead.`
            }
          </Paragraph>
        </When>
      </When>

      <When condition={ !!emailHistory }>
        <DescriptionList
          description={ `View delivery and open history for this email` }
          className={ `mt-6` }
          title={ `Email History` }
          items={ emailHistory }
        />
      </When>

      <When condition={ !!smsHistory }>
        <DescriptionList
          description={ `View delivery history for this SMS` }
          className={ `mt-6` }
          title={ `SMS History` }
          items={ smsHistory }
        />
      </When>

      <When condition={ !!webhookHistory }>
        <DescriptionList
          description={ `Webhooks sent as a result of this action.` }
          className={ `mt-6` }
          title={ `Webhook History` }
          items={ webhookHistory }
        />
      </When>

      <When condition={ action?.aiCall?.transcripts?.length }>
        { () => <AICallTranscription call={ action.aiCall }
          compact /> }
      </When>
      <When condition={ action?.errorMessage }>
        <Card
          className={ `mt-6` }
        >
          <Heading>
            { `Errors` }
          </Heading>

          <Divider />

          <Paragraph
            variant={ `error` }
          >
            { action?.errorMessage }
          </Paragraph>
        </Card>
      </When>

      <When condition={ action?.status === ActionStatus.FAILED && allowedToRetry.includes(action?.type) }>
        { /* TODO */ }
        { /* <Button
          className={ `mt-6` }
          onClick={ () => ({
            invoiceId: action.invoiceId,
            action: `removeAndRetry`,
            organisationId: action.organisationId,
            actionId: action.id,
          }) }
        >
          { `Remove & Retry` }
        </Button> */ }
      </When>
      <ActionNowModal
        message={ `This will process the action immediately, even in safe mode! Regardless of the scheduled date!` }
        onCancel={ () => setShowActionNowModal(false) }
        onConfirm={ onActionNow }
        show={ showActionNowModal }
      />

      <IgnoreLateFeeModal
        message={ `Ignoring the late fee will allow it to be recalculated and reapplied if its still applicable. Do not do this if you no longer want a late fee applied to this invoice!` }
        onCancel={ () => setShowIgnoreFeeModal(false) }
        onConfirm={ onConfirmIgnorePenalty }
        show={ showIgnoreFeeModal }
      />

      { /*  Only show in Non Prod */ }
      <When condition={ !!adminDebug && env.environment !== `production` }>
        <DescriptionList
          className={ `mt-6` }
          title={ `Debug` }
          items={ adminDebug }
        />
      </When>
    </Drawer>
  );
}
