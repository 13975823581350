import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { env } from '../common/lib/env';
import { createMiddleware } from '../common/middleware';

import { emptySplitApi as extendedApi } from './services/api/baseApi';
import { invoiceParamsReducer } from './slices/invoiceParams.js';
import { lateFeeRevertModalReducer } from './slices/revertLateFeeModal';
import { actionDrawerReducer } from './slices/actionDrawer';
import { policyDrawerReducer } from './slices/policyDrawer';
import { contactParamsReducer } from './slices/contactParams';
import { escalationParamsReducer } from './slices/escalationParams';
import { escalationDrawerReducer } from './slices/escalationDrawer';
import { contactPagePrefsReducer } from './slices/contactPagePrefs';
import { actionTableModelReducer } from './slices/actionTableModels';
import { modalSlicerReducer } from './slices/modals';
import { previewPolicyReducer } from './slices/previewPolicy';
import { settingsStateReducer } from './slices/settingsState';

const rootReducer = combineReducers({
  [extendedApi.reducerPath]: extendedApi.reducer,
  invoiceParams: invoiceParamsReducer,
  lateFeeRevertModal: lateFeeRevertModalReducer,
  escalationParams: escalationParamsReducer,
  actionDrawer: actionDrawerReducer,
  actionTableModels: actionTableModelReducer,
  escalationDrawer: escalationDrawerReducer,
  policyDrawer: policyDrawerReducer,
  contactParams: contactParamsReducer,
  contactPagePrefs: contactPagePrefsReducer,
  modals: modalSlicerReducer,
  previewPolicy: previewPolicyReducer,
  settingsState: settingsStateReducer,
});

export type RootState = ReturnType<typeof rootReducer>

export const store = configureStore({
  reducer: rootReducer,
  middleware: createMiddleware,
  devTools: !env.isProd,
});

// Configure listeners to allow out of focus detection to pause polling
setupListeners(store.dispatch);
