import { useMemo, useState } from 'react';
import { UserTypes } from 'shared';
import { When } from 'react-if';
import { DateTime } from 'luxon';

import { Card } from '../../../common/Atoms/Card';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { DescriptionList } from '../../../common/Atoms/DescriptionList';
import { Spinner } from '../Common/Spinner';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { useInviteSupportMutation, useRemoveSupportMutation } from '../../services/api/organisationApi/organisation';
import { Heading } from '../../../common/Atoms/Typography/Heading';
import { FormDescription } from '../../../common/Atoms/FormDescription';
import { CardContent } from '../../../common/Atoms/CardContent';
import { useInviteUserMutation, useUninviteMutation } from '../../services/api/userApi/user';
import { InputTextAddon } from '../../../common/Atoms/InputTextAddon';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { Button } from '../../../common/Atoms/Button';
import { Modal } from '../../../common/Atoms/Modal';
import { Divider } from '../../../common/Atoms/Divider';
import { Notification } from '../../../common/Atoms/Notification';
import { useUser } from '../../hooks/useUser';

export function UserSettings() {
  const [ removeSupport, { isLoading: isRemovingSupport } ] = useRemoveSupportMutation();
  const [ inviteSupport, { isLoading: isInvitingSupport } ] = useInviteSupportMutation();

  const currentOrg = useGetSelectedOrganisation();
  const { data: user } = useUser();
  const userConnections = currentOrg?.users || [];

  const [inviteUser, { isLoading: inviteUserLoading }] = useInviteUserMutation();
  const [ uninvite, { isLoading: isUninviting } ] = useUninviteMutation();
  const [ inviteName, setInviteName ] = useState<string>(``);
  const [ inviteEmail, setInviteEmail ] = useState<string>(``);

  const [showInviteModal, setShowInviteModal] = useState(false);

  // check invite limits, 10 is the max enforced on the server
  const orgTooManyInvites = currentOrg?.pendingInvites?.length >= 10;

  const connectedUser = useMemo(() => {
    return (user?.orgs?.find(org => org.id === currentOrg.id)?.type === UserTypes.Inherit) || false;
  }, [user, currentOrg]);

  function onInvite() {
    inviteUser({ inviteName, inviteEmail, orgId: currentOrg.id });
    setInviteEmail(``);
    setInviteName(``);
  }

  function onClose() {
    setShowInviteModal(false);
  }

  function onRemoveUser(email) {
    uninvite({ orgId: currentOrg.id, userEmailBeingUninvited: email });
  }

  const connections = useMemo(() => {
    return userConnections.map(c => {

      // TODO: this is a bit janky, we need consistency in users but for now this will allow all users to be displayed
      let connected;
      if (c.type === UserTypes.Support) {
        connected = (<><Paragraph>{ `Paidnice Support` }</Paragraph></>);
      }
      else if(c.type === UserTypes.Invited) {
        connected = connectedUser ? (<><span>{ `Invited` }</span>
          <Button
            onClick={ () => onRemoveUser(c.email) }
            className= { `text-xs bg-red-500 hover:bg-red-600 text-white ml-2 py-1 px-1` }
            loading={ isUninviting }
          >{ `Remove` }</Button></>) : (<><span>{ `Invited` }</span></>);
      }
      else {
        connected = (<><Paragraph as={ `span` }>{ `${connected = c.connected ? `Connected` : `Disconnected`}` }</Paragraph></>);
      }

      return ({
        title: c.firstName? (c.firstName + ` ` + c.lastName) : c.email,
        content: connected,
        child: c.lastLogin ? (
          <Paragraph
            as={ `span` }
            variant={ `help` }
          >
            { `Last Login: ${DateTime.fromJSDate(new Date(c.lastLogin)).toFormat(`dd LLL yyyy HH:mm`)}` }
          </Paragraph>
        ) : <Paragraph
          as={ `span` }
          variant={ `help` }
        >
          { `N/A` }
        </Paragraph>,
      });
    });
  }, [ userConnections, user ]);

  const hasSupport = userConnections?.some(c => c.type === UserTypes.Support);

  const supportButton = hasSupport ?
    {
      buttonText: `Remove Paidnice Support`,
      onClick: handleRemoveSupport,
      loading: isRemovingSupport,
    } :
    {
      buttonText: `Invite Paidnice Support`,
      onClick: handleConnectSupport,
      loading: isInvitingSupport,
    };

  const inviteButton = {
    buttonText: `Invite User`,
    onClick: () => { setShowInviteModal(true); },
  };

  function handleConnectSupport() {
    return inviteSupport(currentOrg.id);
  }

  function handleRemoveSupport() {
    return removeSupport(currentOrg.id);
  }

  if (!currentOrg) {
    return <Spinner />;
  }

  return (
    <div>
      <Card>
        <MultiButtonCardHeader
          title={ `Users` }
          buttons={ [
            inviteButton,
            supportButton,
          ] }
          noDivider={ true }
        />
        <DescriptionList
          items={ connections }
          description={ `Users that have access to this organisation` } />

        <When condition={ currentOrg.pendingInvites?.length > 0 }>
          <div className={ `py-4` } />

          <DescriptionList
            className={ `mt-3` }
            description={ `Pending Invites` }
            items={ currentOrg.pendingInvites?.map(invite => ({
              title: invite.name || `No name`,
              content: <span title={ `Invited by: ${invite.invitedBy}` }>{ invite.email }</span>,
              child: <Paragraph
                as={ `span` }
                variant={ `help` }
              >
                { `Invite Sent: ${DateTime.fromJSDate(new Date(invite.createdAt)).toFormat(`dd LLL yyyy HH:mm`)}` }
              </Paragraph>,
            })) || [] }
          />
        </When>
      </Card>
      <Modal
        title={ `` }
        open={ showInviteModal }
        setOpen={ onClose }
      >
        <CardContent>
          <div
            className={ `space-y-3` }>

            <Heading>
              { `Invite a new user` }
            </Heading>
            <FormDescription>
              { `You can invite another user to join this Paidnice account` }
            </FormDescription>

            <div
              className={ `space-y-3` }>

              <div
                className={ `flex items-end justify-between w-full` }
              >
                <InputTextAddon
                  className={ `w-1/2 mr-6` }
                  label={ `Name` }
                  value={ inviteName }
                  onChange={ e => setInviteName(e.value as string) }
                />

                <InputTextAddon
                  className={ `w-1/2` }
                  label={ `Email` }
                  value={ inviteEmail }
                  onChange={ e => setInviteEmail(e.value as string) }
                />
              </div>
              <div className={ `flex justify-end mt-3` }>
                <Button
                  onClick={ onInvite }
                  type={ `button` }
                  disabled={ orgTooManyInvites }
                  loading={ inviteUserLoading }
                >
                  { `Invite` }
                </Button>
              </div>
            </div>
            <Divider />
            <div>
              <Heading>
                { `Pending Invites` }
              </Heading>
              <FormDescription>
                { `These are the users invited to join this Paidnice account` }
              </FormDescription>
              <When condition={ orgTooManyInvites }>
                <Notification
                  className={ `mt-6` }
                  type={ `info` }
                >
                  { `You have reached the limit for pending invitations. Please wait until your invites are accepted before inviting more users.` }
                </Notification>
              </When>
              <DescriptionList
                className={ `mt-3` }
                items={ currentOrg.pendingInvites?.map(invite => ({
                  title: invite.name || `No name`,
                  content: <span title={ `Invited by: ${invite.invitedBy}` }>{ invite.email }</span>,
                })) || [] }
              />
            </div>
          </div>
        </CardContent>
      </Modal>
    </div>
  );
}
