import React, { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StripePricesLookupKeys, SubscriptionStatus, findPriceByLookupKey } from 'shared';
import { Else, If, Then, When } from 'react-if';

import { capitalizeEachWord } from '../../lib/capitalize';
import { Card } from '../../../common/Atoms/Card';
import { ListItems } from '../../../common/Atoms/ListItems';
import { useSubscriptions } from '../../hooks/useSubscriptions';
import { useCreateSubscriptionMutation } from '../../services/api/subscriptionApi/subscription';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { FIND_SUBSCRIPTION_ARTICLE } from '../../constants/links';
import { Heading } from '../../../common/Atoms/Typography/Heading';
import { Badge } from '../../../common/Atoms/Badge';
import { Button } from '../../../common/Atoms/Button';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { useOrganisations } from '../../hooks/useOrganisations';
import { CardContent } from '../../../common/Atoms/CardContent';
import { Notification } from '../../../common/Atoms/Notification';

export function Subscriptions() {
  const { data: subscriptions, isLoading } = useSubscriptions();
  const [ createSubscription, { isLoading: newSubLoading } ] = useCreateSubscriptionMutation();
  const { data: organisations } = useOrganisations();
  const navigate = useNavigate();

  const parsed = useMemo(() => {
    if (subscriptions) {
      return subscriptions.map(account => {
        const orgList = account.organisations.map(org => org.legalName).join(`, `);
        if (account.status === SubscriptionStatus.NONE || !account.stripeSubscriptionId) {
          return {
            title: `No Plan Chosen`,
            description: orgList ? `Linked Organisations: ${orgList}` : `No Organisations Linked`,
            id: account.id,
            onClick: onAccountClick,
          };
        }

        // New pricing
        const priceConfig = findPriceByLookupKey(account.mainLookupKey as StripePricesLookupKeys);

        if (!priceConfig) {
          return {
            title: `Unknown Subscription`,
            description: `Error - contact support`,
            id: account.id,
            onClick: onAccountClick,
          };
        }

        let color = `red`;

        if (account.status === SubscriptionStatus.ACTIVE) {
          color = `green`;
        }

        if (account.status === SubscriptionStatus.TRIALING) {
          color = `yellow`;
        }

        return {
          title: (
            <div className={ `flex` }>
              <Heading>
                { `${priceConfig.name}` }
              </Heading>
              <Badge
                className={ `ml-2` }
                color={ color }
              >
                { capitalizeEachWord(account.status) }
              </Badge>
            </div>
          ),
          description: `Linked Organisations: ${orgList}. Credit Balance: ${account.walletBalanceFormatted}`,
          id: account.id,
          onClick: onAccountClick,
        };

      });
    }

    return [];
  }, [ subscriptions ]);

  // Orgs where another user owns sub
  const otherSubscriptionedOrgs = useMemo(() => {
    if (!organisations) {
      return [];
    }

    if (!subscriptions) {
      return organisations;
    }

    return organisations.filter(org => !subscriptions.some(sub => sub.id === org.subscriptionMetadata?.id));
  }, [ organisations, subscriptions ]);
  function onAccountClick(e, id) {
    e.preventDefault();
    navigate(`/billing/subscriptions/` + id);
  }

  return (
    <Card id={ `subscriptions-card` }>
      <MultiButtonCardHeader
        title={ `Billing Subscriptions` }
        description={ `Assign Organisations to Subscriptions & Manage Payments` }
        secondaryDescription={ `Click on a subscription to start a free trial, and then link an organisation to it` }
      />

      <div className={ `py-6 flex justify-center w-full` }>
        <If condition={ parsed?.length > 0 }>
          <Then>
            <ListItems
              loading={ isLoading }
              fullWidth
              items={ parsed }
            />
          </Then>
          <Else>
            <div className={ `flex flex-col items-center` }>
              <Button
                className={ `mt-12` }
                onClick={ createSubscription }
                loading={ newSubLoading }
                disabled={ newSubLoading }
              >
                { `Create New Subscription` }
              </Button>
              <Paragraph
                variant={ `help` }
                className={ `mb-12 mt-4` }
              >
                { `You have no subscriptions, click "New Subscription" to create a subscription or trial` }
              </Paragraph>
            </div>
          </Else>
        </If>
      </div>

      { /* Other orgs */ }
      <When condition={ otherSubscriptionedOrgs?.length > 0 }>
        {
          () => (
            <CardContent className={ `pb-6` }>
              <Heading>
                { `Other Connected Organisations` }
              </Heading>
              <Notification>
                { `These organisations are linked to subscriptions managed by someone else. Contact the subscription owner to manage these organisations.` }
              </Notification>
              <div className={ `pt-6` }/>
              {
                otherSubscriptionedOrgs.map(org => (
                  <Fragment key={ org.id }>
                    <div className={ `grid grid-cols-2 gap-2 border-b border-gray-200 w-full p-4` }>
                      <Paragraph variant={ `primary` }>
                        { org.legalName }
                      </Paragraph>
                      <div>
                        <Paragraph variant={ `secondary` }>
                          { org.billingManagedBy }
                        </Paragraph>
                      </div>
                    </div>
                  </Fragment>
                ))
              }
            </CardContent>
          )
        }
      </When>

      <div>
        <a
          className={ `mt-1 text-sm text-gray-500` }
          href={ FIND_SUBSCRIPTION_ARTICLE }
          target={ `_blank` }
          rel={ `noopener noreferrer` }
        >
          { `Can't see an active subscription for your organisation?` }
        </a>
      </div>
    </Card>
  );
}
