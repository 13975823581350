import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { EscalationOutcomeLabels, EscalationStatus, IEscalationOutcome, PolicyEscalationType, currencyFormatter, toDecimal } from "shared";
import { When } from "react-if";
import { Tooltip } from "@mui/material";
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';

import { Drawer } from "../../../common/Atoms/Drawer";
import { RootState } from "../../store";
import { closeAndClear } from "../../slices/escalationDrawer";
import { DescriptionItem, DescriptionList } from "../../../common/Atoms/DescriptionList";
import { Grid } from "../../../common/Atoms/Grid";
import { GridItem } from "../../../common/Atoms/GridItem";
import { Button } from "../../../common/Atoms/Button";
import { useEscalations } from "../../hooks/useEscalations";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { Divider } from "../../../common/Atoms/Divider";
import { Card } from "../../../common/Atoms/Card";
import { useAddNoteMutation, useAssignEscalationMutation, useClearAssignedEscalationMutation, useMarkCancelledEscalationMutation, useMarkCompleteEscalationMutation, useMarkSnoozeEscalationMutation } from "../../services/api/escalationApi/escalation";
import { WrappedLink } from "../WrappedLink";
import { Dropdown } from "../../../common/Atoms/Dropdown";
import { Select } from "../../../common/Atoms/Select";
import { DatePopover } from "../../../common/Components/DatePopover";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { ExpectedPaymentDate } from "../Common/ExpectedPaymentDate";
import { Notes } from "../Molecules/Notes";
import { useReopenEscalationMutation } from "../../services/api/escalationApi/escalation";
import { Assignee } from "../Common/Assignee";

import { getEscalationDisplayStatus } from "./EscalationsTable";

export function EscalationDrawer() {
  const drawerProps = useSelector((s: RootState) => s.escalationDrawer);
  const dispatch = useDispatch();
  const { data: escalationsData } = useEscalations();

  const [ outcome, setOutcome ] = useState(``);

  const [addNoteApiCall, { isLoading: addNoteLoading }] = useAddNoteMutation();
  const [completeApiCall, { isLoading: completeLoading }] = useMarkCompleteEscalationMutation();
  const [cancelApiCall, { isLoading: cancelLoading }] = useMarkCancelledEscalationMutation();
  const [snoozeApiCall, { isLoading: snoozeLoading }] = useMarkSnoozeEscalationMutation();
  const [reopenApiCall, { isLoading: reopenLoading }] = useReopenEscalationMutation();
  const [assignEscalationApiCall, { isLoading: assignLoading }] = useAssignEscalationMutation();
  const [clearAssignedUserApiCall, { isLoading: clearAssignLoading }] = useClearAssignedEscalationMutation();

  const organisation = useGetSelectedOrganisation();

  const escalation = useMemo(() => {
    const found = escalationsData?.escalations.find(a => a.id === drawerProps.escalationId);

    return found;
  }, [escalationsData, drawerProps.escalationId]);

  // Close the drawer when not in cache
  useEffect(() => {
    if (!escalation) {
      dispatch(closeAndClear());
    }
  }, [escalation]);

  const snoozeOption = useMemo(() => {
    const options = [
      {
        label: `Tomorrow`,
        value: `1`,
      },
      {
        label: `3 Days Later`,
        value: `3`,
      },
      {
        label: `Next Week`,
        value: `7`,
      },
      {
        label: `Next Month`,
        value: `30`,
      },
    ];
    if (!escalation) return options;

    // we have an expected payment date and it's in the future
    if (escalation.invoice?.expected_payment_date_str && organisation.validatedTimezone) {

      const expPayDateTime = DateTime.fromISO(escalation.invoice.expected_payment_date_str, { zone: organisation.validatedTimezone });
      const orgTz = organisation.validatedTimezone;
      const now = DateTime.local().setZone(orgTz);

      if (expPayDateTime > now) {

        // round up to nearest full day for snoozing
        const days = Math.ceil(expPayDateTime.diff(now).as(`days`));

        options.push({
          label: `Until expected payment date`,
          // number of days from now to expected payment date
          value: `${days}`,
        });
      }
    }

    return options;
  }, [escalation, drawerProps.escalationId]);

  const noteOptions = useMemo(() => {
    const options = [];
    if (!escalation) return options;
    if (escalation.escalationType !== PolicyEscalationType.CALL) return options;

    // Calls can have several outcomes
    const outcomes = [
      IEscalationOutcome.NO_RESPONSE,
      IEscalationOutcome.LEFT_MESSAGE,
      IEscalationOutcome.SPOKE_TO_CUSTOMER,
    ];

    options.push(...outcomes.map(o => {
      return {
        label: EscalationOutcomeLabels[o],
        value: o,
      };
    }));

    return options;
  }, [escalation, drawerProps.escalationId]);

  function onOpenClose(show: boolean) {
    if (show) {
      return;
    }

    setOutcome(null);
    dispatch(closeAndClear());
  }

  function completeEscalation() {
    if (!escalation) return;

    completeApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
    });
  }

  function snoozeEscalation(e) {
    if (!escalation) return;

    snoozeApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
      snoozeDays: e.value,
    });
  }

  function cancelEscalation() {
    if (!escalation) return;

    cancelApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
    });
  }

  function reopenEscalation() {
    if (!escalation) return;

    reopenApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
    });
  }

  function assign(userId: string | null) {
    if (!escalation) return;

    if (!userId) {
      unassign();

      return;
    }

    assignEscalationApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
      userId,
    });
  }

  function unassign() {
    if (!escalation) return;

    if (!escalation.assignedToUserId) return;

    clearAssignedUserApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
    });
  }

  async function addNote(content: string, mentionedUsers: string[]) {
    if (!escalation) return;

    await addNoteApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
      note: content,
      mentionedUsers,
      outcome: outcome,
    });

    setOutcome(null);
  }

  // For esclation save
  async function onSaveExpectedDate(date: string) {
    if (!escalation) return;

    if (!date) return;

    const formattedDate = DateTime.fromISO(date, { zone: organisation.validatedTimezone }).toFormat(`ccc dd LLL yyyy`);

    await addNoteApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
      expectedPaymentDate: date ? date : null,
      note: `Expected payment date set to ${formattedDate}`,
      outcome: IEscalationOutcome.SET_EXPECTED_PAYMENT_DATE,
    });
  }

  const buttons = useMemo(() => {
    if (!escalation) return [];

    const buttonArray = [];

    if (escalation?.escalationStatus === EscalationStatus.PENDING) {
      buttonArray.push(
        <Tooltip
          title={ `This escalation will be marked as closed` }
        ><Button
            onClick={ () => completeEscalation() }
            loading={ completeLoading }
          >
            { `Mark as Closed` }
          </Button>
        </Tooltip>);

      buttonArray.push(
        <Tooltip
          title={ `This escalation will be snoozed until later` }
        ><Dropdown
            size={ `md` }
            position={ `right` }
            label={ `Snooze until...` }
            options={ [snoozeOption] }
            onSelect={ snoozeEscalation }
          />
        </Tooltip>);

      buttonArray.push(
        <Tooltip
          title={ `This escalation will be cancelled - no further action will be taken` }
        ><Button
            onClick={ () => cancelEscalation() }
            loading={ cancelLoading }
          >
            { `Cancel Escalation` }
          </Button>
        </Tooltip>);
    }
    else if (escalation?.escalationStatus === EscalationStatus.COMPLETE ||
               escalation?.escalationStatus === EscalationStatus.CANCELLED) {
      buttonArray.push(
        <Tooltip
          title={ `This escalation will be re-opened` }
        ><Button
            onClick={ () => reopenEscalation() }
            loading={ reopenLoading }
          >
            { `Re-open Escalation` }
          </Button>
        </Tooltip>);
    }

    return buttonArray;
  }, [escalation, completeLoading, snoozeLoading, cancelLoading, reopenLoading]);

  const items = useMemo(() => {
    if (!escalation) return [];

    const formatter = currencyFormatter(escalation.invoice?.xero_currency);

    const statusContent = getEscalationDisplayStatus(escalation);

    const contactContent = (
      <WrappedLink
        to={ `/contacts/${escalation?.contact.id}` }
        className={ `hover:underline text-indigo-600 hover:text-indigo-800 ` }
      >
        { escalation?.contact?.name }
      </WrappedLink>
    );

    const assigneeContent = (
      <Assignee
        current={ escalation?.assignedToUserId || null }
        onChange={ assign }
        loading={ assignLoading || clearAssignLoading }
      />
    );

    const commonProps: DescriptionItem[] = [
      {
        title: `Status`,
        content: statusContent,
      },
      {
        title: `Assignee`,
        content: assigneeContent,
      },
      {
        title: `Customer`,
        content: contactContent,
      },
      {
        title: `Expected Payment Date`,
        content: <ExpectedPaymentDate
          onSave={ onSaveExpectedDate }
          currentDate={ escalation.invoice?.expected_payment_date_str }
        />,
      },
      {
        title: `Invoice Number`,
        content: escalation.invoice?.xero_number ? (
          <WrappedLink
            to={ `/invoices/${escalation.invoice?.id}` }
            className={ `text-blue-600 underline flex items-start` }
          >
            { escalation.invoice?.xero_number }
            <ArrowTopRightOnSquareIcon
              className={ `inline-block w-4 h-4 ml-1` }
              aria-hidden={ `true` }
            />
          </WrappedLink>
        ) : `-`,
      },
      {
        title: `Invoice Total Due`,
        content: formatter(toDecimal(escalation.invoice?.xero_amount_due)),
      },
      {
        title: `Invoice Due Date`,
        content: <DatePopover
          date={ escalation.invoice?.due_date_string }
          labelFormat={ `ccc dd LLL yyyy` }
          organisationTimezone={ organisation.validatedTimezone }
        />,
      },
      {
        title: `Escalation Date`,
        content: <DatePopover
          date={ escalation.escalationDate }
          labelFormat={ `ccc dd LLL yyyy` }
          organisationTimezone={ organisation.validatedTimezone }
        />,
      },
      {
        title: `Instructions`,
        content: escalation.escalationSteps,
      },
    ];

    return commonProps;
  }, [escalation, assignLoading, clearAssignLoading, organisation]); //TODO: more dependencies

  return (
    <Drawer
      open={ drawerProps.open }
      setOpen={ onOpenClose }
    >

      <When condition={ !!escalation }>
        <DescriptionList
          description={ `An escalation has been scheduled for ${escalation?.contact?.name}. Details of the ${escalation?.escalationType} and required steps are below.` }
          title={ `Escalation to ${escalation?.escalationType} for ${escalation?.contact?.name}` }
          items={ items }
        />

        { /* Buttons */ }
        <When condition={ !!buttons.length }>
          <Grid
            className={ `mt-6` }
            cols={ 3 }>
            {
              buttons.map((button, i) => {
                return (
                  <GridItem
                    key={ i }
                    span={ 1 }
                    position={ `bottom` }
                  >
                    { button }
                  </GridItem>
                );
              })
            }
          </Grid>
        </When>

        <Divider></Divider>
        <Heading>{ `Notes` }</Heading>
      </When>
      <When condition={ !!escalation }>
        <Card
          className={ `mt-4` }
        >
          <Notes
            notes={ escalation?.notes }
            loading={ addNoteLoading }
            enableNewNote
            childrenOptions={ <When condition={ noteOptions && noteOptions.length > 0 }>
              <Select
                className={ `mt-2` }
                emptyText={ `Optionally, select a call outcome...` }
                options={ noteOptions }
                selected={ outcome }
                onChange={ e => setOutcome(e.value) }
                nullable
              />
            </When> }
            onSave={ addNote }
          />
        </Card>
      </When>
    </Drawer>
  );
}
