import { useMemo, useState } from 'react';
import { EmailTemplateTypes, PolicyTargetStatus } from 'shared';
import { When } from 'react-if';

import { Toggle } from '../../../../../common/Atoms/Toggle';
import { Grid } from '../../../../../common/Atoms/Grid';
import { FormRow } from '../../../Molecules/Form/FormRow';
import { GridItem } from '../../../../../common/Atoms/GridItem';
import { Select } from '../../../../../common/Atoms/Select';
import { fieldIsValid } from '../../../../lib/helper';
import { FormLabel } from '../../../../../common/Atoms/FormLabel';
import { ICS_ARTICLE } from '../../../../constants/links';
import { IPolicyProps } from '../PolicyItem';
import { useAccountingResources } from '../../../../hooks/useAccountingResources';
import { EmailTemplateSelector } from '../PolicyForm/Fields/EmailTemplateSelector';
import { DayIndexWithType } from '../PolicyForm/Fields/DayIndexWithType';

function calculateDayTime(value: number): `before` | `after` | `on` {
  if (value === 0) {
    return `on`;
  }

  if (value < 0) {
    return `before`;
  }

  return `after`;
}

export function PolicyReminder(props: IPolicyProps) {
  const { policy, invalids, handleChange } = props;
  const [dayType, setDayType] = useState(calculateDayTime(policy.day_index));
  const { data: resources, isLoading } = useAccountingResources();

  const showFileAttachments = useMemo(() => {
    return resources?.fileAccessScope;
  }, [ resources, isLoading ]);

  const dayTypes = useMemo(() => {
    const result =[
      {
        label: `After`,
        value: `after`,
      },
      {
        label: `On The`,
        value: `on`,
      },
    ];

    if (policy.target_status === PolicyTargetStatus.DUE) {
      result.unshift({
        label: `Before`,
        value: `before`,
      });
    }

    return result;
  }, [ policy.target_status ]);

  const invoiceStates = [
    {
      label: `Due Date`,
      value: PolicyTargetStatus.DUE,
    },
    {
      label: `Issue Date`,
      value: PolicyTargetStatus.ISSUED,
      tooltip: `Tip: Use this to be able to schedule an invoice send date & time`,
    },
    {
      label: `Date Marked Paid`,
      value: PolicyTargetStatus.MARKED_PAID,
      tooltip: `The date the invoice was marked as fully paid. (Not the payment date)`,
      badge: `new`,
      badgeColor: `purple`,
    },
  ];

  const dayIndexValue = useMemo(() => {
    let str = policy.day_index.toString();

    if (str.startsWith(`-`)) {
      str = str.replace(`-`, ``);
    }

    return str;
  }, [ policy.day_index ]);

  function onDayIndexChange(e, day = dayType) {
    const { value } = e;

    let num = Number(value);

    if (Number.isNaN(num)) {
      handleChange({
        name: `day_index`,
        value,
      });

      return;
    }

    if (day === `before`) {
      num = num < 0 ? num : -num;
    }

    if (day === `after`) {
      num = num > 0 ? num : -num;
    }

    if (day === `on`) {
      num = 0;
    }

    handleChange({
      name: `day_index`,
      value: num,
    });
  }

  function handleDayTypeChange(e) {
    setDayType(e.value);

    onDayIndexChange({
      value: policy.day_index,
    }, e.value);
  }

  function handleTargetStatusChange(e) {
    handleChange(e);
  }

  return (
    <>

      { /* RULE */ }
      <div>
        <FormLabel>{ `Send this email:` }</FormLabel>
        <Grid cols={ 3 }
          className={ `items-end` }>
          <GridItem>
            <DayIndexWithType
              handleChange={ handleChange }
              policyFormData={ policy }
              invalids={ invalids }
              dayType={ dayType }
              dayIndexValue={ dayIndexValue }
              onDayIndexChange={ onDayIndexChange }
            />
          </GridItem>
          <GridItem>
            <Select
              options={ dayTypes }
              selected={ dayType }
              onChange={ handleDayTypeChange }
            />
          </GridItem>
          <GridItem>
            <Select
              options={ invoiceStates }
              selected={ policy.target_status }
              onChange={ handleTargetStatusChange }
              name={ `target_status` }
              invalid={ fieldIsValid(`target_status`, invalids) }
            />
          </GridItem>
        </Grid>
      </div>

      <EmailTemplateSelector
        type={ policy.target_status === PolicyTargetStatus.MARKED_PAID ? EmailTemplateTypes.AFTER_MARKED_PAID : EmailTemplateTypes.REMINDER }
        value={ policy.template_id }
        handleChange={ handleChange }
        invalid={ fieldIsValid(`template_id`, invalids) }
        name={ `template_id` }
        nullable
        label={ `Email template` }
        description={ ` Only email templates of the type "Reminder" will be available here.` }
      />
      <FormRow>
        <Grid cols={ 6 }>
          <GridItem span={ 6 }>
            <Toggle
              label={ `Attach invoice as PDF` }
              name={ `include_invoice_pdf` }
              checked={ policy.include_invoice_pdf }
              onChange={ handleChange }
              description={ `Attach the invoice as a PDF to the email` }
            />
          </GridItem>
        </Grid>
      </FormRow>
      <FormRow>
        <When condition={ showFileAttachments }>
          <Grid cols={ 6 }>
            <GridItem span={ 6 }>
              <Toggle
                label={ `Include invoice attachments` }
                name={ `include_attachments` }
                checked={ policy.include_attachments }
                onChange={ handleChange }
                description={ `Includes any other files attached to the invoice in your accounting system.` }
              />
            </GridItem>
          </Grid>
        </When>
      </FormRow>
      <FormRow>
        <Grid cols={ 6 }>
          <GridItem span={ 6 }>
            <Toggle
              label={ `Attach calendar invite` }
              helpLink={ ICS_ARTICLE }
              name={ `attach_ics` }
              checked={ policy.attach_ics }
              onChange={ handleChange }
              description={ `Includes an attachment to add a reminder on the due date to your customers calendar if possible.` }
            />
          </GridItem>
        </Grid>
      </FormRow>
    </>
  );
}
