import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { setState } from "../slices/previewPolicy";

export function usePreviewPolicy() {
  const params = useSelector((s: RootState) => s.previewPolicy);
  const dispatch = useDispatch();

  const closePreviewPolicy = () => {
    dispatch(setState({ showModal: false, invoiceId: null, policyId: null }));
  };

  const openPreviewPolicy = (invoiceId: string, policyId: number) =>
    dispatch(setState({ showModal: true, invoiceId, policyId }));

  return {
    ...params,
    closePreviewPolicy,
    openPreviewPolicy,
  };
}
