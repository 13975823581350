import { createSlice } from '@reduxjs/toolkit';

export const initialState: {
  escalationId: null | number,
  open: boolean
} = {
  escalationId: null,
  open: false,
};

const escalationDrawerSlice = createSlice({
  name: `escalationDrawer`,
  initialState,
  reducers: {
    closeAndClear(state) {
      state.open = false;
      state.escalationId = null;
    },
    open(state, escalation) {
      state.open = true;
      state.escalationId = escalation.payload;
    },
  },
});

export const { closeAndClear, open } = escalationDrawerSlice.actions;

export const escalationDrawerReducer = escalationDrawerSlice.reducer;
