import { When } from 'react-if';

import { useGetSubscriptionMetadata } from '../hooks/useGetSubscription';
import { useOrganisations } from '../hooks/useOrganisations';
import { Notification } from '../../common/Atoms/Notification';

export function NotificationArea() {
  const { data: organisations } = useOrganisations();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();

  const disconnectErrors = organisations.map(x => {
    if (!x.connected) {
      return `${x.legalName}`;
    }
  }).filter(x => x);

  if (!subscriptionMetadata) return null;

  return (
    <>
      <When condition={ disconnectErrors.length > 0 }>
        <div className={ `space-y-4 py-6` }>

          {
            disconnectErrors.map(e => {
              return (
                <Notification
                  key={ e }
                  type={ `error` }
                  title={ `Attention` }
                >
                  { `Organisation ` }{ e }{ ` is no longer connected. ` }<a href={ `/connect/xero` }>{ `reconnect` }</a>
                </Notification>
              );
            })
          }
        </div>
      </When>
    </>
  );
}
