import { When } from "react-if";

import { SmallSpinner } from "../../../common/Atoms/SmallSpinner";
import { classNames } from "../../../common/lib/classNames";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";

interface LoadingOverlayProps {
  className?: string;
  loading: boolean;
  message?: string;
  children: React.ReactNode;
  contrast?: `dark` | `light`;
}

export function LoadingOverlay({ className, loading, children, message, contrast = `dark` }: LoadingOverlayProps) {
  return (
    <div className={
      classNames(
        `relative`,
        className,
        loading && `cursor-wait`,
        loading ? (contrast === `light` && `opacity-50`) : ``,
      )
    }>
      <When condition={ loading }>
        <div className={
          classNames(
            `absolute top-0 left-0 w-full h-full bg-white flex items-center justify-center z-50`,
            contrast === `dark` ? `bg-opacity-50` : ``,
          )
        } >
          <div>
            <SmallSpinner
              size={ `lg` }
              colored
            />
            <When condition={ !!message }>
              <Paragraph
                className={ `mt-2` }
                variant={ `secondary` }
                size={ `md` }
              >
                { message }
              </Paragraph>
            </When>
          </div>
        </div>
      </When>
      { children }
    </div>
  );
}
