import React, { useEffect, useMemo, useState } from "react";
import { EscalationSchedule, EscalationStatus, IEscalationResponseItem, PolicyEscalationType, langauge } from "shared";
import { useDispatch } from "react-redux";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import { Table } from "../../../common/Atoms/Table";
import { Grid } from "../../../common/Atoms/Grid";
import { GridItem } from "../../../common/Atoms/GridItem";
import { InputTextAddon } from "../../../common/Atoms/InputTextAddon";
import { Select } from "../../../common/Atoms/Select";
import { Pagination } from "../../../common/Atoms/Pagination";
import { useDebounce } from "../../hooks/useDebounce";
import { open } from "../../slices/escalationDrawer";
import { Badge } from "../../../common/Atoms/Badge";
import { useEscalationParams, useEscalations } from "../../hooks/useEscalations";
import { CheckBox } from "../../../common/Atoms/CheckBox";
import { DatePopover } from "../../../common/Components/DatePopover";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { useCurrentOrgUsers } from "../../hooks/useCurrentOrgUsers";

interface IEscalationRow {
  id: number;
  type: string | React.ReactNode;
  status: string | React.ReactNode;
  invoiceNumber: string | React.ReactNode;
  escalationDate: string | React.ReactNode;
  completedOn: string | React.ReactNode;
}

const typeOptions = Object.values(PolicyEscalationType).map(et => ({
  label: langauge.escalation[et].label,
  value: et,
}));

const statusOptions = Object.values(EscalationStatus).map(es => ({
  label: langauge.generic.escalation.status[es],
  value: es,
}));

const scheduledOptions = [
  {
    label: `Ready now`,
    value: EscalationSchedule.READY,
  },
  {
    label: `Snoozed`,
    value: EscalationSchedule.SOON,
  },
];

interface EscalationsTableProps {
  /** Where this table is rendered */
  context?: `contact`
}

export function EscalationsTable({ context }: EscalationsTableProps) {
  const { data: escalationsData, isLoading, isFetching } = useEscalations();
  const { params, updateParams, resetParams } = useEscalationParams();
  const dispatch = useDispatch();

  const escalations = escalationsData?.escalations;

  const [searchText, setSearchText] = useState(params.searchText || ``);

  const debouncedTextQuery = useDebounce(searchText, 500);

  const organisation = useGetSelectedOrganisation();

  const assigneeOptions = useCurrentOrgUsers();

  const handleIncludePaid = () => {
    updateParams({ ...params, includePaid: !params.includePaid, page: 1 });
  };

  useEffect(() => {
    updateParams({
      ...params,
      searchText: debouncedTextQuery,
    });
  }, [debouncedTextQuery]);

  const tableHeadings = useMemo(() => {
    const headings = [
      {
        label: `Type`,
        name: `type`,
      },
      {
        label: `Contact`,
        name: `contact`,
        textAlign: `left` as const,
      },
      {
        label: `Invoice`,
        name: `invoiceNumber`,
      },
      {
        label: `Scheduled Date`,
        name: `escalationDate`,
      },
      {
        label: `Assignee`,
        name: `assignee`,
      },
      {
        label: `Status`,
        name: `status`,
      },
    ];

    return headings.filter(h => {
      if (!context) return true;

      if (context === `contact`) {
        return h.name !== `contact`;
      }
    });
  }, [context]);

  useEffect(() => {
    // On mount, default the filters
    resetParams([`searchText`]);
  }, []);

  const tableRows: IEscalationRow[] = useMemo(() => {
    if (!escalations) {
      return [];
    }

    return escalations
      .map(e => {

        const type = (
          <span className={ `flex items-center` }>
            { langauge.escalation[e.escalationType].label }
            <ArrowTopRightOnSquareIcon className={ `w-4 h-4 ml-1` } />
          </span>
        );

        const statusBadge = getEscalationDisplayStatus(e);

        const escalationDate = (
          <DatePopover
            date={ e.escalationDate }
            labelFormat={ `ccc dd LLL yyyy` }
            organisationTimezone={ organisation.validatedTimezone }
          />
        );

        const res = {
          id: e.id,
          type,
          status: statusBadge,
          contact: e.invoice?.xero_contact_name || e.contact?.name || `-`,
          invoiceNumber: e.invoice?.xero_number,
          completedOn: e.completedAt ? (
            <DatePopover
              date={ e.completedAt }
              labelFormat={ `ccc dd LLL yyyy` }
              organisationTimezone={ organisation.validatedTimezone }
            />
          ) : `-`,
          escalationDate,
          assignee: e.assignedUser ? `${e.assignedUser.firstName} ${e.assignedUser.lastName}` : `-`,
        };

        if (e.assignedUser?.removed) {
          res.assignee = `${res.assignee} (Removed)`;
        }

        return res;
      });
  }, [escalations]);

  function handleRowClick(row) {
    dispatch(open(row.id));
  }

  function onTypeFilter(filter: PolicyEscalationType) {
    updateParams({
      ...params,
      type: filter,
    });
  }

  const cols = context === `contact` ? 5 : 6;

  return (
    <div>
      <Grid
        colsSmallScreen={ 3 }
        cols={ cols }
        colsLargeScreen={ cols }
        className={ `mt-4` }
      >
        <GridItem
          span={ 1 }
          position={ `bottom` }
        >
          <Select
            label={ `Escalation Type` }
            emptyText={ `Any` }
            options={ typeOptions }
            onChange={ e => onTypeFilter(e.value) }
            selected={ params.type }
            nullable
          />
        </GridItem>
        <GridItem
          span={ 1 }
          position={ `bottom` }
        >
          <InputTextAddon
            className={ `w-full` }
            value={ searchText }
            label={ `Search` }
            placeholder={ `E.g INV-0001 or John Smith` }
            onChange={ e => setSearchText(e.value as string) }
          />
        </GridItem>
        <GridItem
          span={ 1 }
          position={ `bottom` }
        >
          <Select
            label={ `Status` }
            emptyText={ `Any` }
            options={ statusOptions }
            onChange={ e => updateParams({ ...params, status: e.value, page: 1 }) }
            selected={ params.status }
            nullable
          />
        </GridItem>
        <GridItem
          span={ 1 }
          position={ `bottom` }
        >
          <Select
            label={ `Scheduled` }
            emptyText={ `Any` }
            options={ scheduledOptions }
            onChange={ e => updateParams({ ...params, scheduled: e.value, page: 1 }) }
            selected={ params.scheduled }
            nullable
          />
        </GridItem>
        <GridItem
          span={ 1 }
          position={ `bottom` }
        >
          <Select
            label={ `Assignee` }
            emptyText={ `Any` }
            options={ assigneeOptions }
            onChange={ e => updateParams({ ...params, assignedUserId: e.value, page: 1 }) }
            selected={ params.assignedUserId }
            nullable
            valueClassname={ `max-w-[120px] 2xl:max-w-[210px] truncate` }
          />
        </GridItem>
        <GridItem
          span={ 1 }
          position={ `bottom` }
        >
          <CheckBox
            label={ `Include fully paid` }
            checked={ params.includePaid || false }
            onChange={ handleIncludePaid }
          />
        </GridItem>
      </Grid>
      <Table
        rows={ tableRows }
        headings={ tableHeadings }
        loading={ isFetching || isLoading }
        loadingMessage={ `Updating..` }
        onRowClick={ handleRowClick }
        emptyMessage={ `There are no matching escalations yet. Create an escalation policy to trigger a phone call, letter or other manual escalation when an invoice is overdue.` }
      />
      <Pagination
        onChangePage={ page => updateParams({ ...params, page }) }
        total={ escalationsData?.total || 0 }
        limit={ params.limit }
        activePage={ params.page }
        count={ escalations?.length || 0 }
        className={ `py-2` }
      />
    </div>
  );
}

export function getEscalationDisplayStatus(e: IEscalationResponseItem) {

  const fullyPaid = e.invoice?.xero_amount_due === 0;

  const readableStatus = langauge.escalation[e.escalationType]?.status?.[e.escalationStatus] || e.escalationStatus;

  return (<Badge
    color={ `success` }
    message={ `${readableStatus} ${fullyPaid ? `(Paid)` :``}` }
    size={ `sm` }
  />);
}

