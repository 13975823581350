import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { useFetchInvoiceHistoryMetricsQuery } from '../../../services/api/organisationApi/organisation';
import { Select } from '../../../../common/Atoms/Select';
import { LoadingOverlay } from '../../Molecules/LoadingOverlay';

export const paidOnTimeColor = `#2ADACE`;

export const overdueColor = `#467691`;

export const paidLateColor = `#FF5E5C`;

export const outstandingColor = `#34454E`;

const periodOptions = [
  { value: 3, label: `3 months` },
  { value: 6, label: `6 months` },
  { value: 9, label: `9 months` },
  { value: 12, label: `12 months` },
];

export function InvoiceHistory() {
  const { organisationId } = useParams<{ organisationId: string }>();
  const [period, setPeriod] = useState(6);
  // Only load this dataset to keep queries light
  const { data: metricsResponse, isLoading } = useFetchInvoiceHistoryMetricsQuery({ orgId: organisationId, period }, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
  });

  const valueFormatter = (value: number | null) => `${value}`;

  const chartSetting = {
    yAxis: [
      {
        label: `Number of Invoices`,
      },
    ],

    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: `translate(-5px, 0)`,
      },
    },
  };

  const data = useMemo(() => {
    if (!metricsResponse?.history) return [{
      paidLate: 0,
      outstanding: 0,
      overdue: 0,
      paid: 0,
      label: `Jan`,
    }];

    return metricsResponse.history.map(item => {
      return {
        ...item,
        paidLate: item.paidLate || 0,
      };
    });

    // return example;
  }, [metricsResponse, isLoading]);

  // If no invoices in dataset are paid late, then dont show it
  const seriesData = useMemo(() => {
    const hasPaidLate = data.some(item => item.paidLate > 0);

    return [
      { dataKey: `paidLate`, label: `Paid Late`, valueFormatter, color: paidLateColor },
      { dataKey: `outstanding`, label: `Outstanding`, valueFormatter, color: outstandingColor },
      { dataKey: `overdue`, label: `Overdue`, valueFormatter, color: overdueColor },
      { dataKey: `paid`, label: `Paid`, valueFormatter, color: paidOnTimeColor },
    ].filter(item => item.dataKey !== `paidLate` || hasPaidLate);
  }, [data]);

  return (
    <LoadingOverlay loading={ isLoading }>
      <Card className={ `min-h-fit` }>
        <MultiButtonCardHeader
          title={ `Invoice History` }
          cta={
            <div className={ `w-32` }>
              <Select
                options={ periodOptions }
                onChange={ value => setPeriod(value.value) }
                selected={ period }
              />
            </div>
          }
        />
        <div className={ `h-[400px] min-w-[400px]` } >
          <BarChart
            dataset={ data }
            xAxis={ [{ scaleType: `band`, dataKey: `label`, label: `Due Date` }] }
            series={ seriesData }
            { ...chartSetting }
          />
        </div>
      </Card>
    </LoadingOverlay>
  );
}
