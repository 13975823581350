import { useMemo, useState } from "react";
import { IPolicy, PolicyType, policyConfig } from "shared";
import { When } from "react-if";

import { Select } from "../../../../../../common/Atoms/Select";
import { Grid } from "../../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../../common/Atoms/GridItem";
import { IFieldProps } from "../Fields/fieldProps";
import { useGetSelectedOrganisation } from "../../../../../hooks/useGetSelectedOrganisation";
import { useAllowedFeature } from "../../../../../hooks/useAllowedFeature";
import { useAdvancedPolicyFields } from "../../../../../hooks/useAdvancedPolicyFields";

import { AdvancedField } from "./AdvancedField";

export function AdvancedFields({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const fieldsWithValues = useAdvancedPolicyFields(policyFormData);
  const [shownFields, setShownFields] = useState<(keyof typeof policyConfig)[]>(fieldsWithValues);
  const organisation = useGetSelectedOrganisation();
  const allowedWebhooks = useAllowedFeature(`webhooks`);

  const fieldOptions = useMemo(() => {
    return Object.keys(policyConfig)
      .sort((a, b) => policyConfig[a].sortIndex >= policyConfig[b].sortIndex ? 1 : -1)
      .filter((key: keyof IPolicy) => {
        const policyConfigItem = policyConfig[key];
        let advanced = policyConfigItem.advanced;

        if (policyConfigItem.advanced && Array.isArray(policyConfigItem.advanced)) {
          // When advanced is an array, it must include the policy type
          advanced = policyConfigItem.advanced.includes(policyFormData.policy_type);
        }

        if (!advanced) return false;

        if (shownFields.includes(key as keyof typeof policyConfig)) return false;

        if (!policyConfigItem.appliesTo.includes(policyFormData.policy_type)) return false;

        if (policyConfigItem.notApplicableAccountingSystems?.includes(organisation?.type)) return false;

        if (policyConfigItem.requiresAny) {
          const hasAny = Object.keys(policyConfigItem.requiresAny).some(k => {
            const otherFieldValue = policyFormData[k];

            return otherFieldValue === policyConfigItem.requiresAny[k];

          });

          if (!hasAny) return false;
        }

        if (policyConfigItem.requiresFeature) {
          if (policyConfigItem.requiresFeature === `webhooks` && !allowedWebhooks) return false;
        }

        return true;
      })
      .map(key => ({
        label: policyConfig[key].label,
        value: key,
      }));

  }, [policyFormData, shownFields]);

  function onOptionSelect(e: { value: string }) {
    setShownFields([...shownFields, e.value as keyof typeof policyConfig]);
  }

  if (!fieldOptions.length && !shownFields.length) return null;

  const uniqueFields = [...new Set([...fieldsWithValues, ...shownFields])];

  return (
    <div className={ `p-4  mb-6` }>
      <When condition={ !!fieldOptions.length }>
        <Select
          label={ `Available Options` }
          description={
            policyFormData?.policy_type === PolicyType.STATEMENT_LATE_FEE ?
              `Filter out invoices from being included in the calculation of the fee` :
              `Add extra filters and options to this policy` }
          options={ fieldOptions }
          selected={ null }
          onChange={ onOptionSelect }
          className={ `mb-6` }
          emptyText={ `Choose an option..` }
        />
      </When>

      <Grid cols={ 2 }>
        { /* Render the fields */ }
        {
          uniqueFields
            .sort((a, b) => policyConfig[a].sortIndex >= policyConfig[b].sortIndex ? 1 : -1)
            .map(key => {
              return (
                <GridItem
                  key={ key }
                  position={ `bottom` }
                  className={ `min-w-full` }
                  span={ policyConfig[key].span === `full` ? 2 : 1 }
                >
                  <AdvancedField
                    formKey={ key }
                    policyFormData={ policyFormData }
                    invalids={ invalids }
                    handleChange={ handleChange }
                  />
                </GridItem>
              );
            })
        }
      </Grid>
    </div>
  );
}
